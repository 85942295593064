import React from "react";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import Alert from "@mui/material/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import session from "../api/session";

class ContactClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      msg: "",
      isValid: true,
      captchaValue: null,
      errorMsgEmail: "",
      isSuccess: false,
    };
  }

  handleFirstName(e) {
    this.setState({ first_name: e.target.value });
  }
  handleLastName(e) {
    this.setState({ last_name: e.target.value });
  }
  handleEmail(e) {
    this.setState({ email: e.target.value });
  }

  handlePhone(e) {
    this.setState({ phone: e.target.value });
  }

  handleMessage(e) {
    this.setState({ msg: e.target.value });
  }

  submitForm() {
    const myList = [
      this.state.first_name,
      this.state.last_name,
      this.state.email,
      // this.state.phone,
      this.state.msg,
    ];

    const validationForm = myList.map((e) => e != "").filter((o) => !o);

    if (this.state.phone == "") {
      this.state.phone = "-";
    }

    if (validationForm.length > 0) {
      this.setState({ isValid: false });
    } else {
      this.setState({ isValid: true });
      session
        .post(`/api/contact-create/${this.state.captchaValue}/`, {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          telephone: this.state.phone,
          email: this.state.email,
          message: this.state.msg,
        })
        .then((res) => {
          console.log(res.data);
          this.setState({
            captchaValue: null,
            errorMsgEmail: "",
            isSuccess: true,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);
        })
        .catch((e) => {
          console.log(e.response.data);
          if (e.response.data && e.response.data.email) {
            this.setState({
              errorMsgEmail: "Εισάγετε μια έγκυρη διεύθυνση email.",
            });
          }
        });
    }
  }

  onChange(value) {
    this.setState({ captchaValue: value });
  }
  render() {
    return (
      <div>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax
          small
          filter
          image={require("assets/img/profile-bg2.jpg")}
          style={{ height: "250px" }}
        />
        <Box
          sx={{
            width: "100%",
            typography: "body1",
          }}
        >
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                style={{ marginBottom: "30px" }}
              >
                Επικοινωνήστε μαζί μας και θα σας ενημερώσουμε το συντομότερο
                δυνατόν!
              </Typography>
              {this.state.isSuccess ? (
                <Alert variant="outlined" severity="success">
                  Το αίτημα σας καταχωρήθηκε με επιτυχία!
                </Alert>
              ) : (
                ""
              )}

              <TextField
                id="standard-basic"
                label="Όνομα*"
                variant="standard"
                style={{ paddingBottom: "25px" }}
                onChange={(e) => this.handleFirstName(e)}
              />

              <TextField
                id="standard-basic"
                label="Επίθετο*"
                variant="standard"
                style={{ paddingBottom: "25px" }}
                onChange={(e) => this.handleLastName(e)}
              />

              <TextField
                id="standard-basic"
                label="Τηλέφωνο"
                variant="standard"
                style={{ paddingBottom: "25px" }}
                onChange={(e) => this.handlePhone(e)}
              />

              <TextField
                id="standard-basic"
                label="Email*"
                variant="standard"
                style={{ paddingBottom: "25px" }}
                onChange={(e) => this.handleEmail(e)}
              />
              {this.state.errorMsgEmail != "" ? (
                <p style={{ color: "red" }}> {this.state.errorMsgEmail}</p>
              ) : (
                ""
              )}

              <TextField
                id="filled-multiline-static"
                label="Μήνυμα*"
                multiline
                rows={4}
                variant="standard"
                onChange={(e) => this.handleMessage(e)}
              />
            </CardContent>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>Τα πεδία με αστερίσκο(*) είναι υποχρεωτικά.</p>
              <ReCAPTCHA
                sitekey="6Le54bIfAAAAAA7W2vcgMMd9BwpwPz4JDFEDKOAI"
                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={(v) => this.onChange(v)}
              />
            </CardContent>

            <CardActions style={{ justifyContent: "center" }}>
              {this.state.isValid == false ? (
                <Alert variant="outlined" severity="error">
                  Παρακαλώ συμπληρώστε όλα τα πεδία!
                </Alert>
              ) : (
                ""
              )}
              {this.state.captchaValue != null ? (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => this.submitForm()}
                >
                  αποστολη
                </Button>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        </Box>
        <Footer />
      </div>
    );
  }
}

export default ContactClass;
