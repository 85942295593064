import React, { Component } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  Tooltip,
  LayersControl,
} from "react-leaflet";
import Button from "components/CustomButtons/Button.js";
import L from "leaflet";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Checkbox from "@material-ui/core/Checkbox";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import session from "../api/session";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

let UdataTableLa = [];

let UdataTableP = [];
const useStyles = makeStyles(styles);

let center = [39.365, 22.951];
let zoom = 14;

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default function Map() {
  React.useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  const classes = useStyles();

  const position = [51.505, -0.09];
  const polygon = [
    [51.515, -0.09],
    [51.52, -0.1],
    [51.52, -0.12],
  ];
  const purpleOptions = { color: "purple" };

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            {/* <GridContainer justify="center"> */}
            {/* <GridItem xs={12}>{renderItems()}</GridItem> */}
            {/* <GridItem xs={12}> */}
            <MapPanel />
            {/* <div> */}
            {/* <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: '80vh', width: '200wh' }}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                  <Polygon pathOptions={purpleOptions} positions={polygon} />
                </MapContainer> */}
            {/* </div> */}
            {/* </GridItem> */}
            {/* </GridContainer> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PagkoiList: [],
      map: null,
      PagkosCategoryList: [],
      LaikiList: [],
      markers: [[0, 0]],
      laikiselected: "-",
      pagkosselected: "",
      daysselected: [],
      tmpItemsLaikesSelected: [],
      laikiselectedcenter: "",
      legend: "",
      msgNoBenches: "",
      spinnerActive: false,
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListCategory();

    const { map } = this.state;
  }

  refreshList() {
    // const api_url = 'http://127.0.0.1:8000/api/'
    const epixirin_api = "https://epixirin-volos.govcloud.gr/be/api/";
    //  axios
    //   .get(`${api_url}Pagkoi/`)
    // session
    //   .get("api/Pagkoi/")
    //   .then((res) => this.setState({ PagkoiList: res.data }))
    //   .catch((err) => console.log(err));
    //  axios
    // .get(`${api_url}LaikiAgora/`)
    session
      .get("api/LaikiAgora/")
      .then((res) => this.setState({ LaikiList: res.data }))
      .catch((err) => console.log(err));
  }

  handlePagkosSelect(e, latlng) {
    const value = e.target.value;

    console.log(value);

    this.state.pagkosselected = value;

    const { map } = this.state;
    console.log(latlng, "edw latlng");
    console.log(latlng.length > 0);
    if (latlng != null && latlng.length > 0) {
      console.log("mpainw edw?");
      if (map) map.flyTo(latlng, 21);
    }

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  ClearLaikiSelect() {
    this.state.laikiselected = "-";
    this.state.laikiselectedcenter = center;
    this.setState({ state: this.state, msgNoBenches: "" });
    this.refreshList();
  }

  fetchBenchesBySelectedMarker(laikiId) {
    session.get(`api/pagkoi/mapla/?laikiAgoraId__id=${laikiId}`).then((res) => {
      this.setState({ PagkoiList: res.data, spinnerActive: false });
      if (res.data.length == 0) {
        this.setState({ msgNoBenches: "Δεν Βρέθηκαν Πάγκοι" });
      } else {
        this.setState({ msgNoBenches: "" });
      }
    });
  }

  handleLaikiSelect(e, latlng, laikiId) {
    const value = e.target.value;

    console.log(value);
    this.setState({ spinnerActive: true });
    this.fetchBenchesBySelectedMarker(laikiId);
    this.state.laikiselected = value;
    this.state.laikiselectedcenter = latlng;

    const { map } = this.state;

    if (latlng != null) {
      if (map) map.flyTo(latlng, 18);
    }

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  refreshListCategory() {
    // const api_url = 'http://127.0.0.1:8000/api/'
    //  axios
    //   .get(`${api_url}PagkosCategory/`)
    session
      .get("api/PagkosCategory/")
      .then((res) => this.setState({ PagkosCategoryList: res.data }))
      .catch((err) => console.log(err));
  }

  handleChangeLaikiMeres = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    if (value) {
      this.state.daysselected.push(name);
    } else {
      var array = this.state.daysselected;
      var index = array.indexOf(name);
      array.splice(index, 1);
    }

    this.setState({ state: this.state });
    this.refreshList();
    this.render();

    const tmpItemsLaikes = this.state.LaikiList;

    for (var i = 0; i < tmpItemsLaikes.length; i++) {
      console.log("-------");

      const found = false;
      console.log(tmpItemsLaikes[i]);

      if (tmpItemsLaikes[i].deytera === true) {
        if (this.state.daysselected.includes("deytera")) {
          console.log("D");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }
      if (tmpItemsLaikes[i].triti === true) {
        if (this.state.daysselected.includes("triti")) {
          console.log("T");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }
      if (tmpItemsLaikes[i].tetarti === true) {
        if (this.state.daysselected.includes("tetarti")) {
          console.log("TR");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }
      if (tmpItemsLaikes[i].pempti === true) {
        if (this.state.daysselected.includes("pempti")) {
          console.log("P");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }
      if (tmpItemsLaikes[i].paraskeui === true) {
        if (this.state.daysselected.includes("paraskeui")) {
          console.log("PA");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }
      if (tmpItemsLaikes[i].savvato === true) {
        if (this.state.daysselected.includes("savvato")) {
          console.log("S");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }
      if (tmpItemsLaikes[i].kyriaki === true) {
        if (this.state.daysselected.includes("kyriaki")) {
          console.log("K");
          found = true;
          this.state.tmpItemsLaikesSelected.push(tmpItemsLaikes[i].id);
          // break;
        }
      }

      console.log(found);
      console.log("-------");

      if (found == false) {
        var array = this.state.tmpItemsLaikesSelected;
        var index = array.indexOf(tmpItemsLaikes[i].id);
        // console.log(tmpItemsLaikes[i].id);
        // console.log(array);
        if (index != -1) {
          console.log(index);
          array.splice(index, 1);
        }
      }
    }
    console.log("---*******************----");

    const uniqueNames = Array.from(new Set(this.state.tmpItemsLaikesSelected));
    this.state.tmpItemsLaikesSelected = uniqueNames;
    console.log(this.state.tmpItemsLaikesSelected);
    // console.log(uniqueNames);
  };

  render() {
    let tmpItemsP = this.state.PagkoiList.filter(
      (item) => String(item.laikiAgoraId) === String(this.state.laikiselected)
    );
    let tmpItemsLaikes = this.state.LaikiList.filter((item) =>
      this.state.tmpItemsLaikesSelected.includes(parseInt(item.id))
    );

    if (this.state.laikiselected !== "-") {
      tmpItemsLaikes = this.state.LaikiList.filter(
        (item) => String(item.nameLA) === String(this.state.laikiselected)
      );
    }

    let tmpItemsLaikesMap = this.state.LaikiList;

    const { markers } = this.state;
    let categories = [];

    console.log(this.state.daysselected);
    // for (var i = 0; i < tmpItems.length; i++) {
    //   var tmpr = [];

    //   var tmpstr = "";
    //   tmpstr = tmpItems[i].coordsE.split(",");
    //   var tmpstrlat = parseFloat(tmpstr[0]);
    //   var tmpstrlong = parseFloat(tmpstr[1]);
    //   tmpr.push(tmpstrlat);
    //   tmpr.push(tmpstrlong);
    //   // markers.push(tmpr);

    //   tmpItems.coordsE = tmpr;
    // }

    // const { markers } = this.state;
    // markers.push(e.latlng)
    // this.setState({markers})
    let positionC = [51.505, -0.09];

    // console.log(tmpItemsP);
    // console.log(tmpItemsLaikes);

    if (this.state.PagkosCategoryList != undefined) {
      categories = this.state.PagkosCategoryList;
    }

    if (categories != undefined) {
      for (var i = 0; i < tmpItemsP.length; i++) {
        // console.log(tmpItems[i]);
        let categorytmp = categories.filter(
          (item) => String(item.namePC) === String(tmpItemsP[i].categoryP)
        );
        // console.log(categorytmp);
        if (categorytmp[0] != undefined) {
          tmpItemsP[i].color = categorytmp[0].colorPC;
          tmpItemsP[i].Bcolor = categorytmp[0].description;
        }
      }
    } else {
      this.setState({ state: this.state });
    }

    if (tmpItemsLaikes[0] != undefined) {
      positionC = tmpItemsLaikes[0].centerLA;
    }
    if (this.state.laikiselectedcenter == "") {
      this.state.laikiselectedcenter = center;
    }

    if (tmpItemsP[0] != undefined) {
      let sortedProducts = [...tmpItemsP];
      sortedProducts.sort((a, b) => {
        if (parseInt(a.userIdp) < parseInt(b.userIdp)) {
          return 1;
        }
        if (parseInt(a.userIdp) > parseInt(b.userIdp)) {
          return -1;
        }
        return 0;
      });

      tmpItemsP = sortedProducts;
    }

    UdataTableLa = [];

    for (var i = 0; i < tmpItemsLaikes.length; i++) {
      const dataRow = {};
      const coords = tmpItemsLaikes[i].coordsLA;
      const name = tmpItemsLaikes[i].nameLA;
      const item = tmpItemsLaikes[i];

      dataRow.name = name;
      dataRow.selectLa = (
        <Radio
          checked={String(this.state.laikiselected) === String(name)}
          onChange={(e) => this.handleLaikiSelect(e, coords, item.id)}
          value={name}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.perioxi = tmpItemsLaikes[i].perioxiA;

      UdataTableLa.push(dataRow);
    }

    UdataTableP = [];

    for (var i = 0; i < tmpItemsP.length; i++) {
      const dataRow = {};

      const coords = tmpItemsP[i].coordsP1;
      const nameP = tmpItemsP[i].nameP;

      dataRow.name = nameP;

      // if (coords != null && coords[0] != 0) {
      //   dataRow.selectLa = (
      //     <Radio
      //       checked={String(this.state.pagkosselected) === String(nameP)}
      //       onChange={(e) => this.handlePagkosSelect(e, coords)}
      //       value={nameP}
      //       name="radio-button-demo"
      //     />
      //   );
      // }
      // tmpItemsLaikes[i].nameLA;
      dataRow.perioxi = tmpItemsP[i].categoryP;

      UdataTableP.push(dataRow);
    }

    var legend2 = L.control({ position: "topright" });

    legend2.onAdd = function (map) {
      var div = L.DomUtil.create("div", "legend");
      div.innerHTML += "<h4>Κατηγορίες πάγκων</h4>";
      div.innerHTML +=
        '<i style="background: #4CAF50; border-radius: 10px"></i><span>Θέσεις Παραγωγών</span><br>';
      div.innerHTML +=
        '<i style="background: #9C27B0; border-radius: 10px"></i><span>Κενές Θέσεις Παραγωγών</span><br>';
      div.innerHTML +=
        '<i style="background: #00ACC1; border-radius: 10px"></i><span>Θέσεις επαγγελματιών ψαράδων	</span><br>';
      div.innerHTML +=
        '<i style="background: #F44336; border-radius: 10px"></i><span>Κενές θέσεις επαγγελματιών πωλητών	</span><br>';
      div.innerHTML +=
        '<i style="background: #FF9800; border-radius: 10px"></i><span>Θέσεις επαγγελματιών πωλητών	</span><br>';

      return div;
    };

    if (this.state.map && this.state.legend == "") {
      legend2.addTo(this.state.map);
      this.state.legend = "-";
    }

    // tmpItemsP
    const drawerWidth = {
      width: "50%",
      "@media(minWidth: 850px)": {
        display: "block",
      },
    };
    return (
      <GridContainer justify="center" spacing={1}>
        <Card style={{ alignItems: "center" }}>
          <CardHeader color="success" style={{ width: "30%" }}>
            Ημέρες
          </CardHeader>
          <CardBody>
            <Table>
              <TableBody
                style={{
                  padding: "0%",
                }}
              >
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="deytera"
                        id="deytera"
                        color="primary"
                      />
                    }
                    label="Δευτέρα"
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="triti"
                        id="triti"
                        color="primary"
                      />
                    }
                    label="Τρίτη"
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="tetarti"
                        id="tetarti"
                        color="primary"
                      />
                    }
                    label="Τετάρτη"
                  />
                </TableCell>
                <br></br>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="pempti"
                        id="pempti"
                        color="primary"
                      />
                    }
                    label="Πέμπτη"
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="paraskeui"
                        id="paraskeui"
                        color="primary"
                      />
                    }
                    label="Παρασκευή"
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="savvato"
                        id="savvato"
                        color="primary"
                      />
                    }
                    label="Σάββατο"
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangeLaikiMeres(e)}
                        name="kyriaki"
                        id="kyriaki"
                        color="primary"
                      />
                    }
                    label="Κυριακή"
                  />
                </TableCell>
              </TableBody>
            </Table>
          </CardBody>
        </Card>
        <GridItem xs={10} lg={6}>
          {categories.length == 0 && (
            <Card>
              <CardHeader color="success">Χάρτης</CardHeader>
              <CardBody>
                <MapContainer
                  center={center}
                  zoom={zoom}
                  scrollWheelZoom={false}
                  style={{ height: "80vh", width: "200wh" }}
                >
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Street View">
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Earth view">
                      <TileLayer
                        maxNativeZoom={17}
                        minZoom={0}
                        maxZoom={17}
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                        attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                      />
                    </LayersControl.BaseLayer>
                  </LayersControl>
                </MapContainer>
              </CardBody>
            </Card>
          )}
          {categories.length != 0 && (
            <Card style={{ width: "90%" }}>
              <CardHeader color="success">Χάρτης</CardHeader>
              <CardBody>
                <MapContainer
                  center={center}
                  zoom={zoom}
                  scrollWheelZoom={false}
                  style={{ height: "70vh", width: "200wh" }}
                  whenCreated={(map) => this.setState({ map })}
                >
                  {/* <FeatureGroup>
                <EditControl
                  position="topright"
                  onEdited={this._onEditPath}
                  onCreated={this._onCreate}
                />
              </FeatureGroup> */}
                  {/* <Circle center={[51.51, -0.06]} radius={200} /> */}

                  {/* <TileLayer
                    maxNativeZoom={19}
                    minZoom={0}
                    maxZoom={22}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  /> */}

                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Street View">
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Earth view">
                      <TileLayer
                        maxNativeZoom={17}
                        minZoom={0}
                        maxZoom={17}
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                        attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                      />
                    </LayersControl.BaseLayer>
                  </LayersControl>

                  {/* <Control position="bottomleft">
                    <div>
                      <h4>Tegnforklaring</h4>
                    </div>
                  </Control> */}

                  {/* {tmpItemsLaikes.map((item) => (
            <Marker key={`marker-${item.id}`} position={item.coordsLA}>
              <Popup>{item.nameLA}</Popup>
            </Marker>
          ))} */}

                  {tmpItemsP.map((item) => (
                    <>
                      {item.coordsP1 != null &&
                        item.coordsP2 != null &&
                        item.coordsP3 != null &&
                        item.coordsP4 != null && (
                          <Polygon
                            positions={[
                              item.coordsP1,
                              item.coordsP2,
                              item.coordsP3,
                              item.coordsP4,
                            ]}
                            color={item.color}
                          >
                            <Tooltip>
                              {item.nameP}
                              <br></br>
                              {item.userIdp}
                              <br></br>
                              {item.categoryP}
                            </Tooltip>
                          </Polygon>
                        )}
                    </>
                  ))}

                  {/* {this.state.laikiselectedcenter != center && (
                    <Marker
                      position={this.state.laikiselectedcenter}
                      icon={L.icon({
                        iconUrl:
                          "https://epixirin-volos.govcloud.gr/be/uploads/logos/download.png",
                        iconSize: [25, 40],
                        iconAnchor: [
                          this.state.laikiselectedcenter[0] - 29,
                          this.state.laikiselectedcenter[1] + 18,
                        ],
                        popupAnchor: null,
                        shadowUrl: null,
                        shadowSize: null,
                        shadowAnchor: null,
                      })}
                    >
                      <Tooltip>{this.state.laikiselected}</Tooltip>
                    </Marker>
                  )} */}
                  {/* {this.state.laikiselectedcenter == center && ( */}
                  {/* <div> */}
                  {tmpItemsLaikesMap.map((item) => (
                    <Marker key={`marker-${item.id}`} position={item.coordsLA}>
                      <Tooltip>{item.nameLA}</Tooltip>
                    </Marker>
                  ))}

                  {/* <Legend /> */}
                  {/* </div> */}
                  {/* )} */}
                </MapContainer>
              </CardBody>
            </Card>
          )}
        </GridItem>

        <GridItem xs={10} lg={6}>
          <Card>
            <CardHeader color="success">Λαϊκές Αγορές</CardHeader>
            <CardBody>
              <ReactTable
                data={UdataTableLa}
                filterable
                resizable={false}
                columns={[
                  {
                    Header: (
                      <Button
                        round
                        color="danger"
                        size="sm"
                        onClick={() => this.ClearLaikiSelect()}
                      >
                        {" "}
                        <i className="fa fa-times"></i>{" "}
                      </Button>
                    ),
                    accessor: "selectLa",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                  {
                    Header: "Όνομα",
                    accessor: "name",
                  },
                  {
                    Header: "Περιοχή",
                    accessor: "perioxi",
                  },
                  {
                    Header: "",
                    accessor: "e",
                    disableFilters: true,
                    disableSortBy: true,
                  },

                  // {
                  //   Header: " ",
                  //   accessor: "",
                  // },
                ]}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />

              {/* <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {" "}
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.ClearLaikiSelect()}
                        >
                          <i className="fa fa-times"></i>
                        </Button>
                        Όνομα
                      </TableCell>
                      <TableCell>Περιοχή</TableCell>
                      <TableCell>Ημέρες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tmpItemsLaikes.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          <Radio
                            checked={
                              String(this.state.laikiselected) ===
                              String(item.nameLA)
                            }
                            onChange={(e) =>
                              this.handleLaikiSelect(e, item.coordsLA)
                            }
                            value={item.nameLA}
                            name="radio-button-demo"
                          />
                          {item.nameLA} */}
              {/* <FormControl component="fieldset">
                          <FormControlLabel
                            value={item.id}
                            onChange={(e) => this.handleLaikiSelect(e)}
                            control={<Radio />}
                            label={item.nameLA}
                          />
                        </FormControl> */}
              {/* </TableCell>
                        <TableCell>{item.perioxiA} </TableCell>
                        <TableCell>
                          {item.deytera && "Δευτέρα "}
                          {item.triti && "Τρίτη "}
                          {item.tetarti && "Τετάρτη "}
                          {item.pempti && "Πέμπτη "}
                          {item.paraskeui && "Παρασκευή "}
                          {item.savvato && "Σάββατο "}
                          {item.kyriaki && "Κυριακή"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </CardBody>
          </Card>
          {/* {tmpItemsP.length == undefined && ( */}
          <Card>
            <CardHeader color="success">Πάγκοι Λαϊκής</CardHeader>

            <CardBody>
              {this.state.msgNoBenches.length > 0 ? (
                <h4>{this.state.msgNoBenches}</h4>
              ) : (
                ""
              )}
              {this.state.spinnerActive ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                  Παρακαλώ περιμένετε
                </Box>
              ) : (
                ""
              )}
              {/* <Box sx={{ display: "flex" }}>
                <CircularProgress />
                Παρακαλώ περιμένετε
              </Box> */}
              {UdataTableP.length != 0 && (
                <ReactTable
                  data={UdataTableP}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: " ",
                      accessor: "selectLa",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Όνομα",
                      accessor: "name",
                    },
                    {
                      Header: "Είδος",
                      accessor: "perioxi",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },

                    // {
                    //   Header: " ",
                    //   accessor: "",
                    // },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              )}

              {/*          
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Όνομα</TableCell>
                      <TableCell>Τύπος</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tmpItemsP.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          <Radio
                            checked={
                              String(this.state.pagkosselected) ===
                              String(item.nameP)
                            }
                            onChange={(e) =>
                              this.handlePagkosSelect(e, item.coordsP1)
                            }
                            value={item.nameP}
                            name="radio-button-demo"
                          />
                          {item.nameP}
                        </TableCell>
                        <TableCell>
                          <Badge color={item.Bcolor}>{item.categoryP}</Badge>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </CardBody>
          </Card>
          {/* )} */}
        </GridItem>
      </GridContainer>
    );
  }
}
