import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { el } from "date-fns/locale";
import Input from '@mui/material/Input';
import grLocale from 'date-fns/locale/el';
import session from '../../api/session'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';





const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function UpdateBusinessLicenseFile(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [file, setFile] = React.useState(props.file ? props.file : "")
    const [businessLisence, setbusinessLisence] = React.useState('')

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }


    const saveFile = () => {
        session.get(`api/business-file/${props.fileId}/actions/`).then((resp) => {

            console.log(resp.data.business_lisence)
            let data = new FormData()
            data.append('file', file)
            data.append('business_lisence', resp.data.business_lisence)
            session.put(`api/business-file/${props.fileId}/actions/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(() => setOpen(false), props.handleBusinessFileEnd())

        })


    }


    return (
        <div style={{ display: "inline" }}>
            <Button onClick={handleOpen} startIcon={<EditIcon />}>

            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Επεξεργασία Αρχείου
                    </Typography>
                    <hr />
                    <input

                        type="file"
                        onChange={(e) => handleFile(e)}

                    />

                    <br />
                    <br />
                    <Grid item xs={12}>
                        <CardContent>

                            <Button
                                onClick={saveFile}
                            >
                                {" "}
                                Αποθηκευση
                            </Button>
                        </CardContent>
                    </Grid>
                </Box>


            </Modal>
        </div>
    )

}

function BusinessEdit(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const [buisnessName, setBuisnessName] = React.useState(props.bussinessName ? props.bussinessName : "")
    const [owner, setOwner] = React.useState(props.owner ? props.owner : "")
    const [afm, setAfm] = React.useState(props.afm ? props.afm : "")
    const [site, setSite] = React.useState(props.site ? props.site : "")
    const [address, setAddress] = React.useState(props.address ? props.address : "")
    const [folderNumber, setFolderNumber] = React.useState(props.folderNumber ? props.folderNumber : "")
    const [category, setCategory] = React.useState(props.categories)
    const [notes, setNotes] = React.useState(props.notes ? props.notes : "")
    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);
    const [alertShow, setAlertShow] = React.useState(false)
    const [selectedFile, setSelectedFile] = React.useState(null);
    // const [alertShow, setAlertShow] = React.useState(false)
    const isFormValid = () => {
        if ([buisnessName, owner, address, category].map(e => e == "").filter(o => o == true).length > 0) {
            setAlertShow(true)
            return false
        } else {
            setAlertShow(false)
            return true
        }
    }
    const handleFiles = () => {
        console.log(selectedFile)
        const fileID = props.file.map(e => e.id)
        if (fileID.length === 0 && selectedFile != null) {
            let data = new FormData()

            data.append('file_field', selectedFile)
            data.append('epixeirisi', buisnessName)
            session.post(`api/FileFieldFormEpixeirisi/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        }
        if (selectedFile != null && fileID.length > 0) {
            let data = new FormData()
            data.append('file_field', selectedFile)
            data.append('epixeirisi', buisnessName)
            session.put(`api/FileFieldFormEpixeirisi/${fileID[0]}/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })

            // session.put(`api/FileFieldFormEpixeirisi/11/`)
        }
    }

    const saveDialog = () => {

        if (isFormValid()) {
            const today = new Date()
            session.put(`api/Epixeiriseis/${props.bussinessId}/`, {
                nameE: buisnessName,
                afmE: afm,
                iconeE: category,
                userIdE: owner,
                siteE: site,
                dieuthinsi: address,
                paratiriseis: notes,
                arfak: folderNumber,
                wrarioE: startTime != null ? startTime.toLocaleTimeString('el', { hour12: false }) : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0).toLocaleTimeString('el', { hour12: false }),
                wrarioL: endTime != null ? endTime.toLocaleTimeString('el', { hour12: false }) : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 16, 0, 0).toLocaleTimeString('el', { hour12: false })
            }).then(() => handleFiles()).finally(() => setOpen(false), props.handleChangeUpdate())
        }
    }

    const handleClose = () => {
        setOpen(false)
        setAlertShow(false)
    }

    return (
        <div>
            {/* <Button
                onClick={handleOpen}
                // startIcon={<AddBusinessIcon />}
                variant="contained"
                sx={{
                    width: "fit-content",
                    height: "10%",
                    textTransform: "none",
                    bgcolor: "#169ab0",
                }}
            >
                Επεξεργασία Επιχείρησης
            </Button> */}
            <Button onClick={handleOpen} variant="outlined">
                Επεξεργασια
            </Button>
            <Dialog

                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'l'}
            >

                <DialogTitle style={{ textAlign: "center" }}>Επεξεργασία Επιχείρησης - {owner}</DialogTitle>
                {alertShow &&
                    <Alert severity="error">Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία!</Alert>
                }

                <DialogContent style={{ textAlign: "center" }}>


                    <hr />

                    <Grid container spacing={8} alignItems="center"
                        justify="center">
                        <Grid item xs={6} >
                            <Card sx={{ minWidth: 275 }} style={{ boxShadow: "0 0 20px aquamarine", borderStyle: "groove", borderRadius: "10px" }}>
                                <CardContent>
                                    <TextField id="standard-basic" label="Επωνυμία" variant="standard" value={buisnessName} onChange={(e) => setBuisnessName(e.target.value)} />
                                    <br />
                                    <br />

                                    <TextField id="standard-basic" label="Ιδιοκτήτης" variant="standard" value={owner} onChange={(e) => setOwner(e.target.value)} />
                                    <br />
                                    <br />
                                    <TextField id="standard-basic" label="Διεύθυνση" variant="standard" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    <br />
                                    <br />


                                    <TextField id="standard-basic" label="ΑΦΜ" variant="standard" value={afm} onChange={(e) => setAfm(e.target.value)} />
                                    <br />
                                    <br />

                                    <TextField id="standard-basic" label="Site" variant="standard" value={site} onChange={(e) => setSite(e.target.value)} />
                                    <br />
                                    <br />
                                    <InputLabel id="demo-controlled-open-select-label">
                                        Κατηγορία Επιχείρησης*
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>-------------------</em>
                                        </MenuItem>
                                        {props.categories.map((e) => (
                                            <MenuItem key={e} value={e}>
                                                {e}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <br />
                                    <br />
                                    <TextField id="standard-basic" label="Παρατηρήσεις" variant="standard" value={notes} onChange={(e) => setNotes(e.target.value)} />


                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }} style={{ boxShadow: "0 0 20px aquamarine", borderStyle: "groove", borderRadius: "10px" }}>
                                <CardContent>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Εναρξη Ωραρίου"
                                            value={props.startTime}
                                            ampm={false}
                                            format="HH:mm:ss"
                                            onChange={(newValue) => {
                                                setStartTime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <br />
                                    <br />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Λήξη Ωραρίου"
                                            value={props.endTime}
                                            ampm={false}
                                            format="HH:mm:ss"
                                            onChange={(newValue) => {
                                                setEndTime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>

                                </CardContent>
                            </Card>
                            <br />
                            <br />

                            <Card sx={{ minWidth: 275 }} style={{ boxShadow: "0 0 20px aquamarine", borderStyle: "groove", borderRadius: "10px" }}>

                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Συνημμένο αρχείο
                                    </Typography>


                                    <Input
                                        //  multiple
                                        id="file_field"
                                        name="file_field"
                                        type="file"

                                        onChange={(e) => setSelectedFile(e.target.files[0])}

                                    />

                                </CardContent>
                            </Card>
                        </Grid>


                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="success" onClick={saveDialog}>Αποθηκευση</Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>Κλεισιμο</Button>
                </DialogActions>


            </Dialog>
        </div >
    )

}

function BusinessLisenceModal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [startDate, setStartDate] = React.useState(props.start_date ? new Date(props.start_date) : null);
    const [endDate, setEndDate] = React.useState(props.end_date ? new Date(props.end_date) : null)
    const [comment, setComment] = React.useState(props.comment ? props.comment : "");
    const [files, setFiles] = React.useState([])
    const [licenseParameter, setLicenseParameter] = React.useState('')


    const handleComment = (e) => {

        setComment(e.target.value)
    }

    const handleFiles = (e) => {
        setFiles(e.target.files)
    }
    const handleSelectChange = (e) => {
        setLicenseParameter(e.target.value)
    }

    const saveCard = () => {
        if (props.action === 'Create') {

            session.post('api/bussiness-license/create/', {
                start_date: new Date(startDate).toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-'),
                end_date: new Date(endDate).toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-'),
                comments: comment,
                bussiness: props.businessID,
                license_parameter: licenseParameter,
                created_by: props.created_by
            }).then(resp => {

                if (files.length > 0) {
                    for (const file of files) {
                        let data = new FormData()

                        data.append('file', file)
                        data.append('business_lisence', resp.data.id)
                        setTimeout(() => {
                            session.post('api/business-files/create/', data, { headers: { 'Content-Type': 'multipart/form-data' } })
                        }, 1000);
                    }
                }
            }).finally(() => setOpen(false), props.handleBusinessChange(), setStartDate(null), setEndDate(null), setComment(null), setLicenseParameter(''), setFiles([]))
        } else {

            let updateData = new FormData()
            updateData.append('start_date', new Date(startDate).toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-'))
            updateData.append('end_date', new Date(endDate).toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-'))
            updateData.append('comments', comment)
            updateData.append('bussiness', props.businessID)
            updateData.append('license_parameter', licenseParameter)
            updateData.append('created_by', props.created_by)


            // session.put(`api/business-license/${props.actionID}/actions/`, updateData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => setOpen(false), props.handleBusinessChange())
            if (files.length > 0) {
                for (const file of files) {
                    let form = new FormData()
                    form.append('file', file)
                    form.append('business_lisence', props.actionID)
                    setTimeout(() => {
                        session.post('api/business-files/create/', form, { headers: { 'Content-Type': 'multipart/form-data' } })
                    }, 1000)
                }
            }
            session.put(`api/business-license/${props.actionID}/actions/`, updateData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => setOpen(false), props.handleBusinessChange())
        }
    }



    return (
        <div>
            <Button onClick={handleOpen} variant="outlined">
                {props.action === 'Create' ? 'Δημιουργια' : 'Επεξεργασια'}
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {props.action === 'Create' ? 'Δημιουργία' : 'Επεξεργασια'} Αδειας
                    </Typography>
                    <hr />
                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CardContent>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={grLocale}>
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                label="Έναρξη Άδειας"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>

                                        <br />
                                        <br />
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={grLocale}>
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                label="Λήξη Άδειας"
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    setEndDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </CardContent>
                                </Grid>


                                <br /><br />
                                <Grid item xs={12}>
                                    <CardContent>
                                        <TextField
                                            label="Παρατηρήσεις"
                                            id="standard-basic"
                                            variant="standard"
                                            multiline
                                            maxRows={10}
                                            value={comment}
                                            onChange={(e) => handleComment(e)}

                                        />
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent>
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Είδος Άδειας</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={licenseParameter}
                                                onChange={handleSelectChange}
                                                label="Σταδιο Παράβασης"
                                            >

                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>


                                                {props.licenseParameters && props.licenseParameters.length > 0 && props.licenseParameters.map((e) => (
                                                    <MenuItem key={e.id} value={e.id}>{e.title}</MenuItem>

                                                ))}

                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                </Grid>

                                <br /><br />

                                <Grid item xs={12}>
                                    <CardContent>
                                        <input
                                            multiple
                                            type="file"
                                            onChange={(e) => handleFiles(e)}

                                        />

                                        {/* {files && files.length > 0 && files.map((e, index) => (
                                            <ul key={index}>
                                                <li>{e.name}</li>
                                            </ul>
                                        ))} */}

                                    </CardContent>
                                </Grid>


                                <br />
                                <br />
                                <br />
                                <Grid item xs={12}>
                                    <CardContent>

                                        <Button
                                            onClick={saveCard}
                                        >
                                            {" "}
                                            Αποθηκευση
                                        </Button>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </div >
    )

}




export { BusinessLisenceModal, UpdateBusinessLicenseFile, BusinessEdit }