/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

import espa from "assets/img/footer_banner-300x70.png";
import serreslogo from "assets/img/serresgr-logo-300x77 (1).png";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          {/* <a
            href="https://www.serres.gr/index.php/oroi-xrisis"
            className={aClasses}
            target="_blank"
          >
            Όροι Χρήσης
          </a>{" "}
          –
          <a
            href="https://www.serres.gr/images/GDPR/pol_prostasias_pros_ded_2020.pdf"
            className={aClasses}
            target="_blank"
          >
            Πολιτική Προστασίας Προσωπικών Δεδομένων
          </a>{" "} */}
          <br />

          <img
            src={serreslogo}
            style={{ width: "150px" }}
            alt="..."
            className={imageClasses}
          />
          {/* <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="/MapE" className={classes.block} target="_blank">
                Επιχειρήσεις
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/MapLA" className={classes.block} target="_blank">
                Λαϊκές Αγορές
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/profile-page" className={classes.block} target="_blank">
                Προφίλ
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.creative-tim.com/license?ref=mkr-footer"
                className={classes.block}
                target="_blank"
              >
                Licenses
              </a>
            </ListItem>
          </List> */}
        </div>
        <div className={classes.right}>
          Copyright &copy; {1900 + new Date().getYear()} , Δήμος Βόλου. Με την
          επιφύλαξη παντός δικαιώματος
          {/* <Favorite className={classes.icon} /> */}
          <br></br>
          Ανάπτυξη & Σχεδιασμός{" "}
          <a href="https://www.proset.gr" className={aClasses} target="_blank">
            Proset
          </a>{" "}
          for a better web.
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
