import axios from "axios";

const CSRF_COOKIE_NAME = "csrftoken";
const CSRF_HEADER_NAME = "X-CSRFToken";

// base Url must end with slash (/) https://whatever/
axios.defaults.baseURL = "https://epixirin-demo.govcloud.gr/be/";
// axios.defaults.baseURL = "http://192.168.10.113:8000/";

const token = localStorage.getItem("token");
if (token != null) {
  axios.defaults.headers.Authorization = `JWT ${token}`;
}

const session = axios.create({
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
});

export default session;
