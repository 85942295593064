import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import session from '../api/session'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    flexGrow: 1
};


function SuperVisorModal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [benchMeters, setBenchMeters] = React.useState(props.benchPosition ? props.benchPosition.split(',').map(e => e != '').filter(o => o).length : 0)
    const [benchPosition, setBenchPosition] = React.useState(props.benchPosition ? props.benchPosition : "")
    const [isPresent, setIsPresent] = React.useState(true)
    const [IllegalTextOne, setIllegalTextOne] = React.useState('')
    const [IllegalTextTwo, setIllegalTextTwo] = React.useState('')
    const [isIllegal, setIsIllegal] = React.useState(false)


    const handleBenchPosition = (e) => {
        setBenchPosition(e.target.value)

        setBenchMeters(e.target.value.split(',').map(e => e != "").filter(o => o).length)
    }

    const updateBenchPosition = () => {
        session.put(`api/Pagkoi/${props.pagkosID}/`, {
            bench_position: benchPosition,
            tetragwnika: benchMeters
        })

    }
    const parousiaPagkouPost = () => {
        const user = localStorage.getItem('userselectedname')
        const date = new Date().toISOString().split('T')[0]
        session.post('api/ParousiaPagkou/', {
            parousia: isPresent,
            date: date,
            pagkos: props.anagnwristikoPagkou,
            user: user
        })
    }
    const illegalPagkosPost = () => {
        const date = new Date().toISOString().split('T').join(' ')
        const user = localStorage.getItem('userselectedname')
        session.post('api/ParanomosPagkou/', {
            paratiriseis: IllegalTextOne,
            paratiriseis2: IllegalTextTwo,
            date: date,
            pagkos: props.anagnwristikoPagkou,
            user: user
        })
    }
    const inspectedPagkos = () => {
        const date = new Date().toISOString().split('T').join(' ')
        const user = localStorage.getItem('userselectedname')
        session.post('api/ElegxosPagkou/', {
            date: date,
            user: user,
            pagkos: props.anagnwristikoPagkou
        })
    }
    const saveCard = () => {
        updateBenchPosition()
        parousiaPagkouPost()
        inspectedPagkos()
        if (isIllegal) {
            illegalPagkosPost()
        }
        props.endBenchInspection()
        setOpen(false)


    }
    return (
        <div>
            <Button onClick={handleOpen}>Έλεγχος</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ελεγχος Παγκου
                    </Typography>
                    <hr />
                    <Stack spacing={5}>

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>

                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", boxShadow: "0 0 6px cyan" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Στοιχεία κατόχου
                                        </Typography>

                                        <TextField disabled id="standard-basic" label="Αρ. Πάγκου" variant="standard" value={props.numberP} />
                                        <TextField disabled id="standard-basic" label="Oνοματεπώνυμο" variant="standard" value={props.name} />
                                        <TextField
                                            disabled
                                            label="Έναρξη Άδειας πάγκου"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.licenseStart}
                                        />

                                        <TextField
                                            disabled
                                            label="Λήξη Άδειας πάγκου"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.licenceEnd}
                                        />
                                        <TextField

                                            label="Θέση Πάγκου"
                                            id="standard-basic"
                                            variant="standard"
                                            value={benchPosition}
                                            onChange={(e) => handleBenchPosition(e)}
                                        />
                                        <TextField
                                            disabled
                                            label="Μέτρα"
                                            id="standard-basic"
                                            variant="standard"
                                            value={benchMeters}
                                        />


                                        <TextField
                                            disabled
                                            label="Barcode"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.barcode}
                                        />


                                        <TextField
                                            disabled
                                            label="Qr Code"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.qrcode}
                                        />



                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled
                                                checked={props.anastoli}
                                                // onChange={(e) => this.checkBoxHandle(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label='Αναστολή' />


                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled
                                                checked={props.adeiaAllouDimou}
                                                // onChange={(e) => this.checkBoxHandle(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label="Άδεια Άλλου Δήμου" />



                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled
                                                checked={props.diakopi}

                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label="Διακοπή" />


                                    </CardContent>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", display: "flex", flexDirection: "column" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Χαρακτηριστικά
                                        </Typography>

                                        <TextField
                                            label="Μέτρα"
                                            id="standard-basic"
                                            variant="standard"
                                            type="text"
                                            onChange={(e) => this.handleRealSquares(e)}
                                        />

                                        <TextField

                                            label="Κατηγορία"
                                            id="standard-basic"
                                            variant="standard"
                                            onChange={(e) => this.handleCategoryText(e)}
                                        />

                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={this.state.isPaid}
                                                onChange={(e) => this.handlePaymentCheckBox(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label={this.state.isPaidLabel} />

                                    </CardContent>
                                </Grid> */}
                                <Grid item xs={6}>
                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", boxShadow: "0 0 6px cyan" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Παρουσία Πάγκου
                                        </Typography>


                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={isPresent}
                                                onChange={() => setIsPresent(!isPresent)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label='Παρών' />

                                    </CardContent>
                                </Grid>
                                <Grid item xs={6}>
                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", display: "flex", flexDirection: "column", boxShadow: "0 0 6px cyan" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Παράνομος Πάγκος
                                        </Typography>

                                        <TextField
                                            label="Παρατηρήσεις "
                                            id="standard-basic"
                                            variant="standard"
                                            multiline
                                            maxRows={4}
                                            onChange={(e) => setIllegalTextOne(e.target.value)}
                                        />

                                        <TextField
                                            label="Παρατηρήσεις 2"
                                            id="standard-basic"
                                            variant="standard"
                                            multiline
                                            maxRows={4}
                                            onChange={(e) => setIllegalTextTwo(e.target.value)}
                                        />
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={isIllegal}
                                                onChange={() => setIsIllegal(!isIllegal)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label='Ναι' />
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {/* TODO: alert message when the form is saved */}
                        <Button variant="contained" color="success" onClick={saveCard}>
                            Αποθηκευση
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Ακυρο
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export { SuperVisorModal }