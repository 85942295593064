import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom'
import Button from "@mui/material/Button";
import { DeleteBussiness } from './create-business-modal.jsx'




export default function BasicTable({ data, handleBusinessDeleteEnd1 }) {

    const handleBusinessDeleteEnd = (e) => {
        handleBusinessDeleteEnd1()
    }

    return (

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>

                        <TableCell align="left">Επωνυμία</TableCell>
                        <TableCell align="left">Είδος</TableCell>
                        <TableCell align="left">Διεύθυνση</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data.map((row) => (
                        < TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{row.nameE}</TableCell>
                            <TableCell align="left">{row.iconeE}</TableCell>
                            <TableCell align="left">{row.dieuthinsi}</TableCell>

                            <TableCell>

                                <Button
                                    color="success"
                                    // onClick={() => {
                                    //     window.location.href = "/business-details";
                                    // }}
                                    // component={Link to="/business-details"}
                                    component={Link} to={{ pathname: `/business-details/${row.id}`, state: { id: row.id } }} >
                                    {/* //TODO OnClick go to detail view */}
                                    Λεπτομeρειες
                                </Button >
                            </TableCell>

                            <TableCell>

                                <DeleteBussiness
                                    bussinessID={row.id}
                                    bussinessName={row.nameE}
                                    handleBusinessDeleteEnd={(e) =>
                                        handleBusinessDeleteEnd(e)
                                    }
                                ></DeleteBussiness>

                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
}