import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import session from '../api/session'







const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function EidosAdeiasDeleteSwal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onDelete = () => {
        session.delete(`api/eidos-adeias/${props.eidosAdeiasID}/actions/`).then(() => setOpen(false), props.eidosAdeiasEnd())
    }

    return (
        <div>
            <Button onClick={handleOpen} color="error">
                Διαγραφη
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Είστε Σίγουροι; Διαγραφή Είδος Άδειας
                    </Typography>

                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <CardContent>

                                <Button
                                    onClick={onDelete}
                                    variant="outlined"
                                    color="error"
                                >
                                    {" "}
                                    Διαγραφη
                                </Button>
                                <Button
                                    onClick={handleClose}
                                >
                                    {" "}
                                    Ακυρο
                                </Button>
                            </CardContent>
                        </Box>
                    </Stack>

                </Box>

            </Modal>
        </div>
    )

}

function EidosAdeiasModal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [title, setTitle] = React.useState(props.title ? props.title : '')

    const titleChange = (e) => {
        setTitle(e.target.value)
    }

    const onSave = () => {
        if (props.action === 'Create') {
            session.post('api/eidos-adeias/create/', {
                title: title
            }).then(() => setOpen(false), props.eidosAdeiasEnd(), setTitle(''))
        } else {
            session.put(`api/eidos-adeias/${props.eidosAdeiasID}/actions/`, { title: title }).then(() => setOpen(false), props.eidosAdeiasEnd())
        }
    }





    return (
        <div>
            <Button onClick={handleOpen}>
                {props.action === 'Create' ? 'Δημιουργια' : 'Επεξεργασια'}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {props.action === 'Create' ? 'Δημιουργία' : 'Επεξεργασία'} Είδος Άδειας
                    </Typography>
                    <hr />
                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <CardContent>
                                <TextField
                                    label="Τίτλος"
                                    id="outlined-multiline-flexible"
                                    multiline
                                    maxRows={10}
                                    value={title}
                                    onChange={titleChange}

                                />
                                <Button
                                    onClick={onSave}
                                >
                                    {" "}
                                    Αποθηκευση
                                </Button>
                            </CardContent>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </div >
    )
}

function StadioParabasisDeleteSwal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const onDelete = () => {
        session
            .delete(`api/stadio-parabasis/${props.stadioID}/action/`)
            .then(() => setOpen(false), props.handleStadioEnd());
    }

    return (
        <div>
            <Button onClick={handleOpen} color="error">
                Διαγραφη
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Είστε Σίγουροι; Διαγραφή Στάδιο Παράβασης
                    </Typography>

                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <CardContent>

                                <Button
                                    onClick={onDelete}
                                    variant="outlined"
                                    color="error"
                                >
                                    {" "}
                                    Διαγραφη
                                </Button>
                                <Button
                                    onClick={handleClose}
                                >
                                    {" "}
                                    Ακυρο
                                </Button>
                            </CardContent>
                        </Box>
                    </Stack>

                </Box>

            </Modal>
        </div>
    )
}


function StadiaParabaseisModal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [title, setTitle] = React.useState(props.title ? props.title : "")

    const titleChange = (e) => {
        setTitle(e.target.value)
    }
    const onSave = () => {
        if (props.action === 'Create') {

            session
                .post("api/stadio-parabasis/create/", {
                    title: title
                }).then(() => setOpen(false), props.handleStadioEnd(), setTitle(''))

        } else {
            session
                .put(`api/stadio-parabasis/${props.stadioID}/action/`, {
                    title: title
                }).then(() => setOpen(false), props.handleStadioEnd())
        }
    }

    return (
        <div>
            <Button onClick={handleOpen}>
                {props.action === 'Create' ? 'Δημιουργια' : 'Επεξεργασια'}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {props.action === 'Create' ? 'Δημιουργία' : 'Επεξεργασία'} Στάδιο Παράβασης
                    </Typography>
                    <hr />
                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <CardContent>
                                <TextField
                                    label="Τίτλος"
                                    id="outlined-multiline-flexible"
                                    multiline
                                    maxRows={10}
                                    value={title}
                                    onChange={titleChange}

                                />
                                <Button
                                    onClick={onSave}
                                >
                                    {" "}
                                    Αποθηκευση
                                </Button>
                            </CardContent>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </div >
    )

}

function EidosParabasisDeleteSwal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const onDelete = () => {

        session
            .delete(`api/eidos-parabasis/${props.EidosID}/action/`)
            .then(() => setOpen(false), props.handleEnd());
    }



    return (
        <div>
            <Button onClick={handleOpen} color="error">
                Διαγραφη
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Είστε Σίγουροι; Διαγραφή Είδος Παράβασης
                    </Typography>

                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <CardContent>

                                <Button
                                    onClick={onDelete}
                                    variant="outlined"
                                    color="error"
                                >
                                    {" "}
                                    Διαγραφη
                                </Button>
                                <Button
                                    onClick={handleClose}
                                >
                                    {" "}
                                    Ακυρο
                                </Button>
                            </CardContent>
                        </Box>
                    </Stack>

                </Box>

            </Modal>
        </div>
    )

}

function EidosParabasisModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [title, setTitle] = React.useState(props.title ? props.title : '')


    const titleChange = (e) => {
        setTitle(e.target.value)
    }

    const onSave = () => {
        if (props.action === 'Create') {
            session
                .post("api/eidos-parabasis/create/", {
                    title: title
                }).then(() => setOpen(false), props.handleEnd(), setTitle(''))
        } else {

            session
                .put(`api/eidos-parabasis/${props.eidosId}/action/`, {
                    title: title,
                })
                .then(() => setOpen(false), props.handleEnd());

        }

    }

    return (
        <div>
            <Button onClick={handleOpen}>
                {props.action === 'Create' ? 'Δημιουργια' : 'Επεξεργασια'}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {props.action === 'Create' ? 'Δημιουργία' : 'Επεξεργασία'} Είδος Παράβασης
                    </Typography>
                    <hr />
                    <Stack spacing={5}>
                        <Box sx={{ flexGrow: 1 }}>
                            <CardContent>
                                <TextField
                                    label="Τίτλος"
                                    id="outlined-multiline-flexible"
                                    multiline
                                    maxRows={10}
                                    value={title}
                                    onChange={titleChange}

                                />
                                <Button
                                    onClick={onSave}
                                >
                                    {" "}
                                    Αποθηκευση
                                </Button>
                            </CardContent>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </div >
    )

}





export {
    EidosParabasisModal,
    EidosParabasisDeleteSwal,
    StadiaParabaseisModal,
    StadioParabasisDeleteSwal,
    EidosAdeiasModal,
    EidosAdeiasDeleteSwal
}