import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import axios from "axios";
// import PDFViewer from "pdf-viewer-reactjs";
import DownloadLink from "react-download-link";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "components/ReactTable/ReactTable.js";
// import style from "./style.css";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  Tooltip,
  useMapEvents,
} from "react-leaflet";
import {
  Badge,
  CardFooter,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import L from "leaflet";
import Parallax from "components/Parallax/Parallax.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PDFDownloadLink, Document, Page } from "react-pdf";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import session from '../api/session'
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let UdataTable = [];
let center = [39.365, 22.951];
let zoom = 14;

async function setDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadGnwst";
  console.log(item);

  let formData = new FormData();
  formData.append("gnwstopoiisi", item.gnwstopoiisi);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post('api/docuploadGnwst',formData)

  // let response = await axios.post(url, formData);
  console.log(response);
}
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
export default function Gnwstopoiiseis(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  React.useEffect(() => {
    // console.log("e");
    // console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
       <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GnwstopoiiseisPanel />
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}
class GnwstopoiiseisPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      GnwstopoiiseisList: [],
      EpixeiriseisList: [],
      EpixeiriseisCategoryList: [],
      markers: [[0, 0]],
      map: null,
      Gnwstopoiiseisselected: "",
      activeItemG: {},
      activeItemGK: {},
      activeItemGKItem: {},
      numPages: 0,
      pageNumber: 1,
      docsList: [],
      selectedGnwstopoiiseisDocs: [],
      GnwstopoiiseisKatList: [],
      Gnwstopoiiseiscategoryselected: "-",
      modalG: false,
      arxdocitem: {},
      selectedDocs: [],
      epixirisiselected: "",
      isediting: false,
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListEpixeiriseis();
    this.refreshListGnwstopoiiseisDocs();
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
  }

  refreshListGnwstopoiiseisDocs() {
    session.get('api/FileFieldFormGnwst/')
    .then((res) => this.setState({ docsList: res.data }))
    .catch((err) => console.log(err));
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormGnwst/")
    //   .then((res) => this.setState({ docsList: res.data }))
    //   .catch((err) => console.log(err));
  }

  refreshList() {
    session.get('api/Gnwstopoiiseis/')
    .then((res) => this.setState({ GnwstopoiiseisList: res.data }))
      .catch((err) => console.log(err));
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Gnwstopoiiseis/")
    //   .then((res) => this.setState({ GnwstopoiiseisList: res.data }))
    //   .catch((err) => console.log(err));

    session.get('api/GnwstopoiiseisKatastasi/')
    .then((res) => this.setState({ GnwstopoiiseisKatList: res.data }))
    .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/")
    //   .then((res) => this.setState({ GnwstopoiiseisKatList: res.data }))
    //   .catch((err) => console.log(err));
  }

  refreshListEpixeiriseis() {
    session.get('api/Epixeiriseis/')
    .then((res) => this.setState({ EpixeiriseisList: res.data }))
      .catch((err) => console.log(err));
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/")
    //   .then((res) => this.setState({ EpixeiriseisList: res.data }))
    //   .catch((err) => console.log(err));

    session.get('api/EpixeirisiCategory/')
    .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
    .catch((err) => console.log(err));
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/EpixeirisiCategory/")
    //   .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
    //   .catch((err) => console.log(err));
  }

  handleCloseG = () => {
    this.state.activeItemG = [];
    this.setState({ activeItemG: [] });
    this.setState({ modalG: false });
  };

  handleNewG = () => {
    // console.log(item);
    this.state.activeItemG.etos = "";
    this.state.activeItemG.fakelos = "";
    this.state.activeItemG.syntaxas = "";
    this.state.activeItemG.onomatepwnymoEG = "";
    this.state.activeItemG.perigrafiEG = "";
    this.state.activeItemG.afmEG = "";
    this.state.activeItemG.thlefwnoEG = "";
    this.state.activeItemG.dieythinsi = "";
    this.state.activeItemG.poli = "";
    this.state.activeItemG.ygeionomikosE = "";
    this.state.activeItemG.pyrosvestikosE = "";
    this.state.activeItemG.poleodomiaE = "";
    this.state.activeItemG.paratiriseis = "";

    this.state.activeItemG.epix = "";

    // this.state.activeItemG = item;
    this.setState({ modalG: true });
    // console.log(this.state.modalG);
    // console.log(this.state.activeItemG);
    // this.render();
  };

  handleEpixirisiSelect(e) {
    const value = e.target.value;

    console.log(value);

    this.state.epixirisiselected = value;

    // const latlng = value.coordsE;
    // const { map } = this.state;
    // if (map) map.flyTo(latlng, 18);

    this.state.activeItemG.epix = value;

    this.setState({ state: this.state });
    this.refreshListEpixeiriseis();
    this.render();
  }

  handleNewGK = () => {
    const newItemGK = this.state.activeItemGK;

    if (!this.state.activeItemG.id) {
      this.handlePost2();
    }

    newItemGK.gnwstopoiisi = this.state.activeItemG.nameG;

    if (newItemGK.id) {
      session.put(`api/GnwstopoiiseisKatastasi/${newItemGK.id}/`)
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/${newItemGK.id}/`,
      //     newItemGK
      //   )
        .then(this.refreshList());
    } else {
      session.post('api/GnwstopoiiseisKatastasi/',newItemGK)
      // axios
      //   .post(
      //     "https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/",
      //     newItemGK
      //   )
        .then(this.refreshList());
    }

    this.setState({ state: this.state });
    this.refreshList();
    this.render();

    // console.log(item);

    this.state.activeItemGK.id = "";
  };

  handleChangeGKKat = (e, item) => {
    const value = e.target.value;

    this.state.activeItemGK.katastasi = value;

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleChangeGKAA = (e, item) => {
    const value = e.target.value;

    console.log(value);
    console.log(item);

    this.refreshList();
    this.render();
  };

  handleChangeGKEk = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemGKItem.hmerominiaEkdosis = fulldate;

    console.log(this.state.activeItemGKItem);
    this.setState({ state: this.state });
    // this.setState({ activeItemE });
  };

  handleChangeGKGn = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemGKItem.hmerominiaGnwst = fulldate;

    console.log(this.state.activeItemGKItem);
    this.setState({ state: this.state });
    // this.setState({ activeItemE });
  };

  handleEditG = (item) => {
    console.log(this.state.docsList);

    this.refreshListGnwstopoiiseisDocs();

    this.state.selectedDocs = this.state.docsList.filter(
      (itemS) => String(itemS.gnwstopoiisi) === String(item.nameG)
    );

    console.log(this.state.selectedDocs);

    this.state.activeItemG = item;

    let gnwstopoihseisKatastasiItems = this.state.GnwstopoiiseisKatList.filter(
      (itemS) => String(itemS.gnwstopoiisi) === String(item.nameG)
    );

    // if (gnwstopoihseisKatastasiItems[0] != undefined) {
    //   this.state.activeItemGK = gnwstopoihseisKatastasiItems[0];
    // }

    // this.state.activeItemG = item;
    this.setState({ modalG: true });
    // console.log(this.state.modalG);
    console.log(this.state.activeItemG);
    // this.render();
  };

  handleEditGK = (item) => {
    this.state.activeItemGK = item;
    // this.state.activeItemG = item;
    this.setState({ modalGK: true });
    // console.log(this.state.modalG);
    console.log(this.state.activeItemGK);
    // this.render();
  };

  handleAkyrwsoEditGKItem = (item) => {
    this.state.isediting = false;
    this.state.activeItemGKItem = [];
    this.setState({ state: this.state });
    this.refreshList();
    this.render();

    // this.render();
  };

  handleEditGKItem = (item) => {
    this.state.activeItemGKItem = item;

    this.state.isediting = true;

    console.log(item);
    // console.log(this.state.modalG);
    console.log(this.state.activeItemGKItem);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();

    // this.render();
  };

  handleChangeArxDoc = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    console.log(this.state.activeItemG);

    this.state.arxdocitem.file_field = e.target.files[0];
    this.state.arxdocitem.description = fname[0];
    this.state.arxdocitem.gnwstopoiisi = this.state.activeItemG.nameG;
  };

  handleChangeG = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemG = { ...this.state.activeItemG, [name]: value };
    // formData.append([name], value);
    console.log("ok");
    this.setState({ activeItemG });
  };
  handleChangeGK = (e, item) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemGKItem = { ...this.state.activeItemGKItem, [name]: value };

    // formData.append([name], value);
    this.setState({ activeItemGKItem });
  };

  handleDeleteGKItem = (id) => {
    session.delete('api/GnwstopoiiseisKatastasi/${id}/')
    // axios
    //   .delete(
    //     `https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/${id}/`
    //   )
      .then(this.refreshList());

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handlePostGKItem = () => {
    const newItem = this.state.activeItemGKItem;

    session.put(`api/GnwstopoiiseisKatastasi/${newItem.id}/`)
    // axios
    //   .put(
    //     `https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/${newItem.id}/`,
    //     newItem
    //   )
      .then(this.refreshList());

    this.state.isediting = false;
    this.state.activeItemGKItem = [];
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  async handlePost2() {
    const newItem = this.state.activeItemG;
    newItem.nameG =
      newItem.etos + "-" + String(newItem.syntaxas).replace("", "_");

    this.state.arxdocitem.nameG = newItem.nameG;
    
    // let response = await axios.post(
    //   "https://epixirin-volos.govcloud.gr/be/api/Gnwstopoiiseis/",
    //   newItem
    // );
    let response =await session.post('api/Gnwstopoiiseis/')

    console.log(response);

    this.state.activeItemG.id = response.data.id;

    this.refreshList();

    if (this.state.arxdocitem.file_field != undefined) {
      setDoc(this.state.arxdocitem);
      this.refreshListGnwstopoiiseisDocs();
    }

    this.refreshListGnwstopoiiseisDocs();

    this.refreshList();
  }

  handlePostG = () => {
    const newItem = this.state.activeItemG;

    const newItemGK = this.state.activeItemGK;

    newItemGK.gnwstopoiisi = newItem.nameG;

    console.log(newItem);
    console.log(newItemGK);

    if (newItem.id) {
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/Gnwstopoiiseis/${newItem.id}/`,
      //     newItem
      //   )
        session.put(`api/Gnwstopoiiseis/${newItem.id}/`)
        .then(this.refreshList());

      if (this.state.arxdocitem.file_field != undefined) {
        setDoc(this.state.arxdocitem);
        this.refreshListGnwstopoiiseisDocs();
      }
      this.refreshListGnwstopoiiseisDocs();
    } else {
      newItem.nameG =
        newItem.etos + "-" + String(newItem.syntaxas).replace("", "_");

      this.state.arxdocitem.nameG = newItem.nameG;

      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/Gnwstopoiiseis/", newItem)
        session.post('api/Gnwstopoiiseis/',newItem)
        .then(this.refreshList());

      if (this.state.arxdocitem.file_field != undefined) {
        setDoc(this.state.arxdocitem);
        this.refreshListGnwstopoiiseisDocs();
      }
      this.refreshListGnwstopoiiseisDocs();
    }

    this.state.activeItemG = [];
    this.refreshList();
    this.handleCloseG();

    // if (newItemGK.id) {
    //   axios
    //     .put(
    //       `https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/${newItemGK.id}/`,
    //       newItemGK
    //     )
    //     .then(this.refreshList());
    // } else {
    //   axios
    //     .post(
    //       "https://epixirin-volos.govcloud.gr/be/api/GnwstopoiiseisKatastasi/",
    //       newItemGK
    //     )
    //     .then(this.refreshList());
    // }

    document.getElementById("file_field").value = null;

    // }
    // newE = true;
  };

  handleDeleteG = (item) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/Gnwstopoiiseis/${item.id}`)
      session.delete(`api/Gnwstopoiiseis/${item.id}`)
      .then(this.refreshList());
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleDeleteDoc = (id) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/FileFieldFormGnwst/${id}`)
      session.delete(`api/FileFieldFormGnwst/${id}`)
      .then(this.refreshListGnwstopoiiseisDocs());
    this.refreshList();
    this.refreshListGnwstopoiiseisDocs();
    this.setState({ state: this.state });
    this.setState({ modalG: false });
    this.render();
    document.getElementById("file_field").value = null;

    // window.location.reload(false);
    // this.setState({ modalP: true });
    // this.setState({ modalE: true });
    this.yourFunction();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunction() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    this.handleEditG(this.state.activeItemG);
  }

  renderKatastasi() {
    let gnwstopoihseisKatastasiItems = this.state.GnwstopoiiseisKatList.filter(
      (itemS) =>
        String(itemS.gnwstopoiisi) === String(this.state.activeItemG.nameG)
    );

    console.log(gnwstopoihseisKatastasiItems);

    return gnwstopoihseisKatastasiItems.map((item) => (
      // return (
      <>
        <Card>
          {item.katastasi == "Νέα" && (
            <CardHeader color="success">
              {String(item.katastasi)} {"-"} {String(item.argnwst)}
              <Button
                round
                color="info"
                onClick={(e) => this.handleEditGKItem(item)}
                size="sm"
              >
                <i className="fa fa-edit" />
              </Button>
            </CardHeader>
          )}
          {item.katastasi == "Μεταβολή" && (
            <CardHeader color="warning">
              {String(item.katastasi)} {"-"} {String(item.argnwst)}
              <Button
                round
                color="info"
                onClick={(e) => this.handleEditGKItem(item)}
                size="sm"
              >
                <i className="fa fa-edit" />
              </Button>
            </CardHeader>
          )}
          {item.katastasi == "Ακύρωση" && (
            <CardHeader color="danger">
              {String(item.katastasi)} {"-"} {String(item.argnwst)}
              <Button
                round
                color="info"
                onClick={(e) => this.handleEditGKItem(item)}
                size="sm"
              >
                <i className="fa fa-edit" />
              </Button>
            </CardHeader>
          )}

          {this.state.isediting == true && (
            <>
              {this.state.activeItemGKItem.id === item.id && (
                <CardBody>
                  <Row>
                    <Col>
                      <CustomInput
                        id="argnwst"
                        inputProps={{
                          value: this.state.activeItemGKItem.argnwst,
                          placeholder: "Αρ. Γνωστοποίησης",
                          onChange: (e) => this.handleChangeGK(e, item),
                          type: "text",
                        }}
                      />
                      <CustomInput
                        inputProps={{
                          value: this.state.activeItemGKItem.katastasi,
                          disabled: true,
                          placeholder: "Τύπος Γνωστοποίησης",
                          // onChange: (e) => this.handleChangeGK(e),
                          type: "text",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="hmerominiaEkdosis"
                          name="hmerominiaEkdosis"
                          showTodayButton
                          label="Ημερομηνία έκδοσης"
                          value={this.state.activeItemGKItem.hmerominiaEkdosis}
                          onChange={(e) => this.handleChangeGKEk(e, item)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="hmerominiaGnwst"
                          name="hmerominiaGnwst"
                          label="Ημερομηνία κοινοποίησης"
                          value={this.state.activeItemGKItem.hmerominiaGnwst}
                          onChange={(e) => this.handleChangeGKGn(e, item)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>

                  <Row>
                    {" "}
                    <Col>
                      <CustomInput
                        id="kad"
                        inputProps={{
                          value: this.state.activeItemGKItem.kad,
                          placeholder: "Κ.Α.Δ.",
                          onChange: (e) => this.handleChangeGK(e, item),
                          type: "text",
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Card>
                    <CardHeader>Βεβαίωση Εγκατάστασης</CardHeader>
                    <CardBody>
                      <CustomInput
                        id="aprVE"
                        inputProps={{
                          value: this.state.activeItemGKItem.aprVE,
                          placeholder: "Αρ. Πρωτοκόλλου",
                          onChange: (e) => this.handleChangeGK(e, item),
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="kadVE"
                        inputProps={{
                          value: this.state.activeItemGKItem.kadVE,
                          placeholder: "Κ.Α.Δ.",
                          onChange: (e) => this.handleChangeGK(e, item),
                          type: "text",
                        }}
                      />
                    </CardBody>
                  </Card>
                  <Row>
                    <Col>
                      <CustomInput
                        id="apa"
                        inputProps={{
                          value: this.state.activeItemGKItem.apa,
                          placeholder: "Αρ. Παραβόλου",
                          onChange: (e) => this.handleChangeGK(e, item),
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="dimen"
                        inputProps={{
                          value: this.state.activeItemGKItem.dimen,
                          placeholder: "Δημοτική ενημερότητα",
                          onChange: (e) => this.handleChangeGK(e, item),
                          type: "text",
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              )}
              {this.state.activeItemGKItem.id !== item.id && (
                <CardBody>
                  <Row>
                    <Col>
                      <CustomInput
                        disabled
                        className="mine"
                        id="argnwst"
                        inputProps={{
                          disabled: true,
                          value: item.argnwst,
                          placeholder: "Αρ. Γνωστοποίησης",
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="argnwst"
                        inputProps={{
                          value: item.katastasi,
                          disabled: true,
                          placeholder: "Τύπος Γνωστοποίησης",
                          type: "text",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="hmerominiaEkdosis"
                          name="hmerominiaEkdosis"
                          showTodayButton
                          label="Ημερομηνία έκδοσης"
                          value={item.hmerominiaEkdosis}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="hmerominiaGnwst"
                          name="hmerominiaGnwst"
                          label="Ημερομηνία κοινοποίησης"
                          value={item.hmerominiaGnwst}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>

                  <Row>
                    {" "}
                    <Col>
                      <CustomInput
                        id="kad"
                        inputProps={{
                          disabled: true,
                          value: item.kad,
                          placeholder: "Κ.Α.Δ.",
                          type: "text",
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Card>
                    <CardHeader>Βεβαίωση Εγκατάστασης</CardHeader>
                    <CardBody>
                      <CustomInput
                        id="aprVE"
                        inputProps={{
                          disabled: true,
                          value: item.aprVE,
                          placeholder: "Αρ. Πρωτοκόλλου",
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="kadVE"
                        inputProps={{
                          disabled: true,
                          value: item.kadVE,
                          placeholder: "Κ.Α.Δ.",
                          type: "text",
                        }}
                      />
                    </CardBody>
                  </Card>
                  <Row>
                    <Col>
                      <CustomInput
                        id="apa"
                        inputProps={{
                          disabled: true,
                          value: item.apa,
                          placeholder: "Αρ. Παραβόλου",
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="dimen"
                        inputProps={{
                          disabled: true,
                          value: item.dimen,
                          placeholder: "Δημοτική ενημερότητα",
                          type: "text",
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              )}
            </>
          )}

          {this.state.isediting == false && (
            <CardBody>
              <Row>
                <Col>
                  <CustomInput
                    disabled
                    className="mine"
                    id="argnwst"
                    inputProps={{
                      disabled: true,
                      value: item.argnwst,
                      placeholder: "Αρ. Γνωστοποίησης",
                      type: "text",
                    }}
                  />
                  <CustomInput
                    id="argnwst"
                    inputProps={{
                      value: item.katastasi,
                      disabled: true,
                      placeholder: "Τύπος Γνωστοποίησης",
                      type: "text",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      disabled
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="hmerominiaEkdosis"
                      name="hmerominiaEkdosis"
                      showTodayButton
                      label="Ημερομηνία έκδοσης"
                      value={item.hmerominiaEkdosis}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      disabled
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="hmerominiaGnwst"
                      name="hmerominiaGnwst"
                      label="Ημερομηνία κοινοποίησης"
                      value={item.hmerominiaGnwst}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Col>
              </Row>

              <Row>
                {" "}
                <Col>
                  <CustomInput
                    id="kad"
                    inputProps={{
                      disabled: true,
                      value: item.kad,
                      placeholder: "Κ.Α.Δ.",
                      type: "text",
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Card>
                <CardHeader>Βεβαίωση Εγκατάστασης</CardHeader>
                <CardBody>
                  <CustomInput
                    id="aprVE"
                    inputProps={{
                      disabled: true,
                      value: item.aprVE,
                      placeholder: "Αρ. Πρωτοκόλλου",
                      type: "text",
                    }}
                  />
                  <CustomInput
                    id="kadVE"
                    inputProps={{
                      disabled: true,
                      value: item.kadVE,
                      placeholder: "Κ.Α.Δ.",
                      type: "text",
                    }}
                  />
                </CardBody>
              </Card>
              <Row>
                <Col>
                  <CustomInput
                    id="apa"
                    inputProps={{
                      disabled: true,
                      value: item.apa,
                      placeholder: "Αρ. Παραβόλου",
                      type: "text",
                    }}
                  />
                  <CustomInput
                    id="dimen"
                    inputProps={{
                      disabled: true,
                      value: item.dimen,
                      placeholder: "Δημοτική ενημερότητα",
                      type: "text",
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          )}

          {this.state.isediting == true && (
            <>
              <Button
                round
                color="success"
                size="sm"
                onClick={() => this.handlePostGKItem()}
              >
                Αποθήκευση
              </Button>
              <Button
                round
                color="warning"
                size="sm"
                onClick={() => this.handleAkyrwsoEditGKItem()}
              >
                Ακύρωση
              </Button>
            </>
          )}
          {this.state.isediting == false && (
            <Button
              round
              color="danger"
              size="sm"
              onClick={() => this.handleDeleteGKItem(item.id)}
            >
              Διαγραφή
            </Button>
          )}
        </Card>
        <br />
        {/* </CardBody>
        </Card>
        <br />
        <br /> */}
      </>
    ));
  }

  render() {
    const classes = this.state.useStyles;
    let gnwstopoihseisItems = this.state.GnwstopoiiseisList;

    for (var i = 0; i < gnwstopoihseisItems.length; i++) {
      gnwstopoihseisItems[i].aa = i + 1;
    }

    let EpixeiriseisItems = this.state.EpixeiriseisList;
    const Epcategories = this.state.EpixeiriseisCategoryList;

    let EpixeiriseisItemsToShow = this.state.EpixeiriseisList.filter(
      (item) => item.nameE == this.state.activeItemG.epix
    );

    UdataTable = [];

    for (var i = 0; i < gnwstopoihseisItems.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      // const name = postcat[i].namePoC;
      const aa = gnwstopoihseisItems[i].aa;
      const etos = gnwstopoihseisItems[i].etos;
      const fakelos = gnwstopoihseisItems[i].fakelos;
      const epix = gnwstopoihseisItems[i].epix;
      const item = gnwstopoihseisItems[i];

      dataRow.aa = aa;
      dataRow.etos = etos;
      dataRow.fakelos = fakelos;
      dataRow.epix = epix;

      dataRow.selectLa = (
        <>
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditG(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeleteG(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      // tmpItemsLaikes[i].nameLA;
      // dataRow.perioxi = tmpItemsLaikes[i].perioxiA;

      UdataTable.push(dataRow);
    }

    console.log(EpixeiriseisItemsToShow);

    if (Epcategories != undefined) {
      for (var i = 0; i < EpixeiriseisItemsToShow.length; i++) {
        // console.log(EpixeiriseisItemsToShow[0].iconeE);
        // console.log(Epcategories);
        let categorytmp = Epcategories.filter(
          (item) =>
            String(item.nameEC) === String(EpixeiriseisItemsToShow[i].iconeE)
        );

        if (categorytmp[0] != undefined) {
          // console.log(categorytmp);

          EpixeiriseisItemsToShow[i].logo = categorytmp[0].logo;

          const myIcon = L.icon({
            iconUrl: categorytmp[0].logo,
            iconSize: [25, 25],
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
          });

          EpixeiriseisItemsToShow[i].logo2 = myIcon;

          // const iconPerson = new L.Icon({
          //   iconUrl: require(categorytmp[0].logo),
          //   iconRetinaUrl: require(categorytmp[0].logo),
          //   iconAnchor: null,
          //   popupAnchor: null,
          //   shadowUrl: null,
          //   shadowSize: null,
          //   shadowAnchor: null,
          //   iconSize: new L.Point(60, 75),
          //   className: "leaflet-div-icon",
          // });

          // EpixeiriseisItemsToShow[i].logo = iconPerson;
        }
      }
    } else {
      Epcategories = undefined;
      this.setState({ state: this.state });
    }

    return (
      <>
        <GridContainer>
          <GridItem>
            <Card>
              <CardHeader color="success">
                Γνωστοποιήσεις{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewG()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>

              {/* <GridContainer direction="row" justify="center" alignItems="center">
              <h3>
                {" "}
                {"Γνωστοποιήσεις"}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewG()}
                >
                  <i className="fa fa-plus"></i>
                </Button>{" "}
              </h3>
            </GridContainer> */}
              <CardBody>
                <ReactTable
                  data={UdataTable}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "ΑΑ",
                      accessor: "aa",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Έτος",
                      accessor: "etos",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Φάκελος",
                      accessor: "fakelos",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Επιχείρηση",
                      accessor: "epix",
                      disableFilters: false,
                      disableSortBy: false,
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "selectLa",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />

                {/* <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Α/Α</TableCell>
                    <TableCell>Έτος</TableCell>
                    <TableCell>Φάκελος</TableCell>
                    <TableCell>Ενέργειες</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gnwstopoihseisItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {/* <Radio
                        checked={
                          parseInt(this.state.Gnwstopoiiseisselected) ===
                          parseInt(item.id)
                        }
                        onChange={(e) => this.handleEpixirisiSelect(e)}
                        value={item.id}
                        name="radio-button-demo"
                      /> 
                        {item.aa}
                      </TableCell>
                      <TableCell>
                        {/* <Radio
                        checked={
                          parseInt(this.state.Gnwstopoiiseisselected) ===
                          parseInt(item.id)
                        }
                        onChange={(e) => this.handleEpixirisiSelect(e)}
                        value={item.id}
                        name="radio-button-demo"
                      />
                        {item.etos}
                      </TableCell>
                      <TableCell>{item.fakelos}</TableCell>
                      {/* <TableCell>{item.author}</TableCell> */}
                {/* <TableCell>{item.created_on.split("T")[0]}</TableCell> 
                      <TableCell>
                        <Button
                          round
                          color="info"
                          size="sm"
                          onClick={() => this.handleEditG(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>{" "}
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.handleDeleteG(item)}
                        >
                          <i className="fa fa-times"></i>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalG}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="lg"
          onClose={() => this.handleCloseG()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}> Στοιχεία Γνωστοποίησης</h4>

            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseG()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              {/* <Row> */}
              <GridItem xs={6}>
                {/* <Row> */}
                <GridItem>
                  <br />
                  <Card>
                    <CardHeader color="success">
                      Στοιχεία Καταχώρισης
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        className="mine"
                        id="etos"
                        inputProps={{
                          value: this.state.activeItemG.etos,
                          placeholder: "Έτος",
                          onChange: (e) => this.handleChangeG(e),
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="fakelos"
                        inputProps={{
                          value: this.state.activeItemG.fakelos,
                          placeholder: "Φάκελος",
                          onChange: (e) => this.handleChangeG(e),
                          type: "text",
                        }}
                      />
                      <CustomInput
                        id="syntaxas"
                        inputProps={{
                          value: this.state.activeItemG.syntaxas,
                          placeholder: "Συντάξας*",
                          onChange: (e) => this.handleChangeG(e),
                          type: "text",
                        }}
                      />{" "}
                    </CardBody>
                  </Card>
                </GridItem>
                {/* </Row> */}
                {/* <Row> */}
                {/* <GridItem xs={2}> */}
                <br />
                <Card>
                  <CardHeader color="success">Στοιχεία Επιχείρησης</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="onomatepwnymoEG"
                      inputProps={{
                        value: this.state.activeItemG.onomatepwnymoEG,
                        placeholder: "Ονοματεπώνυμο",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="perigrafiEG"
                      inputProps={{
                        value: this.state.activeItemG.perigrafiEG,
                        placeholder: "Περιγραφή",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="afmEG"
                      inputProps={{
                        value: this.state.activeItemG.afmEG,
                        placeholder: "ΑΦΜ Επιχείρησης",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="thlefwnoEG"
                      inputProps={{
                        value: this.state.activeItemG.thlefwnoEG,
                        placeholder: "Τηλέφωνο",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="dieythinsi"
                      inputProps={{
                        value: this.state.activeItemG.dieythinsi,
                        placeholder: "Διεύθυνση",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="poli"
                      inputProps={{
                        value: this.state.activeItemG.poli,
                        placeholder: "Πόλη",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
                {/* </GridItem> */}
                {/* </Row> */}
                {/* <Row> */}
                {/* <GridItem xs={2}> */}
                <br />
                <Card>
                  <CardHeader color="success">Έλεγχοι</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="ygeionomikosE"
                      inputProps={{
                        value: this.state.activeItemG.ygeionomikosE,
                        placeholder: "Υγειονομικό",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="pyrosvestikosE"
                      inputProps={{
                        value: this.state.activeItemG.pyrosvestikosE,
                        placeholder: "Πυροσβεστική",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="poleodomiaE"
                      inputProps={{
                        value: this.state.activeItemG.poleodomiaE,
                        placeholder: "Πολεοδομία",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
                <br />
                <Card>
                  <CardHeader color="success">Παρατηρήσεις</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItemG.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChangeG(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
                <br />
                <Card>
                  <CardHeader color="success">Αρχεία</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        Αρχείο
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangeArxDoc(e)}
                        name="file_field"
                        type="file"
                      />
                    </FormGroup>
                    Διαθέσιμα αρχεία
                    {this.state.selectedDocs.map(
                      ({ id, file_field, description }) => (
                        <Badge>
                          <Button
                            size="sm"
                            round
                            onClick={() => window.open(file_field, "_blank")}
                          >
                            {" "}
                            {description}{" "}
                          </Button>

                          <Button
                            round
                            color="danger"
                            size="sm"
                            onClick={() => this.handleDeleteDoc(id)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </Badge>
                      )
                    )}
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader color="success">Τοποθεσία</CardHeader>
                  <CardBody>
                    <label>Επιλέξτε επιχείρηση</label>
                    <Select
                      labelId="demo-simple-select-label"
                      name="nameE"
                      id="nameE"
                      value={String(this.state.activeItemG.epix)}
                      onChange={(e) => this.handleEpixirisiSelect(e)}
                    >
                      <br />
                      <br />
                      {EpixeiriseisItems.map((item) => (
                        <MenuItem value={item.nameE}>{item.nameE}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <br />

                    {Epcategories.length != 0 && (
                      <MapContainer
                        center={center}
                        zoom={zoom}
                        maxZoom={21}
                        scrollWheelZoom={false}
                        style={{ height: "40vh", width: "550px" }}
                        whenCreated={(map) => this.setState({ map })}
                      >
                        <TileLayer
                          maxNativeZoom={19}
                          minZoom={0}
                          maxZoom={22}
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {/* <MyComponent /> */}
                        {/* <LocationMarker /> */}

                        {EpixeiriseisItemsToShow.map(
                          ({ _id, nameE, coordsE, logo, iconeE }) => (
                            <Marker
                              key={`marker-${_id}`}
                              position={coordsE}
                              // icon={item.logo}
                              icon={L.icon({
                                iconUrl: logo,
                                iconSize: [25, 35],
                                popupAnchor: null,
                                shadowUrl: null,
                                shadowSize: null,
                                shadowAnchor: null,
                              })}
                            >
                              <Tooltip>
                                {nameE}
                                <br></br>
                                {iconeE}
                              </Tooltip>
                            </Marker>
                          )
                        )}
                      </MapContainer>
                    )}
                    {/* <TextField
                  id="time"
                  label="Alarm clock"
                  type="time"
                  defaultValue={this.state.activeItemG.wrarioE}
                  className={classes.textField}
                  onChange={(e) => this.handleChangeEnarxiWrariou2(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 100, // 5 min
                  }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={this.state.activeItemG.wrarioE}
                    onChange={(e) => this.handleChangeEnarxiWrariou(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
                    {/* <CustomInput
                  id="wrarioE"
                  inputProps={{
                    value: this.state.activeItemG.wrarioE,
                    placeholder: "Έναρξη ωραρίου (ΩΩ:ΛΛ)",
                    onChange: (e) => this.handleChangeG(e),
                    type: "text",
                  }}
                />
              
                <CustomInput
                  id="wrarioL"
                  inputProps={{
                    value: this.state.activeItemG.wrarioL,
                    placeholder: "Λήξη ωραρίου (ΩΩ:ΛΛ)",
                    onChange: (e) => this.handleChangeG(e),
                    type: "text",
                  }}
                /> */}
                    {/* <CustomInput
                  id="covidStatus"
                  inputProps={{
                    value: this.state.activeItemG.covidStatus,
                    placeholder: "Covid Status",
                    onChange: (e) => this.handleChangeG(e),
                    type: "text",
                  }}
                /> */}
                  </CardBody>
                </Card>
                {/* </GridItem> */}
                {/* </Row> */}
              </GridItem>
              <GridItem xs={6}>
                {/* <Row> */}
                {/* <GridItem xs={2}> */}
                <br />
                <Card>
                  <CardHeader color="success">
                    Στοιχεία Γνωστοποιήσης
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {/* {this.state.activeItemG.id && ( */}
                      {/* <> */}
                      <Button
                        round
                        color="info"
                        size="sm"
                        onClick={() => this.handleNewGK()}
                      >
                        <i className="fa fa-plus"></i>
                      </Button>

                      {this.state.activeItemG.id && (
                        <>
                          {/* <Button
                        round
                        color="info"
                        size="sm"
                        onClick={() => this.handleNewGK()}
                      >
                        <i className="fa fa-plus"></i>
                      // </Button>{" "} */}

                          <Select
                            labelId="demo-simple-select-label"
                            name="katastasi"
                            id="katastasi"
                            // value={String(item.katastasi)}
                            onChange={(e) => this.handleChangeGKKat(e)}
                          >
                            <MenuItem value="Νέα">Νέα</MenuItem>
                            <MenuItem value="Μεταβολή">Μεταβολή</MenuItem>
                            <MenuItem value="Ακύρωση">Ακύρωση</MenuItem>
                          </Select>
                        </>
                      )}
                    </Row>

                    {this.renderKatastasi()}
                  </CardBody>
                </Card>
                <br />
                <br />

                {/* <NavPills
                  color="success"
                  tabs={[
                    {
                      tabButton: "Profile",
                      tabContent: (
                        <span>
                          <p>
                            Collaboratively administrate empowered markets via
                            plug-and-play networks. Dynamically procrastinate
                            B2C users after installed base benefits.
                          </p>
                          <br />
                          <p>
                            Dramatically visualize customer directed convergence
                            without revolutionary ROI. Collaboratively
                            administrate empowered markets via plug-and-play
                            networks. Dynamically procrastinate B2C users after
                            installed base benefits.
                          </p>
                          <br />
                          <p>This is very nice.</p>
                        </span>
                      ),
                    },
                    {
                      tabButton: "Settings",
                      tabContent: (
                        <span>
                          <p>
                            Efficiently unleash cross-media information without
                            cross-media value. Quickly maximize timely
                            deliverables for real-time schemas.
                          </p>
                          <br />
                          <p>
                            Dramatically maintain clicks-and-mortar solutions
                            without functional solutions.
                          </p>
                        </span>
                      ),
                    },
                    {
                      tabButton: "Options",
                      tabContent: (
                        <span>
                          <p>
                            Completely synergize resource taxing relationships
                            via premier niche markets. Professionally cultivate
                            one-to-one customer service with robust ideas.{" "}
                          </p>
                          <br />
                          <p>
                            Dynamically innovate resource-leveling customer
                            service for state of the art customer service.
                          </p>
                        </span>
                      ),
                    },
                  ]}
                /> */}
                {/* <label> Οικονομικά Στοιχεία</label>
                <br />
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="enarxiE"
                    name="enarxiE"
                    showTodayButton
                    label="Έναρξη"
                    value={this.state.activeItemG.enarxiE}
                    onChange={(e) => this.handleChangeEnarxiE(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <br />
                <br />
                <br />
                
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="lixeE"
                    name="lixeE"
                    label="Λήξη"
                    value={this.state.activeItemG.lixiE}
                    onChange={(e) => this.handleChangeLixiE(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
                {/* <CustomInput
                  id="posoE"
                  inputProps={{
                    value: this.state.activeItemG.posoE,
                    placeholder: "Ποσό",
                    onChange: (e) => this.handleChangeG(e),
                    type: "text",
                  }}
                /> */}
                {/* </GridItem> */}
                {/* <GridItem xs={2}> */}
                {/* </Row> */}
                {/* <Row> */}

                {/* </GridItem>
                 */}
                {/* </Row> */}
              </GridItem>
              {/* </Row> */}
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseG()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostG()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
