import * as React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import Box from "@mui/material/Box";
// import Checkmarks from "./checkMarks";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ListView from "./listView";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import session from "../api/session";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Select from "@mui/material/Select";

// import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { CreateBusinessModal } from "./create-business-modal.jsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class BusinessCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responseData: [],
      page: 1,
      categoryBusiness: "",
      businessName: "",
      businessCategories: [],
      selectedCategory: "",
      open: false,
      searchField: "",
      eidiAdeias: [],
      lisenceOptions: false,
      selecteLicenseOption: "",
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    this.fetchAllBuisness();
    this.fetchBusinessCategories();
    this.fetchEidiAdeias();
  }

  fetchBusinessCategories() {
    session.get("api/EpixeirisiCategory/").then((response) => {
      this.setState({ businessCategories: response.data.map((e) => e.nameEC) });
    });
  }
  fetchAllBuisness() {
    session
      .get("api/business/")
      .then((resp) => {
        this.setState({ responseData: resp.data });
      })
      .catch((e) => {
        console.log(e.response.data);
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
  }
  fetchEidiAdeias() {
    session.get("api/eidos-adeias/all/").then((resp) => {
      this.setState({ eidiAdeias: resp.data });
    });
  }

  fetchBusiness(category, businessName, outOfDate, page) {
    const parse_out_of_date = outOfDate.id != undefined ? outOfDate.id : "";
    session
      .get(
        `api/business/?iconeE=${category}&business_name=${businessName}&out_of_date=${parse_out_of_date}&page=${page}`
      )
      .then((resp) => {
        this.setState({ responseData: resp.data });
      });
  }
  searchData() {
    this.setState({ page: 1 });

    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      this.state.selecteLicenseOption,
      1
    );
  }

  pageChange(_, p) {
    this.setState({ page: p });

    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      this.state.selecteLicenseOption,
      p
    );
  }

  handleChange(e) {
    this.setState({ selectedCategory: e.target.value });
    this.fetchBusiness(
      e.target.value,
      this.state.searchField,
      this.state.selecteLicenseOption,
      1
    );
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleCloseLicense() {
    this.setState({ lisenceOptions: false });
  }
  handleOpenmLicense() {
    this.setState({ lisenceOptions: true });
  }
  handleOpen() {
    this.setState({ open: true });
  }
  searchTextChange(e) {
    this.setState({ searchField: e.target.value });
  }
  handleChangeLisence(e) {
    this.setState({
      selecteLicenseOption: e.target.value,
    });
    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      e.target.value,
      1
    );
  }
  handleBusinessDeleteEnd1(e) {
    console.log("girisa sto index");
    this.fetchAllBuisness();
    this.fetchBusinessCategories();
    this.fetchEidiAdeias();
  }
  createBusinessEndModal(e) {
    setTimeout(() => {
      this.fetchAllBuisness();
      this.fetchBusinessCategories();
      this.fetchEidiAdeias();
    }, 2000);
  }
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>Πίνακας Επιχειρήσεων</h3>
        </Box>

        <Box display="flex" sx={{ backgroundColor: "white" }}>
          <Box
            sx={{ mx: "auto" }}
            display="flex"
            style={{ alignItems: "center" }}
          >
            {this.state.businessCategories &&
              this.state.businessCategories.length > 0 && (
                <FormControl sx={{ m: 1, width: 300 }} variant="standard">
                  <InputLabel id="demo-controlled-open-select-label">
                    Κατηγορίες
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    onOpen={() => this.handleOpen()}
                    value={this.state.selectedCategory}
                    onChange={(e) => this.handleChange(e)}
                  >
                    <MenuItem value="">
                      <em>-------------------</em>
                    </MenuItem>
                    {this.state.businessCategories.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            <FormControl sx={{ m: 1, width: 300 }} variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Άδειες που έληξαν
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                open={this.state.lisenceOptions}
                onClose={() => this.handleCloseLicense()}
                onOpen={() => this.handleOpenmLicense()}
                value={this.state.selecteLicenseOption}
                onChange={(e) => this.handleChangeLisence(e)}
              >
                <MenuItem value="">
                  <em>-------------------</em>
                </MenuItem>
                {this.state.eidiAdeias.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="filled-basic"
              label="Εισάγετε όνομα"
              variant="filled"
              value={this.state.searchField}
              onChange={(e) => this.searchTextChange(e)}
            />

            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                height: "78%",
                textTransform: "none",
                bgcolor: "#388e3c",
              }}
              onClick={() => this.searchData()}
            >
              <i className="fas fa-search"></i>Αναζήτηση
            </Button>
          </Box>

          <Box
            sx={{
              "& > :not(style)": { m: 1 },
              mx: "auto",
            }}
          >
            <CreateBusinessModal
              categories={this.state.businessCategories}
              createBusinessEnd={(e) => this.createBusinessEndModal(e)}
            ></CreateBusinessModal>
          </Box>
        </Box>
        <br></br>
        {this.state.responseData &&
          this.state.responseData.results &&
          this.state.responseData.results.length > 0 && (
            <ListView
              data={this.state.responseData.results}
              handleBusinessDeleteEnd1={(e) => this.handleBusinessDeleteEnd1(e)}
            ></ListView>
          )}
        {this.state.responseData &&
          this.state.responseData.results &&
          this.state.responseData.results.length == 0 && (
            <p>Δεν Βρέθηκαν Αποτελέσματα</p>
          )}

        <Stack spacing={2} direction={{ xs: "column", sm: "row" }} mt={5}>
          <Pagination
            color="primary"
            count={this.state.responseData.total_pages}
            page={this.state.page}
            onChange={(e, p) => this.pageChange(e, p)}
          />
        </Stack>

        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default BusinessCards;
