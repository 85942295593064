import React from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import Treefold from "react-treefold";
import "./styles.css";
import { familyTree } from "./data";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
import { isConstructorDeclaration } from "typescript";
import session from '../api/session'
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';

const useStyles = makeStyles(styles);

export default function Map() {
  React.useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  const classes = useStyles();

  const position = [51.505, -0.09];
  const polygon = [
    [51.515, -0.09],
    [51.52, -0.1],
    [51.52, -0.12],
  ];
  const purpleOptions = { color: "purple" };

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12}>{/* {renderItems()} */}</GridItem>
              <GridItem xs={12}>
                <MapPanel />
                <div>
                  {/* <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: '80vh', width: '200wh' }}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                  <Polygon pathOptions={purpleOptions} positions={polygon} />
                </MapContainer> */}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

const renderPerson = ({ name }, gender) => (
  <span className={gender}>
    {name}
    <br />
  </span>
);

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MarkerList: [],
      markers: [[51.505, -0.09]],
    };
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList() {
    // axios
      // .get("http://192.168.10.111:8000/api/OrganizationChart")
      session.get('api/OrganizationChart')
      .then((res) => this.setState({ MarkerList: res.data }))
      .catch((err) => console.log(err));
  }

  render() {
    const tmpItems = this.state.MarkerList;

    // for (var i = 0; i < tmpItems.length; i++) {
    //   const { markers } = this.state;
    //   var tmpr = [];

    //   var tmpstr = "";
    //   tmpstr = tmpItems[i].value1.split(",");
    //   var tmpstrlat = parseFloat(tmpstr[0]);
    //   var tmpstrlong = parseFloat(tmpstr[1]);
    //   tmpr.push(tmpstrlat);
    //   tmpr.push(tmpstrlong);
    //   markers.push(tmpr);
    // }

    // const { markers } = this.state;
    // // markers.push(e.latlng)
    // // this.setState({markers})
    // const position = [51.505, -0.09];

    console.log(tmpItems.chart);

    let chartitems = tmpItems.chart;

    console.log(chartitems);

    if (chartitems == undefined) {
      chartitems = [];
    }

    return (
      <div className="tree">
        <ul>
          <Treefold
            nodes={chartitems}
            render={({
              node,
              isFolder,
              isExpanded,
              getToggleProps,
              renderChildNodes,
            }) => (
              <li>
                <div
                  className={isFolder ? "non-leaf" : "leaf"}
                  {...(isFolder ? getToggleProps() : {})}
                >
                  {node.colorPappou && renderPerson(node.colorPappou, "pappou")}
                  {node.colorMpampa &&
                    renderPerson(node.colorMpampa, "mpampas")}
                  {node.colorChild && renderPerson(node.colorChild, "paidi")}

                  {/* {node && renderPerson(node)} */}
                  {node.colorPappou && node.colorMpampa && node.colorChild && (
                    <span className="spacer" />
                  )}
                </div>
                {isExpanded && <ul>{renderChildNodes()}</ul>}
              </li>
            )}
          />
        </ul>
      </div>
    );
  }
}
