import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import session from "../../api/session";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ParabaseisModal, MetabibaseisModal } from "./modal-create.jsx";
import {
  BusinessLisenceModal,
  UpdateBusinessLicenseFile,
  BusinessEdit,
} from "./modals.jsx";

class BusinessDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "1",
      detailData: {},

      businessParabaseis: [],
      businessTranfer: [],
      stadiaParabasis: [],
      eidosParabasis: [],
      currentUser: "",
      businessLicense: [],
      businessLicenseParameters: [],
      businessFilePage: [],
      businessCategories: [],
    };
  }
  handleChange = (_, newValue) => {
    this.setState({
      newValue,
    });
  };

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
    // console.log(this.props.match.params.id)
    session
      .get(`api/Epixeiriseis/${this.props.match.params.id}/`)
      .then((resp) => {
        this.setState({ detailData: resp.data });
      })
      .catch((e) => {
        console.log(e.response.data);
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });

    session.get("api/users/").then((resp) => {
      const localUser = localStorage.getItem("userselectedname");

      this.setState({
        currentUser: resp.data.find((e) => e.username === localUser).id,
      });
    });

    session
      .get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis/`)
      .then((resp) => {
        this.setState({ businessParabaseis: resp.data });
      });
    session
      .get(`api/business-transfer/${this.props.match.params.id}/transfer/`)
      .then((resp) => {
        this.setState({ businessTranfer: resp.data });
      });

    session.get("api/stadio-parabasis/all/").then((resp) => {
      this.setState({ stadiaParabasis: resp.data });
    });
    session.get("api/eidos-parabasis/all/").then((resp) => {
      this.setState({ eidosParabasis: resp.data });
    });
    session
      .get(`api/business-license/${this.props.match.params.id}/`)
      .then((resp) => {
        this.setState({ businessLicense: resp.data });
      });

    session.get("api/eidos-adeias/all/").then((resp) => {
      this.setState({ businessLicenseParameters: resp.data });
    });

    session.get("api/FileFieldFormEpixeirisi/").then((resp) => {
      this.setState({
        businessFilePage: resp.data.filter(
          (e) => e.epixeirisi === this.state.detailData.nameE
        ),
      });
    });
    session.get("api/EpixeirisiCategory/").then((response) => {
      this.setState({ businessCategories: response.data.map((e) => e.nameEC) });
    });
  }

  handleModalBuisnessEdit(e) {
    setTimeout(() => {
      session
        .get(`api/Epixeiriseis/${this.props.match.params.id}/`)
        .then((resp) => {
          this.setState({ detailData: resp.data });
        });
      session.get("api/FileFieldFormEpixeirisi/").then((resp) => {
        this.setState({
          businessFilePage: resp.data.filter(
            (e) => e.epixeirisi === this.state.detailData.nameE
          ),
        });
      });
    }, 2000);
  }

  handleModalLisenceClosed(e) {
    setTimeout(() => {
      session
        .get(`api/business-license/${this.props.match.params.id}/`)
        .then((resp) => {
          this.setState({ businessLicense: resp.data });
        });
    }, 2000);
  }

  handleModalTranferClosed(e) {
    console.log("ekleisa");
    setTimeout(() => {
      session
        .get(`api/business-transfer/${this.props.match.params.id}/transfer/`)
        .then((resp) => {
          this.setState({ businessTranfer: resp.data });
        });
    }, 2000);
  }

  handleModalParabasiClosed(e) {
    setTimeout(() => {
      session
        .get(
          `api/business-parabaseis/${this.props.match.params.id}/parabaseis/`
        )
        .then((resp) => {
          this.setState({ businessParabaseis: resp.data });
        });
    }, 2000);
  }
  parseTimer(dt) {
    if (dt != undefined) {
      const dtsplit = dt.split(":");

      if (dtsplit.length === 3) {
        return dtsplit.splice(0, 2).join(":");
      }
      return dt;
    }
  }

  myfunc(dt) {
    const [date, time] = dt.split(" ");
    return date.split("-").reverse().join("-"), time;
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>Καρτέλα επιχείρησης - {this.state.detailData.nameE}</h3>
        </Box>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            backgroundColor: "white",
          }}
        >
          <TabContext value={this.state.newValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TabList
                onChange={this.handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ΣΤΟΙΧΕΙΑ ΕΠΙΧΕΙΡΗΣΗΣ" value="1" />
                <Tab label="ΑΔΕΙΕΣ" value="2" />
                <Tab label="ΠΑΡΑΒΑΣΕΙΣ" value="3" />
                <Tab label="ΜΕΤΑΒΙΒΑΣΕΙΣ" value="4" />
                {/* <Tab label="ΑΙΤΗΣΕΙΣ" value="5" /> */}
              </TabList>
            </Box>
            {/* // TODO Make the tab views */}
            <TabPanel value="2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Εναρξη Αδειας</TableCell>
                      <TableCell align="right">Λήξη</TableCell>
                      <TableCell align="right">Είδος Άδειας</TableCell>
                      <TableCell align="right">Αρχείο</TableCell>
                      <TableCell align="right">Παρατηρήσεις</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.businessLicense &&
                      this.state.businessLicense.length > 0 &&
                      this.state.businessLicense.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {e.start_date.split("-").reverse().join("-")}
                          </TableCell>
                          <TableCell align="right">
                            {e.end_date
                              ? e.end_date.split("-").reverse().join("-")
                              : "-"}
                          </TableCell>
                          <TableCell align="right">
                            {e.license_parameter_name}
                          </TableCell>
                          <TableCell align="right">
                            {e.files.length > 0 &&
                              e.files.map((row, index) => (
                                <div key={index}>
                                  <a
                                    href={row.file}
                                    key={index}
                                    target="_blank"
                                  >
                                    {/* Αρχείο {index + 1} */}
                                    {decodeURI(row.file.split("/").pop())}
                                  </a>
                                  <UpdateBusinessLicenseFile
                                    file={row.file}
                                    fileId={row.id}
                                    businessID={this.state.detailData.id}
                                    handleBusinessFileEnd={(e) =>
                                      this.handleModalLisenceClosed(e)
                                    }
                                  ></UpdateBusinessLicenseFile>
                                </div>
                              ))}
                          </TableCell>

                          <TableCell align="right">{e.comments}</TableCell>
                          <TableCell align="right">
                            <BusinessLisenceModal
                              handleBusinessChange={(e) =>
                                this.handleModalLisenceClosed(e)
                              }
                              action="Update"
                              actionID={e.id}
                              businessID={this.state.detailData.id}
                              licenseParameters={
                                this.state.businessLicenseParameters
                              }
                              created_by={this.state.currentUser}
                              comment={e.comments}
                              start_date={e.start_date}
                              end_date={e.end_date}
                            ></BusinessLisenceModal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <BusinessLisenceModal
                handleBusinessChange={(e) => this.handleModalLisenceClosed(e)}
                action="Create"
                businessID={this.state.detailData.id}
                licenseParameters={this.state.businessLicenseParameters}
                created_by={this.state.currentUser}
              ></BusinessLisenceModal>
            </TabPanel>

            <TabPanel value="3">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ημερομηνία</TableCell>
                      {/* <TableCell align="right">Ώρα Δημιουργίας</TableCell> */}
                      <TableCell align="right">Είδος</TableCell>
                      <TableCell align="right">Στάδιο</TableCell>
                      <TableCell align="center">Ποινή</TableCell>
                      <TableCell align="right">Ανάκληση Άδειας</TableCell>
                      <TableCell align="right">Αρχείο</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.businessParabaseis.length > 0 &&
                      this.state.businessParabaseis.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.date.split("-").reverse().join("-")}
                          </TableCell>
                          {/* <TableCell align="right">
                            {this.myfunc(row.created_at)}
                          </TableCell> */}
                          <TableCell align="right">
                            {row.eidos_title != null ? row.eidos_title : "-"}
                          </TableCell>
                          <TableCell align="right">
                            {row.stadio_title != null ? row.stadio_title : "-"}
                          </TableCell>
                          <TableCell align="center">{row.comments}</TableCell>
                          <TableCell align="right">
                            {row.anaklisi_adeias ? "Ναι" : "Οχι"}
                          </TableCell>
                          <TableCell align="right">
                            {row.file != null ? (
                              <a href={row.file} target="_blank">
                                {decodeURI(row.file.split("/").pop())}
                              </a>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            <ParabaseisModal
                              handleChange={(e) =>
                                this.handleModalParabasiClosed()
                              }
                              action="Update"
                              date={row.date}
                              file={row.file}
                              comments={row.comments}
                              title="Επεξεργασία"
                              buttonTitle="Επεξεργασια"
                              id={row.id}
                              stadia={this.state.stadiaParabasis}
                              updateStadia={row.stadio}
                              eidos={this.state.eidosParabasis}
                              personID={this.state.detailData.id}
                              checkAnaklisi={row.anaklisi_adeias}
                              created_by={this.state.currentUser}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ParabaseisModal
                action="Create"
                personID={this.state.detailData.id}
                stadia={this.state.stadiaParabasis}
                eidos={this.state.eidosParabasis}
                created_by={this.state.currentUser}
                handleChange={(e) => this.handleModalParabasiClosed()}
              />
            </TabPanel>
            <TabPanel value="4">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Από</TableCell>
                      <TableCell align="center">Πρός</TableCell>
                      <TableCell align="right">Ημερομηνία</TableCell>
                      <TableCell align="center">Σχόλια</TableCell>
                      <TableCell align="right">Αρχείο</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.businessTranfer.length > 0 &&
                      this.state.businessTranfer.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.from_person}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.to_person}
                          </TableCell>
                          <TableCell align="right">
                            {row.date.split("-").reverse().join("-")}
                          </TableCell>
                          <TableCell align="center">{row.comments}</TableCell>
                          <TableCell align="right">
                            {row.file != null ? (
                              <a href={row.file} target="_blank">
                                {decodeURI(row.file.split("/").pop())}
                              </a>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            <MetabibaseisModal
                              handleChange={(e) =>
                                this.handleModalTranferClosed(e)
                              }
                              action="Update"
                              fromPerson={row.from_person}
                              toPerson={row.to_person}
                              date={row.date}
                              file={row.file}
                              comments={row.comments}
                              title="Επεξεργασία"
                              buttonTitle="Επεξεργασία"
                              id={row.id}
                              personID={this.state.detailData.id}
                              created_by={this.state.currentUser}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <MetabibaseisModal
                handleChange={(e) => this.handleModalTranferClosed(e)}
                action="Create"
                personID={this.state.detailData.id}
                fromPerson={this.state.detailData.userIdE}
                created_by={this.state.currentUser}
              />
            </TabPanel>
            <TabPanel value="5"></TabPanel>
            <TabPanel value="1">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ιδιοκτήτης</TableCell>
                      <TableCell align="center">ΑΦΜ</TableCell>
                      <TableCell align="right">Είδος Επιχείρησης</TableCell>
                      <TableCell align="right">Διεύθυνση</TableCell>
                      <TableCell align="center">Ωράριο</TableCell>
                      <TableCell align="right">Site</TableCell>
                      <TableCell align="center">Παρατηρήσεις</TableCell>
                      <TableCell align="center">Αρχείο</TableCell>
                      <TableCell align="center">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {this.state.detailData.userIdE}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.afmE}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.iconeE}
                      </TableCell>
                      <TableCell align="right">
                        {this.state.detailData.dieuthinsi}
                      </TableCell>
                      <TableCell align="center">
                        {this.parseTimer(this.state.detailData.wrarioE)} -{" "}
                        {this.parseTimer(this.state.detailData.wrarioL)}
                      </TableCell>

                      <TableCell align="center">
                        {this.state.detailData.siteE}
                      </TableCell>

                      <TableCell align="center">
                        {this.state.detailData.paratiriseis}
                      </TableCell>
                      {this.state.businessFilePage &&
                      this.state.businessFilePage.length > 0 ? (
                        this.state.businessFilePage.map((s) => (
                          <TableCell align="center" key={s.id}>
                            <a href={s.file_field} target="_blank">
                              {decodeURI(s.file_field.split("/").pop())}
                            </a>
                          </TableCell>
                        ))
                      ) : (
                        <TableCell align="center">-</TableCell>
                      )}
                      <TableCell>
                        {this.state.detailData &&
                          this.state.detailData.nameE && (
                            <BusinessEdit
                              handleChangeUpdate={(e) =>
                                this.handleModalBuisnessEdit(e)
                              }
                              bussinessId={this.props.match.params.id}
                              bussinessName={this.state.detailData.nameE}
                              owner={this.state.detailData.userIdE}
                              afm={this.state.detailData.afmE}
                              address={this.state.detailData.dieuthinsi}
                              notes={this.state.detailData.paratiriseis}
                              categories={this.state.businessCategories}
                              site={this.state.detailData.siteE}
                              startTime={this.state.detailData.wrarioE}
                              endTime={this.state.detailData.wrarioL}
                              file={this.state.businessFilePage}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box>

        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default BusinessDetails;
