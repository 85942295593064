/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Apps, CloudDownload, RssFeed, Person } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import serreslogo from "assets/img/serresgr-logo-300x77 (1).png";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import ContactsIcon from "@mui/icons-material/Contacts";
import {
  Row,
  // UncontrolledTooltip,
} from "reactstrap";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  function logout() {
    localStorage.removeItem("token");
    window.location.reload(false);
    window.location.href = "../";
  }

  const classes = useStyles();

  return (
    <List className={classes.list}>
      <div style={{ textAlign: "end" }}>
        <Row>
          {" "}
          <img src={serreslogo} style={{ width: "150px" }} alt="..." />
        </Row>
        <Row>
          {" "}
          <i className="fa fa-phone"></i> <i className="fa fa-fax"></i>
        </Row>

        <Row>
          <ListItem className={classes.listItem}>
            <ListItem className={classes.listItem}>
              <Button color="transparent" className={classes.navLink} href="/">
                {" "}
                <i className="fa fa-home"></i>
                &nbsp; Αρχική
              </Button>
            </ListItem>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              href="/MapLA"
              onClick={() => {
                window.location.href = "/MapLA";
              }}
            >
              <i className="fa fa-cart-plus">{"   "}</i>&nbsp; ΧΑΡΤΗΣ ΛΑΙΚΩΝ
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              href="/MapE"
              onClick={() => {
                window.location.href = "/MapE";
              }}
            >
              {" "}
              <i className="fa fa-building"></i>
              &nbsp; ΧΑΡΤΗΣ ΕΠΙΧΕΙΡΗΣΕΩΝ
            </Button>{" "}
          </ListItem>
          <ListItem className={classes.listItem}>
            <CustomDropdown
              buttonText="ΑΝΑΚΟΙΝΩΣΕΙΣ/ΝΕΑ"
              buttonProps={{
                className: classes.navLink,
                color: "transparent",
              }}
              buttonIcon={RssFeed}
              dropdownList={[
                <Link
                  to="/Post-Covid"
                  onClick={() => {
                    window.location.href = "/Post-Covid";
                  }}
                  className={classes.dropdownLink}
                >
                  Covid
                </Link>,
              ]}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              startIcon={<ContactsIcon />}
              color="transparent"
              className={classes.navLink}
              onClick={() => {
                window.location.href = "/contact";
              }}
            >
              Επικοινωνια
            </Button>
            {/* <CustomDropdown
              buttonText="Επικοινωνία"
              buttonProps={{
                className: classes.navLink,
                color: "transparent",
              }}
              buttonIcon={Apps}
              dropdownList={[
                <Link
                  onClick={() => {
                    window.location.href = "/EpikoinwniaStegasmeno";
                  }}
                  to="/EpikoinwniaStegasmeno"
                  className={classes.dropdownLink}
                >
                  Στεγασμένο Εμπόριο
                </Link>,
                <Link
                  onClick={() => {
                    window.location.href = "/EpikoinwniaYpethrio";
                  }}
                  to="/EpikoinwniaYpethrio"
                  className={classes.dropdownLink}
                >
                  Υπαίθριο Εμπόριο
                </Link>,
              ]}
            /> */}
          </ListItem>
        </Row>

        <Row style={{ width: "800px" }}>
          {localStorage.getItem("token") && (
            <>
              {localStorage.getItem("isSuper") == "true" && (
                <ListItem className={classes.listItem}>
                  <CustomDropdown
                    buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                    buttonProps={{
                      className: classes.navLink,
                      color: "transparent",
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                      <Link
                        to="/Post-admin"
                        onClick={() => {
                          window.location.href = "/Post-admin";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΑΝΑΚΟΙΝΩΣΕΩΝ/ΝΕΩΝ
                      </Link>,
                      // <Link
                      //   to="/MapE-Admin"
                      //   onClick={() => {
                      //     window.location.href = "/MapE-Admin";
                      //   }}
                      //   className={classes.dropdownLink}
                      // >
                      //   ΕΠΙΧΕΙΡΗΣΕΩΝ
                      // </Link>,
                      <Link
                        to="/MapLa-Admin"
                        onClick={() => {
                          window.location.href = "/MapLa-Admin";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΛΑΙΚΩΝ
                      </Link>,
                      // <Link
                      //   to="/MapLa-Parousia"
                      //   onClick={() => {
                      //     window.location.href = "/MapLa-Parousia";
                      //   }}
                      //   className={classes.dropdownLink}
                      // >
                      //   ΠΑΡΟΥΣΙΩΝ
                      // </Link>,
                      <Link
                        // to={{ pathname: "/supervisors" }}
                        to="/supervisors"
                        onClick={() => {
                          window.location.href = "/supervisors";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΕΠΟΠΤΕΣ
                      </Link>,
                      <Link
                        // to={{ pathname: "/business-cards" }}
                        to="/business-cards"
                        onClick={() => {
                          window.location.href = "/business-cards";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΕΠΙΧΕΙΡΗΣΕΩΝ
                      </Link>,

                      <Link
                        // to={{ pathname: "/parametric-infringements" }}
                        to="/parametric-infringements"
                        onClick={() => {
                          window.location.href = "/parametric-infringements";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΠΑΡΑΜΕΤΡΙΚΑ
                      </Link>,
                      <Link
                        to="/syndromes"
                        onClick={() => {
                          window.location.href = "/syndromes";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΑΔΕΙΩΝ ΠΑΓΚΩΝ
                      </Link>,
                      <Link
                        to="/contact-management"
                        onClick={() => {
                          window.location.href = "/contact-management";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΕΠΙΚΟΙΝΩΝΙΩΝ
                      </Link>,
                    ]}
                  />
                </ListItem>
              )}
              {localStorage.getItem("isSuper") == "true" && (
                <ListItem className={classes.listItem}>
                  <CustomDropdown
                    buttonText="SUPERUSER"
                    buttonProps={{
                      className: classes.navLink,
                      color: "transparent",
                    }}
                    buttonIcon={Person}
                    dropdownList={[
                      <Link
                        to="/laikes-admin"
                        onClick={() => {
                          window.location.href = "/laikes-admin";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΛΑΙΚΕΣ
                      </Link>,
                      // <Link
                      //   to="/pagkoi-admin"
                      //   onClick={() => {
                      //     window.location.href = "/pagkoi-admin";
                      //   }}
                      //   className={classes.dropdownLink}
                      // >
                      //   ΠΑΓΚΟΙ
                      // </Link>,
                      <Link
                        to="/epixeiriseis-admin"
                        onClick={() => {
                          window.location.href = "/epixeiriseis-admin";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΕΠΙΧΕΙΡΗΣΕΙΣ
                      </Link>,
                      <Link
                        to="/genika-admin"
                        onClick={() => {
                          window.location.href = "/genika-admin";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΓΕΝΙΚΑ
                      </Link>,
                    ]}
                  />
                </ListItem>
              )}
              {localStorage.getItem("isSuper") == "false" &&
                String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/Post-admin"
                          onClick={() => {
                            window.location.href = "/Post-admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΑΝΑΚΟΙΝΩΣΕΩΝ/ΝΕΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}

              {localStorage.getItem("isSuper") == "false" &&
                String(localStorage.getItem("usergroups")).includes(
                  "Admin"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/Post-admin"
                          onClick={() => {
                            window.location.href = "/Post-admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΑΝΑΚΟΙΝΩΣΕΩΝ/ΝΕΩΝ
                        </Link>,
                        // <Link
                        //   to="/MapE-Admin"
                        //   onClick={() => {
                        //     window.location.href = "/MapE-Admin";
                        //   }}
                        //   className={classes.dropdownLink}
                        // >
                        //   ΕΠΙΧΕΙΡΗΣΕΩΝ
                        // </Link>,
                        <Link
                          to="/MapLa-Admin"
                          onClick={() => {
                            window.location.href = "/MapLa-Admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΛΑΙΚΩΝ
                        </Link>,
                        // <Link
                        //   to="/MapLa-Parousia"
                        //   onClick={() => {
                        //     window.location.href = "/MapLa-Parousia";
                        //   }}
                        //   className={classes.dropdownLink}
                        // >
                        //   ΠΑΡΟΥΣΙΩΝ
                        // </Link>,
                        <Link
                          // to={{ pathname: "/business-cards" }}
                          to="/business-cards"
                          onClick={() => {
                            window.location.href = "/business-cards";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΕΠΙΧΕΙΡΗΣΕΩΝ
                        </Link>,

                        <Link
                          // to={{ pathname: "/parametric-infringements" }}
                          to="/parametric-infringements"
                          onClick={() => {
                            window.location.href = "/parametric-infringements";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΠΑΡΑΜΕΤΡΙΚΑ ΠΑΡΑΒΑΣΕΩΝ
                        </Link>,
                        <Link
                          to="/syndromes"
                          onClick={() => {
                            window.location.href = "/syndromes";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΑΔΕΙΩΝ ΠΑΓΚΩΝ
                        </Link>,
                        <Link
                          to="/contact-management"
                          onClick={() => {
                            window.location.href = "/contact-management";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΕΠΙΚΟΙΝΩΝΙΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}
              {localStorage.getItem("isSuper") == "false" &&
                String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to={{ pathname: "/supervisors" }}
                          // to="/supervisors"
                          // onClick={() => {
                          //   window.location.href = "/supervisors";
                          // }}
                          className={classes.dropdownLink}
                        >
                          ΕΠΟΠΤΕΣ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}
              {/* {localStorage.getItem("isSuper") == "false" &&
                String(localStorage.getItem("usergroups")).includes("Admin") &&
                String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) &&
                !String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/MapE-Admin"
                          onClick={() => {
                            window.location.href = "/MapE-Admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΕΠΙΧΕΙΡΗΣΕΩΝ
                        </Link>,
                        <Link
                          to="/MapLa-Admin"
                          onClick={() => {
                            window.location.href = "/MapLa-Admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΛΑΙΚΩΝ
                        </Link>,
                        <Link
                          to="/MapLa-Parousia"
                          onClick={() => {
                            window.location.href = "/MapLa-Parousia";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΠΑΡΟΥΣΙΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}
              {localStorage.getItem("isSuper") == "false" &&
                String(localStorage.getItem("usergroups")).includes("Admin") &&
                !String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) &&
                String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/Post-admin"
                          onClick={() => {
                            window.location.href = "/Post-admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΑΝΑΚΟΙΝΩΣΕΩΝ/ΝΕΩΝ
                        </Link>,
                        <Link
                          to="/MapE-Admin"
                          onClick={() => {
                            window.location.href = "/MapE-Admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΕΠΙΧΕΙΡΗΣΕΩΝ
                        </Link>,
                        <Link
                          to="/MapLa-Admin"
                          onClick={() => {
                            window.location.href = "/MapLa-Admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΛΑΙΚΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}
              {localStorage.getItem("isSuper") == "false" &&
                !String(localStorage.getItem("usergroups")).includes("Admin") &&
                String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) &&
                String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/Post-admin"
                          onClick={() => {
                            window.location.href = "/Post-admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΑΝΑΚΟΙΝΩΣΕΩΝ/ΝΕΩΝ
                        </Link>,

                        <Link
                          to="/MapLa-Parousia"
                          onClick={() => {
                            window.location.href = "/MapLa-Parousia";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΠΑΡΟΥΣΙΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}
              {localStorage.getItem("isSuper") == "false" &&
                !String(localStorage.getItem("usergroups")).includes("Admin") &&
                String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) &&
                !String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/MapLa-Parousia"
                          onClick={() => {
                            window.location.href = "/MapLa-Parousia";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΠΑΡΟΥΣΙΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}
              {localStorage.getItem("isSuper") == "false" &&
                !String(localStorage.getItem("usergroups")).includes("Admin") &&
                !String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) &&
                String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΔΙΑΧΕΙΡΙΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/Post-admin"
                          onClick={() => {
                            window.location.href = "/Post-admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΑΝΑΚΟΙΝΩΣΕΩΝ/ΝΕΩΝ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )}

              {localStorage.getItem("isSuper") == "false" &&
                !String(localStorage.getItem("usergroups")).includes(
                  "Ελεγκτής"
                ) &&
                !String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <Button
                      color="transparent"
                      className={classes.navLink}
                      href="/Gnwstopoihseis"
                      onClick={() => {
                        window.location.href = "/Gnwstopoihseis";
                      }}
                    >
                      {" "}
                      <i className="fa fa-share"></i>&nbsp; ΓΝΩΣΤΟΠΟΙΗΣΕΙΣ
                    </Button>
                  </ListItem>
                )} */}

              {/* {localStorage.getItem("isSuper") == "true" && (
                <ListItem className={classes.listItem}>
                  <Button
                    color="transparent"
                    className={classes.navLink}
                    href="/SuperuserAdmin"
                    onClick={() => {
                      window.location.href = "/SuperuserAdmin";
                    }}
                  >
                    {" "}
                    <i className="fa fa-user"></i>&nbsp; SUPERUSER
                  </Button>
                </ListItem>
              )} */}
              {/* {localStorage.getItem("isSuper") == "false" &&
                !String(localStorage.getItem("usergroups")).includes(
                  "ανακοινώσεων"
                ) && (
                  <ListItem className={classes.listItem}>
                    <CustomDropdown
                      buttonText="ΑΝΑΣΚΟΠΗΣΗ"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      buttonIcon={Apps}
                      dropdownList={[
                        <Link
                          to="/Admin"
                          onClick={() => {
                            window.location.href = "/Admin";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΕΚΤΥΠΩΣΕΙΣ
                        </Link>,
                        <Link
                          to="/Stats"
                          onClick={() => {
                            window.location.href = "/Stats";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΣΤΑΤΙΣΤΙΚΑ
                        </Link>,
                        <Link
                          to="/Xrewseis"
                          onClick={() => {
                            window.location.href = "/Xrewseis";
                          }}
                          className={classes.dropdownLink}
                        >
                          ΧΡΕΩΣΕΙΣ
                        </Link>,
                      ]}
                    />
                  </ListItem>
                )} */}

              {localStorage.getItem("isSuper") == "true" && (
                <ListItem className={classes.listItem}>
                  <CustomDropdown
                    buttonText="ΑΝΑΣΚΟΠΗΣΗ"
                    buttonProps={{
                      className: classes.navLink,
                      color: "transparent",
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                      <Link
                        to="/Admin"
                        onClick={() => {
                          window.location.href = "/Admin";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΕΚΤΥΠΩΣΕΙΣ
                      </Link>,
                      <Link
                        to="/Stats"
                        onClick={() => {
                          window.location.href = "/Stats";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΣΤΑΤΙΣΤΙΚΑ
                      </Link>,
                      <Link
                        to="/Xrewseis"
                        onClick={() => {
                          window.location.href = "/Xrewseis";
                        }}
                        className={classes.dropdownLink}
                      >
                        ΧΡΕΩΣΕΙΣ
                      </Link>,
                    ]}
                  />
                </ListItem>
              )}
              <ListItem className={classes.listItem}>
                <Button
                  color="transparent"
                  className={classes.navLink}
                  href="/profile-page"
                  onClick={() => {
                    window.location.href = "/profile-page";
                  }}
                >
                  {" "}
                  <i className="fa fa-user-circle"></i>&nbsp; ΠΡΟΦΙΛ
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  color="transparent"
                  className={classes.navLink}
                  onClick={() => logout()}
                >
                  <i className="fa fa-power-off"></i>&nbsp; ΑΠΟΣΥΝΔΕΣΗ
                </Button>
              </ListItem>
            </>
          )}
        </Row>
      </div>
    </List>
  );
}
