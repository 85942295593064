import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import session from "../api/session";
import BasicModal from "./modal.jsx";


export default class CollapsibleTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            businesses: [],
            page: 1,

        };
    }

    componentDidMount() {

        this.fetchBusiness(1)

    }


    fetchBusiness(page) {
        let date = new Date().toLocaleDateString().split('/').reverse().join('-')
        session.get(`api/business/?liksi=${date}&page=${page}`).then(res => {
            this.setState({ businesses: res.data })
        })
    }


    pageClick(_, v) {
        console.log(v);
        this.setState({ page: v });
        console.log(this.state.page);
        this.fetchBusiness(v)
    }




    render() {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Επωνυμία</TableCell>
                                <TableCell align="center">Ονοματεπώνυμο</TableCell>
                                <TableCell align="center">Κατηγορία</TableCell>
                                <TableCell align="center">Ενέργειες</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.businesses &&
                                this.state.businesses.results &&
                                this.state.businesses.results.length > 0 &&
                                this.state.businesses.results.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.nameE}
                                        </TableCell>
                                        <TableCell align="center">{row.userIdE}</TableCell>
                                        <TableCell align="center">{row.iconeE != null ? row.iconeE : '-'}</TableCell>
                                        <TableCell align="center">
                                            <BasicModal name={row.id}></BasicModal>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>



                <Stack spacing={2} direction={{ xs: "column", sm: "row" }} mt={5}>
                    <Pagination
                        color="primary"
                        count={this.state.businesses.total_pages}
                        page={this.state.page}
                        onChange={(e, v) => this.pageClick(e, v)}
                    />
                </Stack>
            </div>
        );
    }
}
