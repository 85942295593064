import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  Polygon,
  useMapEvents,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
import { isConstructorDeclaration } from "typescript";
import {
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';
import ReactTable from "components/ReactTable/ReactTable.js";
import session from '../api/session'
import { SettingsBrightnessOutlined } from "@material-ui/icons";

let UdataTable = [];
const useStyles = makeStyles(styles);
let center = [39.365, 22.951];
let zoom = 14;

// function MyComponent() {
//   const map = useMapEvents({
//     click: () => {
//       map.locate();
//     },
//     locationfound: (location) => {
//       console.log("location found:", location);
//     },
//   });
//   return null;
// }

// function LocationMarker() {
//   const [position, setPosition] = useState(null);
//   const map = useMapEvents({
//     click() {
//       map.locate();
//     },
//     locationfound(e) {
//       setPosition(e.latlng);
//       map.flyTo(e.latlng, map.getZoom());
//     },
//   });

//   return position === null ? null : ();

//     <Marker position={position}>
//       <Popup>You are here</Popup>
//     </Marker>
//   );
// }

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default function Map() {
  React.useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  const classes = useStyles();

  // const position = [51.505, -0.09];
  // const polygon = [
  //   [51.515, -0.09],
  //   [51.52, -0.1],
  //   [51.52, -0.12],
  // ];
  // const purpleOptions = { color: "purple" };

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
       <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <MapPanel />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EpixeiriseisList: [],
      markers: [[0, 0]],
      map: null,
      epixirisiselected: "",
      legend: "",
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListCategory();
  }

  handleEpixirisiSelect(e, latlng) {
    const value = e.target.value;

    this.state.epixirisiselected = value;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 20);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  refreshList() {
    // const api_url = 'http://127.0.0.1:8000/api/'
    // const epixirin_api = 'https://epixirin-volos.govcloud.gr/be/api/'
    // axios
    //   .get(`${api_url}Epixeiriseis/`)  
    session.get('api/Epixeiriseis/')
      .then((res) => this.setState({ EpixeiriseisList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListCategory() {
    // const api_url = 'http://127.0.0.1:8000/api/'
    const epixirin_api = 'https://epixirin-volos.govcloud.gr/be/api/'
    // axios
    //   .get(`${api_url}EpixeirisiCategory/`)
      session.get('api/EpixeirisiCategory/')
      .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
      .catch((err) => console.log(err));
  }

  render() {
    const tmpItems = this.state.EpixeiriseisList;
    const { markers } = this.state;
    let categories = this.state.EpixeiriseisCategoryList;

    if (categories != undefined) {
      for (var i = 0; i < tmpItems.length; i++) {
        console.log(tmpItems[0].iconeE);
        console.log(categories);
        let categorytmp = categories.filter(
          (item) => String(item.nameEC) === String(tmpItems[i].iconeE)
        );

        if (categorytmp[0] != undefined) {
          console.log(categorytmp);

          tmpItems[i].logo = categorytmp[0].logo;

          const myIcon = L.icon({
            iconUrl: categorytmp[0].logo,
            iconSize: [25, 25],
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
          });

          tmpItems[i].logo2 = myIcon;

          // const iconPerson = new L.Icon({
          //   iconUrl: require(categorytmp[0].logo),
          //   iconRetinaUrl: require(categorytmp[0].logo),
          //   iconAnchor: null,
          //   popupAnchor: null,
          //   shadowUrl: null,
          //   shadowSize: null,
          //   shadowAnchor: null,
          //   iconSize: new L.Point(60, 75),
          //   className: "leaflet-div-icon",
          // });

          // tmpItems[i].logo = iconPerson;
        }
      }
    } else {
      categories = [];
      // this.setState({ state: this.state });
    }

    UdataTable = [];

    for (var i = 0; i < tmpItems.length; i++) {
      const dataRow = {};
      const coords = tmpItems[i].coordsE;
      const name = tmpItems[i].nameE;
      const logo = tmpItems[i].logo;

      dataRow.name = name;
      dataRow.selectLa = (
        <Radio
          checked={String(this.state.epixirisiselected) === String(name)}
          onChange={(e) => this.handleEpixirisiSelect(e, coords)}
          value={name}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.media = <img style={{ width: "25px" }} src={logo} />;
      dataRow.eidos = tmpItems[i].iconeE;
      dataRow.wrario = tmpItems[i].wrarioE + "-" + tmpItems[i].wrarioL;

      UdataTable.push(dataRow);
    }

    var legend2 = L.control({ position: "topright" });

    // legend2.onAdd = function (map) {
    //   var div = L.DomUtil.create("div", "legend");
    //   div.innerHTML += "<h4>Κατηγορίες επιχειρήσεων</h4>";

    //   for (var i = 0; i < categories.length; i++) {
    //     div.innerHTML +=
    //       '<img style=" width: 25px " src="' +
    //       categories[i].logo +
    //       '">  ' +
    //       categories[i].nameEC +
    //       " </img><br>";
    //   }

    //   return div;
    // };

    // if (this.state.map && this.state.legend == "") {
    //   legend2.addTo(this.state.map);
    //   this.state.legend = "-";
    // }

    // for (var i = 0; i < tmpItems.length; i++) {
    //   var tmpr = [];

    //   var tmpstr = "";
    //   tmpstr = tmpItems[i].coordsE.split(",");
    //   var tmpstrlat = parseFloat(tmpstr[0]);
    //   var tmpstrlong = parseFloat(tmpstr[1]);
    //   tmpr.push(tmpstrlat);
    //   tmpr.push(tmpstrlong);
    //   // markers.push(tmpr);

    //   tmpItems.coordsE = tmpr;
    // }

    // const { markers } = this.state;
    // markers.push(e.latlng)
    // this.setState({markers})
    const positionC = [51.505, -0.09];

    console.log(tmpItems);

    return (
      <GridContainer>
        <GridItem xs={10} sm={12} md={12} lg={6}>
          {/* <Row> */}

          <Card >
            <CardHeader color="success">Χάρτης</CardHeader>
            <CardBody>
              {categories.length == 0 && (
                <MapContainer
                  center={center}
                  zoom={zoom}
                  scrollWheelZoom={false}
                  style={{ height: "80vh", width: "200wh" }}
                >
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Street View">
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Earth view">
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                        attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                      />
                    </LayersControl.BaseLayer>
                  </LayersControl>
                </MapContainer>
              )}
              {categories.length != 0 && (
                <MapContainer
                  center={center}
                  zoom={zoom}
                  scrollWheelZoom={false}
                  style={{ height: "70vh", width: "200wh" }}
                  whenCreated={(map) => this.setState({ map })}
                >
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Street View">
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Earth view">
                      <TileLayer
                        maxNativeZoom={17}
                        minZoom={0}
                        maxZoom={17}
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                        attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                      />
                    </LayersControl.BaseLayer>
                  </LayersControl>
                  {/* <MyComponent /> */}
                  {/* <LocationMarker /> */}

                  {tmpItems.map(({ _id, nameE, coordsE, logo, iconeE }) => (
                    <Marker
                      key={`marker-${_id}`}
                      position={coordsE}
                      // icon={item.logo}
                      icon={L.icon({
                        iconUrl: logo,
                        iconSize: [25, 35],
                        popupAnchor: null,
                        shadowUrl: null,
                        shadowSize: null,
                        shadowAnchor: null,
                      })}
                    >
                      <Tooltip>
                        {nameE}
                        <br></br>
                        {iconeE}
                      </Tooltip>
                    </Marker>
                  ))}
                </MapContainer>
              )}
              {/* </Col> */}
            </CardBody>
          </Card>
          {/* </Row> */}
        </GridItem>
        <GridItem xs={10} sm={12} md={12} lg={6}>
          <Card>
            <CardHeader color="success">Επιχειρήσεις</CardHeader>
            <CardBody>
              <ReactTable
                data={UdataTable}
                filterable
                resizable={false}
                columns={[
                  {
                    Header: " ",
                    accessor: "selectLa",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                  {
                    Header: "Επωνυμία",
                    accessor: "name",
                  },
                  {
                    Header: " ",
                    accessor: "media",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                  {
                    Header: "Κατηγορία",
                    accessor: "eidos",
                    Filter: SelectColumnFilter,
                    filter: "includes",
                  },
                  // {
                  //   Header: "Περιοχή",
                  //   accessor: "wrario",
                  // },
                  {
                    Header: "",
                    accessor: "e",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                  // {
                  //   Header: " ",
                  //   accessor: "",
                  // },
                ]}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />

              {/* <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Επωνυμία</TableCell>
                      <TableCell>Κατηγορία</TableCell>
                      <TableCell>Ωράριο</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tmpItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          <Radio
                            checked={
                              String(this.state.epixirisiselected) ===
                              String(item.nameE)
                            }
                            onChange={(e) =>
                              this.handleEpixirisiSelect(e, item.coordsE)
                            }
                            value={item.nameE}
                            name="radio-button-demo"
                          />
                          {item.nameE}
                        </TableCell>

                        <TableCell>
                          <img style={{ width: "25px" }} src={item.logo} />{" "}
                          {item.iconeE}
                        </TableCell>
                        <TableCell>
                          {item.wrarioE}
                          {"-"} {item.wrarioL}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </CardBody>
          </Card>

          {/* {"Επιχειρήσεις"}
          <Table dark>
            <thead>
              <tr>
                <th>First Name</th>
              </tr>
            </thead>
            <tbody>
              {tmpItems.map((item) => (
                <tr>{item.nameE}</tr>
              ))}
            </tbody>
          </Table>

          {"Κατηγορίες"}
          <Table dark>
            <thead>
              <tr>
                <th>First Name</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((item) => (
                <div>
                  <img style={{ width: "25px" }} src={item.logo} />{" "}
                  {item.nameEC}
                </div>
              ))}
            </tbody>
          </Table> */}
        </GridItem>
      </GridContainer>
    );
  }
}
