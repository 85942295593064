import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMapEvents,
  FeatureGroup,
  Tooltip,
  // Circle,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
// import { isConstructorDeclaration } from "typescript";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Date from "react-datetime";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelect from "react-multi-select-component";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import NavPills from "components/NavPills/NavPills.js";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import Input from "@material-ui/core/Input";

import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// core components
import userParser from "components/userGroups.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import {
  CardFooter,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  // Table,
  Container,
  Row,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  Modal,
  ModalHeader,
  // Button,
  ModalBody,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import { number } from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import Geocode from "react-geocode";
import lastDayOfISOWeek from "date-fns/fp/lastDayOfISOWeek/index";
import session from "../api/session";

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

Geocode.setApiKey("AIzaSyBSF6RaFqZGzFJgPZv1drw7PEjYZ8qZd_0");
Geocode.setLanguage("el");
Geocode.setRegion("gr");
let UdataTablePocat = [];
let UdataTablePids = [];
let UdataTablePo = [];

let UdataTableU = [];
let grouptable = [];

let UdataTablePoC = [];
let UdataTableEC = [];

let UdataTableL = [];
let UdataTableP = [];
let UdataTablePX = [];
let UdataTableE = [];
let UdataTableXr = [];

async function setDoc(item) {
  var url = "http://127.0.0.1:8000/api/docuploadPagkos";
  // console.log(item);

  let formData = new FormData();
  formData.append("pagkos", item.pagkos);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPagkos", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
}

async function setPostDoc(item) {
  var url = "http://127.0.0.1:8000/api/docuploadPost";
  // console.log(item);

  let formData = new FormData();
  formData.append("postId", item.postId);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPost", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
}

async function setlogo(item) {
  var url = "http://127.0.0.1:8000/api/logoupload";

  // // console.log(formData);
  // console.log(item);
  // console.log(item.logo);
  // console.log(item.nameEC);

  let formData = new FormData();

  formData.append("logo", item.logo);
  formData.append("nameEC", item.nameEC);
  formData.append("iconEC", "-");
  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/logoupload", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
  // // console.log(response.data.logo);
  // var logoname = response.data.logo;
  // window.location.reload();
}
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';

// styles = {
//   label: {
//     cursor: "pointer",
//     paddingLeft: "0",
//     color: "rgba(0, 0, 0, 0.26)",
//     fontSize: "14px",
//     lineHeight: "1.428571429",
//     fontWeight: "400",
//     display: "inline-flex",
//   },
// };

async function setDocE(item) {
  var url = "http://127.0.0.1:8000/api/docuploadEpixirisi";
  // console.log(item);

  let formData = new FormData();
  formData.append("epixeirisi", item.epixeirisi);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadEpixirisi", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let posseleted = [];
let adressel = "";
let newE = false;
let editE = false;
let center = [39.365, 22.951];
let zoom = 14;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function MyComponent() {
//   const map = useMapEvents({
//     click: () => {
//       map.locate();
//     },
//     locationfound: (location) => {
//       // console.log("location found:", location);
//     },
//   });
//   return null;
// }

function LocationMarker() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // console.log(address);
          adressel = address;
        },
        (error) => {
          console.error(error);
        }
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : <></>;
  return position === null ? null : (
    <Marker position={position}>
      {" "}
      <Tooltip>{adressel}</Tooltip>{" "}
    </Marker>
  );
}

function LocationMarkerLaiki() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

// function handleChange(e) {
//   // console.log(e.target);
//   // console.log(e.target.value);
//   // console.log(e.target.id);

// const { name, value } = e.target;
// this.state.newItem = { ...this.state.newItem, [name]:  value };

// // console.log(this.state.newItem);
// }

export default function SuperuserAdmin() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  let newItem = React.useState();
  let newItemC = React.useState();

  React.useEffect(() => {
    // console.log("e");
    // console.log(editE);
  }, [editE]);

  React.useEffect(() => {
    // // console.log("e");
    // // console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <NavPills
              color="success"
              size="sm"
              tabs={[
                {
                  tabButton: "Λαϊκές - Πάγκοι - Επιχειρήσεις",
                  tabContent: <MapPanel></MapPanel>,
                },
                {
                  tabButton: "Κατηγορίες",
                  tabContent: <CategoryPanel></CategoryPanel>,
                },
                {
                  tabButton: "Χρήστες",
                  tabContent: <UsersPanel></UsersPanel>,
                },
                {
                  tabButton: "Posts",
                  tabContent: <PostPanel></PostPanel>,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      LaikesList: [],
      docsList: [],
      PagkoiXrewsiList: [],
      LaikesSelectList: [],
      markers: [[0, 0]],
      activeItem: {},
      activeItemXrewsi: {},
      activeItemLaiki: {},
      modalP: false,
      modalPX: false,
      modalL: false,
      laikiselected: "-",
      pagkosselected: "",
      laikiselectedcenter: "",
      EpixeiriseisList: [],
      EpixeiriseisCategoryList: [],
      activeItemEC: {},
      activeItemE: {},
      modalE: false,
      modalEC: false,
      arxdocitem: {},
      selectedDocs: [],
      arxdocitemE: {},
      selectedDocsE: [],
      ElegxoiList: [],
      ParanomoiList: [],
      XrewseisList: [],
      xrewsiepagk: "",
      xrewsiparag: "",
      ParousiesList: [],
      XrewseisList: [],
      modalXR: false,
      activeItemPagkoiXrewsi: {},
      barcode: "",
      qrcode: "",
    };
  }

  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };
  handleCloseXr = () => {
    this.state.activeItemPagkoiXrewsi = [{}];
    this.setState({ activeItemPagkoiXrewsi: [{}] });
    this.setState({ modalXR: false });
  };

  handleChangeX = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    this.setState({ activeItemXrewsi });

    this.setState({ state: this.state });
  };

  handleEditPagkoiXrewsi = (item) => {
    this.state.activeItemPagkoiXrewsi = item;
    // console.log(this.state.activeItemPagkoiXrewsi);
    this.state.modalXR = true;
    this.setState({ modalXR: true });
    this.setState({ state: this.state });
    // console.log(this.state.modalXR);
  };

  handleNewPagkoiXrewsi = () => {
    this.state.activeItemPagkoiXrewsi = {};

    this.state.activeItemPagkoiXrewsi.timeEp = "";
    this.state.activeItemPagkoiXrewsi.timePar = "";

    // console.log(this.state.activeItemPagkoiXrewsi);
    this.setState({ modalXR: true });
  };

  handlePostXrewseis = () => {
    const newItem = this.state.activeItemPagkoiXrewsi;

    if (newItem.id) {
      // formData.append("id", newItem.id);
      // axios
      //   .put(
      //     `http://127.0.0.1:8000/api/Xrewseis/${newItem.id}/`,
      //     newItem
      //   )
      session
        .put(`api/Xrewseis/${newItem.id}/`, newItem)
        .then(this.refreshList())
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      // axios
      //   .post("http://127.0.0.1:8000/api/Xrewseis/", newItem)
      session.post("api/Xrewseis/", newItem).then(this.refreshList());
    }

    this.handleCloseXr();
  };

  handleChangePXrewseis = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemPagkoiXrewsi = {
      ...this.state.activeItemPagkoiXrewsi,
      [name]: value,
    };

    this.setState({ activeItemPagkoiXrewsi });

    this.setState({ state: this.state });
  };

  handleChangeEnarxiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItemXrewsi.enarxiP = fulldate;

    // console.log(this.state.activeItemXrewsi);
    // this.setState({ activeItem });
  };

  handleChangeLixiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItemXrewsi.lixiP = fulldate;

    // console.log(this.state.activeItemXrewsi);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangePagkosBoolsXrewsi = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    // console.log(activeItemXrewsi);
    this.setState({ activeItemXrewsi });
  };

  handlePostXrewsi = () => {
    const newItem = this.state.activeItemXrewsi;

    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      if (newItem.kathgoria === "Επαγγελματίες") {
        newItem.poso =
          parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
      } else {
        const parousies = this.state.ParousiesList.filter(
          (itemP) => itemP.pagkos === newItem.pagkos && itemP.parousia == true
        );

        let num = 0;

        var dateE = new Date(newItem.enarxiP);
        var dateL = new Date(newItem.lixiP);

        for (var i = 0; i < parousies.length; i++) {
          var date = new Date(parousies[i].date);

          // console.log(parousies[i]);

          if (date >= dateE && date <= dateL) {
            num = num + 1;
          }
        }
        newItem.poso =
          parseInt(newItem.tetragwnika) *
          parseInt(newItem.timimonadas) *
          parseInt(num);
      }

      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `http://127.0.0.1:8000/api/PagkoiXrewsi/${newItem.id}/`,
        //     newItem
        //   )
        session
          .put(`api/PagkoiXrewsi/${newItem.id}/`)
          .then(this.refreshList())
          .catch((error) => {
            alert(error.response.data);
          });
      } else {
        // axios
        //   .post("http://127.0.0.1:8000/api/PagkoiXrewsi/", newItem)
        session.post("api/PagkoiXrewsi/", newItem).then(this.refreshList());
      }

      // this.state.activeItemXrewsi = [];
      this.refreshList();
      this.handleCloseX();
      this.setState({ state: this.state });
    } else {
      alert("προσθέστε τα απαραίτητα στοιχεία");
    }
  };
  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;

    // console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleNewXrewsi = () => {
    this.state.activeItemXrewsi = {};

    this.state.activeItemXrewsi.id = "";
    this.state.activeItemXrewsi.poso = "";
    this.state.activeItemXrewsi.plirwse = false;

    this.state.activeItemXrewsi.tetragwnika = this.state.activeItem.tetragwnika;
    this.state.activeItemXrewsi.timimonadas = this.state.activeItem.timimonadas;
    this.state.activeItemXrewsi.kathgoria = this.state.activeItem.kathgoria;
    this.state.activeItemXrewsi.pagkos = this.state.activeItem.nameP;

    // console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleClose = () => {
    this.state.activeItem = [];
    this.setState({ activeItem: [] });
    this.setState({ modalP: false });
  };

  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };

  handleCloseE = () => {
    this.state.activeItemE = [];
    this.setState({ activeItemE: [] });
    this.setState({ modalE: false });
  };

  handleCloseEC = () => {
    this.state.activeItemEC = [];
    this.setState({ activeItemEC: [] });
    this.setState({ modalEC: false });
  };

  handleCloseLaiki = () => {
    this.state.activeItemLaiki = [];
    this.setState({ activeItemLaiki: [] });
    this.setState({ modalL: false });
  };
  handleChangeEC = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemEC = { ...this.state.activeItemEC, [name]: value };
    // formData.append([name], value);
    // console.log("ok");
    this.setState({ activeItemEC });
  };
  ClearLaikiSelect() {
    this.state.laikiselected = "-";
    this.state.laikiselectedcenter = center;
    this.setState({ state: this.state });
    this.refreshList();
  }

  handleLaikiSelect(e, latlng) {
    const value = e.target.value;

    // console.log(value);

    this.state.laikiselected = value;
    this.state.laikiselectedcenter = latlng;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 18);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handleChangeSelectIcon = (e) => {
    const name = "iconeE";
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    // console.log(activeItemE);
    this.setState({ activeItemE });

    this.setState({ state: this.state });
  };
  handleChangeLogo = (e) => {
    // console.log("ok");
    var logo = e.target.files[0];
    // formData.append("logo", logo);

    this.state.activeItemEC.logo = e.target.files[0];
  };

  handleMultiSelectChange = (e) => {
    const name = "laikiAgoraId";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    // console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };
  handleMultiSelectChangeΚ = (e) => {
    const name = "kathgoria";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    // console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeEp = (e) => {
    // console.log(e);
    // console.log(e.target.id);
    // console.log(e.target.name);
    // console.log(e.target.value);

    const name = e.target.id;
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    // // console.log(activeItemE);
    this.setState({ activeItemE });
  };

  handleChangeEnarxiE = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItemE.enarxiE = fulldate;

    // console.log(this.state.activeItemE);
    this.setState({ state: this.state });
    // this.setState({ activeItemE });
  };

  handleChangeBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    // console.log(activeItem);
    this.setState({ activeItem });
  };

  handleChangeBools2 = (e, item) => {
    const name = e.target.id;
    const value = e.target.checked;
    // console.log(item);
    // console.log(name);
    // console.log(value);
    // const activeItem = { ...this.state.activeItem, [name]: value };

    // // console.log(activeItem);
    // this.setState({ activeItem });
  };

  handleChangeLixiE = (e) => {
    // // console.log(e);

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    this.state.activeItemE.lixiE = fulldate;

    this.setState({ state: this.state });
  };

  handleChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    // console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleDeleteDocP = (id) => {
    this.setState({ modalP: false });
    // axios
    //   .delete(`http://127.0.0.1:8000/api/FileFieldFormPagkos/${id}`)
    session
      .delete(`api/FileFieldFormPagkos/${id}`)
      .then(this.refreshListDocs());
    this.refreshList();
    this.refreshListDocs();
    this.refreshListDocs();

    this.state.selectedDocs = [];

    this.state.selectedDocs = this.state.docsList.filter(
      (itemS) => itemS.pagkos === this.state.activeItem.anagnwristikoP
    );

    this.setState({ state: this.state });

    this.render();
    document.getElementById("file_field").value = null;

    this.refreshListDocs();
    this.setState({ modalP: false });
    this.yourFunctionP();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunctionP() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    // console.log("Waited 5s");
    this.handleEdit(this.state.activeItem);
    this.setState({ modalP: false });
    await delay(1000);
    // console.log("Waited 5s");
    this.handleEdit(this.state.activeItem);
  }

  handleChangeArxDoc = (e) => {
    var files = e.target.files[0];
    // console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.arxdocitem.file_field = e.target.files[0];
    this.state.arxdocitem.pagkos = this.state.activeItem.anagnwristikoP;
    this.state.arxdocitem.description = fname[0];
  };

  handleChangeSelect = (e) => {
    const name = "categoryP";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    // console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeLaikiMeres = (e) => {
    // console.log(e);
    // console.log(e.target.id);
    // console.log(e.target.checked);

    const name = e.target.id;
    const value = e.target.checked;

    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    // console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });
  };

  handleChangeLaiki = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    // console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });

    this.setState({ state: this.state });
  };

  checkaddress = () => {
    let address = this.state.activeItemE.dieuthinsi;

    let latS = "";
    let lngS = "";

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        latS = lat;
        lngS = lng;
        // console.log(lat, lng);
        var tmpPos = [];
        tmpPos.push(latS);
        tmpPos.push(lngS);
        posseleted.push(tmpPos);

        if (this.state.mapModal) {
          this.state.mapModal.flyTo(tmpPos, 18);
          var marker = L.marker([latS, lngS]);

          this.state.mapModal.addLayer(marker);
        }

        // if (this.state.mapModal != null) {
        //   var marker = L.marker([latS, lngS]);
        //   var markers = L.markerClusterGroup();
        //   markers.addLayer(marker);
        //   this.state.mapModal.addLayer(markers);

        //   const { map } = this.state;
        //   if (map) map.flyTo(latlng, 18);

        // }
      },
      (error) => {
        // console.error(error);
      }
    );

    // Geocode.fromAddress("Eiffel Tower").then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     // console.log(lat, lng);
    //   },
    //   (error) => {
    //     // console.error(error);
    //   }
    // );
  };

  handlePostEp = () => {
    // // console.log(newItem);
    // // console.log(posseleted);
    const newItem = this.state.activeItemE;

    let lenghtps = posseleted.length;

    if (lenghtps != 0) {
      newItem.coordsE = posseleted[lenghtps - 1];
    }

    if (adressel != "") {
      newItem.dieuthinsi = adressel;
    }
    newItem.centerE = newItem.coordsE;

    // newItem.enarxiE = "2021-04-21";
    // newItem.lixiE = "2021-04-26";
    // newItem.iconeE = "test";

    // console.log(newItem);
    // // console.log(posseleted);

    if (newItem.centerE[0] == 0) {
      alert("Παρακαλούμε επιλέξτε σημείο στον χάρτη");
    } else {
      if (newItem.id) {
        // axios
        //   .put(
        //     `http://127.0.0.1:8000/api/Epixeiriseis/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/Epixeiriseis/${newItem.id}/`).then(this.refreshList());
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      } else {
        // axios
        //   .post("http://127.0.0.1:8000/api/Epixeiriseis/", newItem)
        session.post("api/Epixeiriseis/", newItem).then(this.refreshList());
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      }

      this.state.activeItemE = [];
      this.refreshList();
      this.handleCloseE();
      document.getElementById("file_field").value = null;
    }
    // newE = true;
  };

  handleNewE = () => {
    // // console.log(item);
    this.state.activeItemE.userIdE = "";
    this.state.activeItemE.nameE = "";
    this.state.activeItemE.afmE = "";

    this.state.activeItemE.arfak = "";
    this.state.activeItemE.antikatastasi = false;
    this.state.activeItemE.paratiriseis = "";
    this.state.activeItemE.dieuthinsi = "";

    this.state.activeItemE.siteE = "";
    this.state.activeItemE.posoE = "";
    this.state.activeItemE.covidStatus = "";
    this.state.activeItemE.wrarioE = "";
    this.state.activeItemE.wrarioL = "";
    this.state.activeItemE.iconeE = "";

    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemE);
    // this.render();
  };
  handleNewEC = () => {
    // // console.log(item);
    this.state.activeItemEC.nameEC = "";
    this.state.activeItemEC.logo = "";
    // this.state.activeItemE = item;
    this.setState({ modalEC: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemEC);
    // this.render();
  };

  handleEditE = (item) => {
    // console.log(item);
    this.state.activeItemE = item;

    this.refreshListDocs();

    this.state.selectedDocsE = this.state.docsListE.filter(
      (itemS) => String(itemS.epixeirisi) === String(item.nameE)
    );

    // console.log(this.state.selectedDocsE);

    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemE);
    // this.render();
  };

  handleDeleteE = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/Epixeiriseis/${item.id}`)
    session.delete("api/Epixeiriseis/${item.id}").then(this.refreshList());
    this.refreshList();
  };

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    // console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    // console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };

  handleChangeLixi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItem.lixiP = fulldate;

    // console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeEnarxi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItem.enarxiP = fulldate;

    // console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };

  handleChangeLixiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItem.lixiAP = fulldate;

    // console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };

  handleChangeEnarxiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // // console.log(fulldate);

    this.state.activeItem.enarxiAP = fulldate;

    // console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };

  handlePostLaiki = (e) => {
    // console.log(posseleted);
    const newItem = this.state.activeItemLaiki;

    var length = posseleted.length;

    if (posseleted[length - 1] != undefined) {
      newItem.centerLA = posseleted[length - 1];
      newItem.coordsLA = posseleted[length - 1];
    }

    // console.log(newItem);
    // // console.log(posseleted);

    if (newItem.id) {
      // axios
      //   .put(
      //     `http://127.0.0.1:8000/api/LaikiAgora/${newItem.id}/`,
      //     newItem
      //   )
      session
        .put(`api/LaikiAgora/${newItem.id}/`, newItem)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("http://127.0.0.1:8000/api/LaikiAgora/", newItem)
      session.post("api/LaikiAgora/", newItem).then(this.refreshList());
    }

    this.state.activeItem = [];
    this.refreshList();
    this.handleCloseLaiki();
  };

  handlePost = () => {
    // // console.log(newItem);
    // // console.log(posseleted);

    const newItem = this.state.activeItem;

    newItem.qr_code = this.state.activeItem.barcode;

    var length = posseleted.length;
    if (posseleted[length - 1] != undefined || posseleted[length - 1] != 0) {
      newItem.coordsP1 = posseleted[length - 1];
    }
    if (posseleted[length - 2] != undefined || posseleted[length - 2] != 0) {
      newItem.coordsP2 = posseleted[length - 2];
    }
    if (posseleted[length - 3] != undefined || posseleted[length - 3] != 0) {
      newItem.coordsP3 = posseleted[length - 3];
    }
    if (posseleted[length - 4] != undefined || posseleted[length - 4] != 0) {
      newItem.coordsP4 = posseleted[length - 4];
    }

    // console.log(newItem);

    if (
      newItem.coordsP1 != undefined &&
      newItem.coordsP2 != undefined &&
      newItem.coordsP3 != undefined &&
      newItem.coordsP4 != undefined
    ) {
      newItem.centerP = [
        (newItem.coordsP1[0] + newItem.coordsP3[0]) / 2,
        (newItem.coordsP1[1] + newItem.coordsP3[1]) / 2,
      ];

      if (
        newItem.coordsP2[0] == 0 &&
        newItem.coordsP3[0] == 0 &&
        newItem.coordsP4[0] == 0
      ) {
        newItem.coordsP1 = undefined;
        newItem.coordsP2 = undefined;
        newItem.coordsP3 = undefined;
        newItem.coordsP4 = undefined;
      }

      if (newItem.coordsP3[0] == 0 && newItem.coordsP4[0] == 0) {
        newItem.coordsP1 = undefined;
        newItem.coordsP2 = undefined;
        newItem.coordsP3 = undefined;
        newItem.coordsP4 = undefined;
      }
    }

    if (newItem.coordsP1 == 0 && newItem.coordsP2 == 0) {
      newItem.coordsP1 = undefined;
      newItem.coordsP2 = undefined;
      newItem.coordsP3 = undefined;
      newItem.coordsP4 = undefined;
    }
    // newItem.userIdp = 2;

    // console.log(newItem);

    if (newItem.kathgoria == "επαγγελματίες") {
      newItem.timimonadas = this.state.xrewsiepagk;
    } else {
      newItem.timimonadas = this.state.xrewsiparag;
    }

    newItem.anagnwristikoP = newItem.nameP + "-" + newItem.userIdp;

    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      newItem.poso =
        parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
    }
    // // console.log(posseleted);
    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      newItem.poso =
        parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
    }

    // console.log(newItem);

    if (
      newItem.nameP == "" ||
      newItem.userIdp == "" ||
      newItem.categoryP == "" ||
      newItem.afmP == "" ||
      newItem.patronymo == "" ||
      newItem.kathgoria == ""
    ) {
      alert("Παρακαλούμε προσθέστε τα υποχρεωτικά πεδία");
      // }
      // else if (
      //   newItem.centerP[0] === 0 ||
      //   newItem.coordsP1[0] === 0 ||
      //   newItem.coordsP2[0] === 0 ||
      //   newItem.coordsP3[0] === 0 ||
      //   newItem.coordsP4[0] === 0
      // ) {
      //   alert("Παρακαλούμε σχεδιάστε τον πάγκο στον χάρτη");
    } else {
      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `http://127.0.0.1:8000/api/Pagkoi/${newItem.id}/`,
        //     newItem
        //   )
        session
          .put(`api/Pagkoi/${newItem.id}/`, newItem)
          .then(this.refreshList());

        if (this.state.arxdocitem.file_field != undefined) {
          setDoc(this.state.arxdocitem);
          this.refreshListDocs();
        }
      } else {
        if (UdataTablePids.includes(newItem.nameP)) {
          alert("Υπάρχει ήδη πάγκος με το ίδιο Αριθμό θέσης");
        } else {
          this.state.arxdocitem.pagkos = newItem.anagnwristikoP;

          // axios
          //   .post("http://127.0.0.1:8000/api/Pagkoi/", newItem)
          session.post("api/Pagkoi/", newItem).then(this.refreshList());

          if (this.state.arxdocitem.file_field != undefined) {
            setDoc(this.state.arxdocitem);
            this.refreshListDocs();
          }
        }
      }

      this.state.activeItem = [];
      this.refreshList();
      this.refreshListDocs();
      this.handleClose();
      this.setState({ state: this.state });
    }
  };
  // c

  handleNewL = () => {
    // // console.log(item);
    // this.state.activeItemLaiki = [];

    this.state.activeItemLaiki.nameLA = "";
    this.state.activeItemLaiki.perioxiA = "";
    // this.state.activeItem = item;
    this.setState({ modalL: true });
  };

  handleNew = () => {
    // // console.log(item);
    this.state.activeItem.nameP = "";
    this.state.activeItem.anagnwristikoP = "";
    this.state.activeItem.adeioP = false;
    this.state.activeItem.afmP = "";
    this.state.activeItem.siteP = "";
    this.state.activeItem.userIdp = "";
    this.state.activeItem.posoP = "";
    this.state.activeItem.plirwse = false;
    this.state.activeItem.isinteam = false;
    this.state.activeItem.anastoli = false;
    this.state.activeItem.patronymo = "";
    this.state.activeItem.dieuthinsi = "";
    this.state.activeItem.paratiriseis = "";
    this.state.activeItem.tilefwno = "";
    this.state.activeItem.kinito = "";
    this.state.activeItem.typos = "";
    this.state.activeItem.kathgoria = "";
    this.state.activeItem.adeiaAllouDimou = false;
    this.state.activeItem.adeiaDimou = false;
    this.state.activeItem.adeiaApokentrwmenis = false;
    this.state.activeItem.diakopi = false;
    this.state.activeItem.arfak = "";
    this.state.activeItem.laikiAgoraId = "";
    this.state.activeItem.categoryP = "";
    this.state.activeItem.barcode = "";
    this.state.activeItem.qrcode = "";
    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // // console.log(this.state.modalP);
    // // console.log(this.state.activeItem);
    // this.render();
  };

  handleEdit = (item) => {
    // // console.log(item);
    this.state.activeItem = item;

    this.refreshListDocs();

    this.state.selectedDocs = this.state.docsList.filter(
      (itemS) => itemS.pagkos === item.anagnwristikoP
    );

    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // // console.log(this.state.modalP);
    // // console.log(this.state.activeItem);
    // this.render();
  };

  handleEditLaiki = (item) => {
    this.state.activeItemLaiki = item;

    this.state.laikiselected = item.nameLA;
    this.state.laikiselectedcenter = item.latlng;

    // console.log(this.state.activeItemLaiki);
    // this.state.activeItem = item;
    this.setState({ modalL: true });
    // // console.log(this.state.modalP);
    // // console.log(this.state.activeItemLaiki);
    // this.render();
  };

  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;
    // console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleDelete = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/Pagkoi/${item.id}`)
    session.delete(`api/Pagkoi/${item.id}`).then(this.refreshList());
    this.refreshList();
    this.setState({ state: this.state });
  };
  componentDidMount() {
    this.refreshList();
    this.refreshListDocs();
    this.refreshListCategory();
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
  }

  refreshList() {
    // axios
    //   .get("http://127.0.0.1:8000/api/Pagkoi/")
    session
      .get("api/Pagkoi/")
      .then((res) => this.setState({ PagkoiList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/PagkoiXrewsi/")

    session
      .get("api/PagkoiXrewsi/")
      .then((res) => this.setState({ PagkoiXrewsiList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/PagkoiXrewsi/")
    session
      .get("api/PagkoiXrewsi/")
      .then((res) => this.setState({ LaikesSelectList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/LaikiAgora/")
    session
      .get("api/LaikiAgora/")
      .then((res) => this.setState({ LaikesList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/Epixeiriseis/")
    session
      .get("api/Epixeiriseis/")
      .then((res) => this.setState({ EpixeiriseisList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/ParousiaPagkou/")
    session
      .get("api/ParousiaPagkou/")
      .then((res) => this.setState({ ParousiesList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/ElegxosPagkou/")
    session
      .get("api/ElegxosPagkou/")
      .then((res) => this.setState({ ElegxoiList: res.data }))
      .catch((err) => console.log(err));
    // axios
    //   .get("http://127.0.0.1:8000/api/ParanomosPagkou/")
    session
      .get("api/ParanomosPagkou/")
      .then((res) => this.setState({ ParanomoiList: res.data }))
      .catch((err) => console.log(err));
    // axios
    //   .get("http://127.0.0.1:8000/api/Xrewseis/")

    session
      .get("api/Xrewseis/")
      .then((res) => this.setState({ XrewseisList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListDocs() {
    // axios
    //   .get("http://127.0.0.1:8000/api/FileFieldFormPagkos/")
    session
      .get("api/FileFieldFormPagkos/")
      .then((res) => this.setState({ docsList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/FileFieldFormEpixeirisi/")
    session
      .get("api/FileFieldFormEpixeirisi/")
      .then((res) => this.setState({ docsListE: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListCategory() {
    // axios
    //   .get("http://127.0.0.1:8000/api/PagkosCategory/")
    session
      .get("api/PagkosCategory/")
      .then((res) => this.setState({ PagkosCategoryList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/EpixeirisiCategory/")
    session
      .get("api/EpixeirisiCategory/")
      .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
      .catch((err) => console.log(err));
  }
  handleLaikiSelect(e) {
    const value = e.target.value;

    // console.log(value);

    this.state.laikiselected = value;

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handleDeleteDocE = (id) => {
    this.setState({ modalE: false });
    // axios
    //   .delete(
    //     `http://127.0.0.1:8000/api/FileFieldFormEpixeirisi/${id}`
    //   )
    session
      .delete(`api/FileFieldFormEpixeirisi/${id}`)
      .then(this.refreshListDocs());
    this.refreshList();
    this.refreshListDocs();

    this.state.selectedDocsE = this.state.docsListE.filter(
      (itemS) =>
        String(itemS.epixeirisi) === String(this.state.activeItemE.nameE)
    );

    this.setState({ state: this.state });

    this.render();
    this.refreshListDocs();
    document.getElementById("file_field").value = null;
    // window.location.reload();

    this.setState({ modalE: false });

    // this.setState({ modalE: true });
    this.yourFunctionE();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunctionE() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    this.handleEditE(this.state.activeItemE);
  }

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    // console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    // console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };
  render() {
    // console.log(this.state.laikiselected);

    const tmpItems = this.state.PagkoiList;
    // .filter(
    //   (item) => String(item.laikiAgoraId) === String(this.state.laikiselected)
    // );
    const tmpItemsLaikes = this.state.LaikesList;
    const tmpItemsLaikesSelect = this.state.LaikesSelectList;
    const { markers } = this.state;
    const classes = this.state.useStyles;
    const theme = this.state.useTheme;
    let EpixeiriseisItems = this.state.EpixeiriseisList;
    const Epcategories = this.state.EpixeiriseisCategoryList;
    let tmpXrewseis = this.state.XrewseisList;

    let categories = [];

    if (this.state.PagkosCategoryList != undefined) {
      categories = this.state.PagkosCategoryList;
    }

    if (categories != undefined) {
      for (var i = 0; i < tmpItems.length; i++) {
        // // console.log(tmpItems[i]);
        let categorytmp = categories.filter(
          (item) => String(item.namePC) === String(tmpItems[i].categoryP)
        );
        // // console.log(categorytmp);
        if (categorytmp[0] != undefined) {
          tmpItems[i].color = categorytmp[0].colorPC;
          tmpItems[i].Bcolor = categorytmp[0].description;
        }
      }
    } else {
      this.setState({ state: this.state });
    }

    const positionC = [39.365, 22.951];
    const open = this.state.open;

    // }
    if (this.state.activeItem.coordsP1 == undefined) {
      this.state.activeItem.coordsP1 = [0, 0];
      this.state.activeItem.coordsP2 = [0, 0];
      this.state.activeItem.coordsP3 = [0, 0];
      this.state.activeItem.coordsP4 = [0, 0];
    }

    // console.log(this.state.activeItem.laikiAgoraId);
    // console.log(this.state.laikiselected);

    if (this.state.activeItem.laikiAgoraId == undefined) {
      this.state.activeItem.laikiAgoraId = this.state.laikiselected;
    }

    if (Epcategories != undefined) {
      for (var i = 0; i < EpixeiriseisItems.length; i++) {
        // // console.log(EpixeiriseisItems[0].iconeE);
        // // console.log(Epcategories);
        let categorytmp = Epcategories.filter(
          (item) => String(item.nameEC) === String(EpixeiriseisItems[i].iconeE)
        );

        if (categorytmp[0] != undefined) {
          // // console.log(categorytmp);

          EpixeiriseisItems[i].logo = categorytmp[0].logo;

          const myIcon = L.icon({
            iconUrl: categorytmp[0].logo,
            iconSize: [25, 25],
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
          });

          EpixeiriseisItems[i].logo2 = myIcon;

          // const iconPerson = new L.Icon({
          //   iconUrl: require(categorytmp[0].logo),
          //   iconRetinaUrl: require(categorytmp[0].logo),
          //   iconAnchor: null,
          //   popupAnchor: null,
          //   shadowUrl: null,
          //   shadowSize: null,
          //   shadowAnchor: null,
          //   iconSize: new L.Point(60, 75),
          //   className: "leaflet-div-icon",
          // });

          // EpixeiriseisItems[i].logo = iconPerson;
        }
      }
    } else {
      Epcategories = undefined;
      this.setState({ state: this.state });
    }

    if (this.state.activeItemE.coordsE == undefined) {
      this.state.activeItemE.coordsE = [0, 0];
    }

    if (this.state.laikiselectedcenter == "") {
      this.state.laikiselectedcenter = center;
    }

    let xrewseislist = this.state.XrewseisList;

    let sortedProducts2 = [...xrewseislist];
    sortedProducts2.sort((a, b) => {
      if (parseInt(a.id) < parseInt(b.id)) {
        return 1;
      }
      if (parseInt(a.id) > parseInt(b.id)) {
        return -1;
      }
      return 0;
    });

    xrewseislist = sortedProducts2;

    if (xrewseislist[0] != undefined) {
      this.state.xrewsiepagk = xrewseislist[0].timeEp;
      this.state.xrewsiparag = xrewseislist[0].timePar;
    }

    UdataTableL = [];

    UdataTableP = [];
    UdataTablePids = [];

    for (var i = 0; i < tmpItemsLaikes.length; i++) {
      const dataRow = {};

      const item = tmpItemsLaikes[i];
      dataRow.eidos = "Λαϊκή";
      const name = tmpItemsLaikes[i].nameLA;
      dataRow.name = tmpItemsLaikes[i].nameLA;

      dataRow.perioxiA = tmpItemsLaikes[i].perioxiA;

      const days = "";

      if (item.deytera) {
        days += "Δευτέρα ";
      }
      if (item.triti) {
        days += "Τρίτη ";
      }
      if (item.tetarti) {
        days += "Τετάρτη ";
      }
      if (item.pempti) {
        days += "Πέμπτη ";
      }
      if (item.paraskeui) {
        days += "Παρασκευή ";
      }
      if (item.savvato) {
        days += "Σάββατο ";
      }
      if (item.kyriaki) {
        days += "Κυριακή ";
      }

      dataRow.days = days;

      const tmpItemsPagkoi = this.state.PagkoiList.filter(
        (item) => String(item.laikiAgoraId) === String(name)
      );

      if (tmpItemsPagkoi.length == 0) {
        dataRow.e = (
          <>
            {" "}
            <Button
              round
              color="info"
              size="sm"
              onClick={() => this.handleEditLaiki(item)}
            >
              <i className="fa fa-edit"></i>
            </Button>{" "}
            <Button
              round
              color="danger"
              size="sm"
              onClick={() => this.handleDeleteL(item)}
            >
              <i className="fa fa-times"></i>
            </Button>{" "}
          </>
        );
      } else {
        dataRow.e = (
          <>
            {" "}
            <Button
              round
              color="info"
              size="sm"
              onClick={() => this.handleEditLaiki(item)}
            >
              <i className="fa fa-edit"></i>
            </Button>{" "}
          </>
        );
      }

      UdataTableL.push(dataRow);
    }

    for (var i = 0; i < tmpItems.length; i++) {
      const dataRow = {};

      const item = tmpItems[i];

      dataRow.eidos = "Πάγκος";
      dataRow.name = tmpItems[i].userIdp;
      dataRow.userIdp = tmpItems[i].nameP;
      dataRow.timeE = String(tmpItems[i].enarxiP);

      UdataTablePids.push(tmpItems[i].nameP);

      var date1 = new Date();
      var date2 = new Date(tmpItems[i].lixiP);

      if (date1 > date2) {
        dataRow.timeL2 = (
          <Button round color="danger" size="sm">
            <i className="fa fa-exclamation"></i>
          </Button>
        );
      } else if (
        date2.getFullYear() == date1.getFullYear() &&
        date2.getMonth() - date1.getMonth() <= 1
      ) {
        dataRow.timeL2 = (
          <Button round color="warning" size="sm">
            <i className="fa fa-question"></i>
          </Button>
        );
      } else {
        dataRow.timeL2 = (
          <Button round color="success" size="sm">
            <i className="fa fa-check"></i>
          </Button>
        );
      }
      dataRow.timeL = String(tmpItems[i].lixiP);

      dataRow.catmedia = <Badge color={tmpItems[i].Bcolor}> </Badge>;
      dataRow.category = tmpItems[i].categoryP;

      dataRow.afmP = tmpItems[i].afmP;
      dataRow.tetragwnika = tmpItems[i].tetragwnika;
      dataRow.timimonadas = tmpItems[i].timimonadas;
      dataRow.poso = tmpItems[i].poso;

      dataRow.laikis = tmpItems[i].laikiAgoraId;
      dataRow.e = (
        <>
          {" "}
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEdit(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDelete(item)}
          >
            <i className="fa fa-times"></i>
          </Button>{" "}
        </>
      );

      UdataTableP.push(dataRow);
    }

    let UdataTablePX = [];

    let tmpItemsXrewsi = this.state.PagkoiXrewsiList.filter(
      (item) => String(item.pagkos) === String(this.state.activeItem.nameP)
    );

    for (var i = 0; i < tmpItemsXrewsi.length; i++) {
      const dataRow = {};
      //// console.log(tmpItemsXrewsi[i]);

      const item = tmpItemsXrewsi[i];

      dataRow.enarxiP = item.enarxiP;
      dataRow.lixiP = item.lixiP;
      dataRow.date = item.date;
      dataRow.poso = item.poso;

      if (item.pragmtetragwnika == "") {
        dataRow.tetragwnika = item.tetragwnika;
      } else {
        dataRow.tetragwnika = item.pragmtetragwnika;
      }

      dataRow.pagkos = item.pagkos;

      const tmppagkoi = this.state.PagkoiList.filter(
        (itemP) => itemP.nameP === item.pagkos
      );

      dataRow.epwn = tmppagkoi[0].userIdp;
      dataRow.afm = tmppagkoi[0].afmP;
      dataRow.time = item.enarxiP + " εώς " + item.lixiP;

      // const tmpparous = this.state.ParousiesList.filter(
      //   (itemPp) => itemPp.pagkos === item.pagkos && itemPp.parousia == true
      // );

      // let num = 0;

      // var dateE = new Date(item.enarxiP);
      // var dateL = new Date(item.lixiP);

      // for (var j = 0; j < tmpparous.length; j++) {
      //   var date = new Date(tmpparous[j].date);

      //   if (date >= dateE && date <= dateL) {
      //     num = num + 1;
      //   }
      // }

      // dataRow.par = num;

      // if (item.plirwse == true) {
      //   dataRow.plirwse = "Ναί";
      // } else {
      //   dataRow.plirwse = "Όχι";
      // }

      dataRow.e = (
        <>
          {" "}
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditXrewsi(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
        </>
      );

      //// console.log(dataRow);

      UdataTablePX.push(dataRow);
    }

    for (var i = 0; i < EpixeiriseisItems.length; i++) {
      const dataRow = {};
      const item = EpixeiriseisItems[i];

      dataRow.eidos = "Επιχείρηση";
      dataRow.name = EpixeiriseisItems[i].nameE;
      dataRow.timeE = String(EpixeiriseisItems[i].enarxiE);
      dataRow.timeL = String(EpixeiriseisItems[i].lixiE);

      var date1 = new Date();
      var date2 = new Date(EpixeiriseisItems[i].lixiE);

      if (date1 > date2) {
        dataRow.timeL2 = (
          <Button round color="danger" size="sm">
            <i className="fa fa-exclamation"></i>
          </Button>
        );
      } else if (
        date2.getFullYear() == date1.getFullYear() &&
        date2.getMonth() - date1.getMonth() <= 1
      ) {
        dataRow.timeL2 = (
          <Button round color="warning" size="sm">
            <i className="fa fa-question"></i>
          </Button>
        );
      } else {
        dataRow.timeL2 = (
          <Button round color="success" size="sm">
            <i className="fa fa-check"></i>
          </Button>
        );
      }

      dataRow.userIdE = String(EpixeiriseisItems[i].userIdE);
      dataRow.afmE = String(EpixeiriseisItems[i].afmE);
      dataRow.wrarioE = String(EpixeiriseisItems[i].wrarioE);
      dataRow.wrarioL = String(EpixeiriseisItems[i].wrarioL);

      dataRow.catmedia = (
        <img style={{ width: "25px" }} src={EpixeiriseisItems[i].logo} />
      );
      dataRow.category = EpixeiriseisItems[i].iconeE;
      dataRow.laikis = "-";

      dataRow.e = (
        <>
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditE(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeleteE(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );

      UdataTableE.push(dataRow);
    }

    // console.log(UdataTablePo);

    UdataTableXr = [];

    let sortedProductsXr = [...tmpXrewseis];
    sortedProductsXr.sort((a, b) => {
      if (parseInt(a.id) < parseInt(b.id)) {
        return 1;
      }
      if (parseInt(a.id) > parseInt(b.id)) {
        return -1;
      }
      return 0;
    });

    xrewseislist = sortedProductsXr;

    tmpXrewseis = xrewseislist;

    for (var i = 0; i < tmpXrewseis.length; i++) {
      const dataRow = {};
      let item = tmpXrewseis[i];

      dataRow.timeEp = item.timeEp;
      dataRow.timePar = item.timeEp;

      let tmp = item.date;
      let tmp2 = tmp.split("-");
      dataRow.date = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      dataRow.e = (
        <Button
          round
          color="info"
          size="sm"
          onClick={() => this.handleEditPagkoiXrewsi(item)}
        >
          <i className="fa fa-edit"></i>
        </Button>
      );

      UdataTableXr.push(dataRow);
    }

    return (
      <>
        {/* <GridContainer> */}
        {/* <GridItem xs={6}>
            <Row>
              <Col>
                {categories.length != 0 && (
                  <MapContainer
                    center={center}
                    zoom={zoom}
                    scrollWheelZoom={false}
                    style={{ height: "80vh", width: "200wh" }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {tmpItems.map((item) => (
                      <Polygon
                        positions={[
                          item.coordsP1,
                          item.coordsP2,
                          item.coordsP3,
                          item.coordsP4,
                        ]}
                        color={item.color}
                      >
                        <Popup>{item.nameP}</Popup>
                      </Polygon>
                    ))}
                    {tmpItemsLaikes.map((item) => (
                      <Marker
                        key={`marker-${item.id}`}
                        position={item.coordsLA}
                      >
                        <Popup>{item.nameE}</Popup>
                      </Marker>
                    ))}
                  </MapContainer>
                )}
              </Col>
            </Row>
          </GridItem> */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardHeader color="success">
                Λαϊκές{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewL()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>

              <CardBody>
                <ReactTable
                  data={UdataTableL}
                  filterable
                  resizable={true}
                  columns={[
                    {
                      Header: "Επωνυμία",
                      accessor: "name",
                    },
                    {
                      Header: "Περιοχή",
                      accessor: "perioxiA",
                    },
                    {
                      Header: "Ημέρες",
                      accessor: "days",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader color="success">
                Πάγκοι{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNew()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>

              <CardBody>
                <ReactTable
                  data={UdataTableP}
                  filterable
                  resizable={true}
                  columns={[
                    {
                      Header: "Επωνυμία",
                      accessor: "userIdp",
                    },
                    // {
                    //   Header: "Ονοματεπώνυμο",
                    //   accessor: "name",
                    // },

                    {
                      Header: "Λαϊκή",
                      accessor: "laikis",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },

                    {
                      Header: "Ιδιότητα",
                      accessor: "category",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "",
                      accessor: "catmedia",
                      disableFilters: true,
                      disableSortBy: true,
                    },

                    // {
                    //   Header: "Έναρξη",
                    //   accessor: "timeE",
                    // },
                    // {
                    //   Header: "Λήξη",
                    //   accessor: "timeL",
                    // },
                    {
                      Header: " ",
                      accessor: "timeL2",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },

                    // {
                    //   Header: " ",
                    //   accessor: "",
                    // },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardHeader color="success">
                Επιχειρήσεις{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewE()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>

              <CardBody>
                <ReactTable
                  data={UdataTableE}
                  filterable
                  resizable={true}
                  columns={[
                    {
                      Header: "Επωνυμία",
                      accessor: "name",
                    },
                    {
                      Header: "Ονοματεπώνυο",
                      accessor: "userIdE",
                    },

                    {
                      Header: "Κατηγορία",
                      accessor: "category",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "",
                      accessor: "catmedia",
                      disableFilters: true,
                      disableSortBy: true,
                    },

                    // {
                    //   Header: "Λήξη",
                    //   accessor: "timeL",
                    // },
                    {
                      Header: " ",
                      accessor: "timeL2",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },

                    // {
                    //   Header: " ",
                    //   accessor: "",
                    // },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader color="success">
                Χρεώσεις{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewPagkoiXrewsi()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>

              <CardBody>
                <ReactTable
                  data={UdataTableXr}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Τιμή επαγγελματιών",
                      accessor: "timeEp",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Τιμή παραγωγών",
                      accessor: "timePar",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ημερομηνία",
                      accessor: "date",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
            {/* <Button
              size="sm"
              round
              onClick={() => this.exagwgi()}
              color="success"
            >
              Εξαγωγή σε excel
            </Button> */}
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalE}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="xlg"
          onClose={() => this.handleCloseE()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Στοιχεία Επιχείρησης
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseE()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <Card>
                  <CardHeader color="success"> Χάρτης</CardHeader>
                  <CardBody>
                    {" "}
                    <label>
                      Παρακαλούμε εισάγετε την διεύθυνση παρακάτω και πατήστε
                      για αποθήκευση ή επιλέξτε την στον χάρτη
                    </label>
                    <Row>
                      <CustomInput
                        id="dieuthinsi"
                        inputProps={{
                          value: this.state.activeItemE.dieuthinsi,
                          placeholder: "Διεύθυνση",
                          onChange: (e) => this.handleChangeEp(e),
                          type: "text",
                        }}
                      />
                      <Button
                        size="sm"
                        round
                        onClick={() => this.checkaddress()}
                        color="success"
                      >
                        Αποθήκευση διέυθυνσης
                      </Button>
                    </Row>
                    <MapContainer
                      center={center}
                      zoom={zoom}
                      scrollWheelZoom={false}
                      style={{ height: "50vh", width: "180wh" }}
                      whenCreated={(mapModal) => this.setState({ mapModal })}
                    >
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      <Marker position={this.state.activeItemE.coordsE}>
                        <Tooltip>{this.state.activeItemE.nameE}</Tooltip>
                      </Marker>

                      {/* <MyComponent /> */}
                      <LocationMarker />
                    </MapContainer>
                  </CardBody>
                </Card>
              </GridItem>
              {/* <GridItem xs={1}></GridItem> */}
              <GridItem xs={6} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="success"> Στοιχεία Επιχείρησης</CardHeader>
                  <CardBody>
                    {" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>Επωνυμία*</label> <br />
                    <CustomInput
                      id="nameE"
                      inputProps={{
                        value: this.state.activeItemE.nameE,
                        placeholder: "Επωνυμία*",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label style={{ color: "#47A34B" }}>Ιδιοκτήτης*</label>{" "}
                    <br />
                    <CustomInput
                      id="userIdE"
                      inputProps={{
                        value: this.state.activeItemE.userIdE,
                        placeholder: "Ιδιοκτήτης*",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {/* <CustomInput
                  id="dieuthinsi"
                  inputProps={{
                    value: this.state.activeItemE.dieuthinsi,
                    placeholder: "Διεύθυνση",
                    onChange: (e) => this.handleChangeEp(e),
                    type: "text",
                  }}
                /> */}
                    {/* <CustomInput
                  id="tk"
                  inputProps={{
                    value: this.state.activeItemE.tk,
                    placeholder: "ΤΚ",
                    onChange: (e) => this.handleChangeAdd(e),
                    type: "text",
                  }}
                /> */}
                    <br />
                    <label>
                      <b>ΑΦΜ</b>
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="afmE"
                      inputProps={{
                        value: this.state.activeItemE.afmE,
                        placeholder: "ΑΦΜ",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "number",
                      }}
                    />{" "}
                    <br />
                    <label>
                      <b>Αριθμός Φακέλου</b>
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="arfak"
                      inputProps={{
                        value: this.state.activeItemE.arfak,
                        placeholder: "Αριθμός Φακέλου",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "number",
                      }}
                    />{" "}
                    <br />
                    <label>
                      <b>Site</b>
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="siteE"
                      inputProps={{
                        value: this.state.activeItemE.siteE,
                        placeholder: "Site",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {/* <Input
                  type="select"
                  name="iconeE"
                  id="iconeE"
                  value={String(this.state.activeItemE.iconeE)}
                  onChange={(e) => this.handleChangeEp(e)}
                >
                  <option style={{ color: "black" }}>{"-"}</option>
                  {Epcategories.map((item) => (
                    <option value={item.nameEC}>{item.nameEC}</option>
                  ))}
                </Input> */}
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      Επιλογή Κατηγορίας*
                    </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="iconeE"
                      id="iconeE"
                      value={String(this.state.activeItemE.iconeE)}
                      onChange={(e) => this.handleChangeSelectIcon(e)}
                    >
                      {Epcategories.map((item) => (
                        <MenuItem value={item.nameEC}>
                          <img style={{ width: "20px" }} src={item.logo} />{" "}
                          {item.nameEC}
                        </MenuItem>
                      ))}
                    </Select>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success"> Παρατηρήσεις</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItemE.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="success"> Οικονομικά στοιχεία</CardHeader>
                  <CardBody>
                    {" "}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarxiE"
                        name="enarxiE"
                        showTodayButton
                        label="Έναρξη"
                        value={this.state.activeItemE.enarxiE}
                        onChange={(e) => this.handleChangeEnarxiE(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <br />
                    <br />
                    <br />
                    {/* <InputLabel id="lixiE" className={classes.label}>
                  Λήξη
                </InputLabel> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="lixeE"
                        name="lixeE"
                        label="Λήξη"
                        value={this.state.activeItemE.lixiE}
                        onChange={(e) => this.handleChangeLixiE(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <CustomInput
                  id="posoE"
                  inputProps={{
                    value: this.state.activeItemE.posoE,
                    placeholder: "Ποσό",
                    onChange: (e) => this.handleChangeEp(e),
                    type: "text",
                  }}
                /> */}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success"> Επιπλέον στοιχεία</CardHeader>
                  <CardBody>
                    {" "}
                    <CustomInput
                      id="wrarioE"
                      inputProps={{
                        value: this.state.activeItemE.wrarioE,
                        placeholder: "Έναρξη ωραρίου (ΩΩ:ΛΛ)",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {/* <h3> Λήξη ωραρίου</h3>*/}
                    <CustomInput
                      id="wrarioL"
                      inputProps={{
                        value: this.state.activeItemE.wrarioL,
                        placeholder: "Λήξη ωραρίου (ΩΩ:ΛΛ)",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {this.state.activeItemE.antikatastasi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangeBools(e)}
                            name="antikatastasi"
                            id="antikatastasi"
                            color="primary"
                          />
                        }
                        label="Αντικατάσταση;"
                      />
                    )}
                    {!this.state.activeItemE.antikatastasi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangeBools(e)}
                            name="antikatastasi"
                            id="antikatastasi"
                            color="primary"
                          />
                        }
                        label="Αντικατάσταση;"
                      />
                    )}
                    {/* <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItemE.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "number",
                      }}
                    /> */}
                    {/* <CustomInput
                  id="covidStatus"
                  inputProps={{
                    value: this.state.activeItemE.covidStatus,
                    placeholder: "Covid Status",
                    onChange: (e) => this.handleChangeEp(e),
                    type: "text",
                  }}
                /> */}
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        Αρχείο
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangeArxDocE(e)}
                        name="file_field"
                        type="file"
                      />
                    </FormGroup>
                    {this.state.activeItemE.id && (
                      <>
                        Διαθέσιμα αρχεία
                        {this.state.selectedDocsE.map(
                          ({ id, file_field, description }) => (
                            <Badge>
                              <Button
                                size="sm"
                                round
                                onClick={() =>
                                  window.open(file_field, "_blank")
                                }
                              >
                                {" "}
                                {description}{" "}
                              </Button>

                              <Button
                                round
                                color="danger"
                                size="sm"
                                onClick={() => this.handleDeleteDocE(id)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Badge>
                          )
                        )}{" "}
                      </>
                    )}
                  </CardBody>
                </Card>
              </GridItem>

              {/* <TextField
                  id="time"
                  label="Alarm clock"
                  type="time"
                  defaultValue={this.state.activeItemE.wrarioE}
                  className={classes.textField}
                  onChange={(e) => this.handleChangeEnarxiWrariou2(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 100, // 5 min
                  }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={this.state.activeItemE.wrarioE}
                    onChange={(e) => this.handleChangeEnarxiWrariou(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseE()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostEp()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalEC}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseEC()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseEC()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="nameEC"
                  inputProps={{
                    value: this.state.activeItemEC.nameEC,
                    placeholder: "Όνομα",
                    onChange: (e) => this.handleChangeEC(e),
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <Input
                  // style={{
                  //   color: "black",
                  //   borderRadius: "100px",
                  //   height: "25px",
                  // }}
                  accept="image/*"
                  // className={classes.input}
                  id="post-image"
                  onChange={(e) => this.handleChangeLogo(e)}
                  name="image"
                  type="file"
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseEC()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => setlogo(this.state.activeItemEC)}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalP}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="xlg"
          onClose={() => this.handleClose()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Στοιχεία Πάγκου Λαϊκής
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose()}
            >
              <Close className={classes.modalLlose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <Card>
                  <CardHeader color="success">Χάρτης</CardHeader>
                  <CardBody>
                    {" "}
                    <label>Παρακαλούμε σχεδιάστε τον πάγκο στον χάρτη</label>
                    <MapContainer
                      center={this.state.laikiselectedcenter}
                      zoom={zoom}
                      scrollWheelZoom={false}
                      style={{ height: "50vh", width: "150wh" }}
                      whenCreated={(map) => this.setState({ map })}
                    >
                      <FeatureGroup>
                        <EditControl
                          position="topright"
                          onEdited={this._onEditPath}
                          onCreated={this._onCreate}
                        />
                      </FeatureGroup>

                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      {tmpItems.map((item) => (
                        <>
                          {item.coordsP1 != null && (
                            <Polygon
                              positions={[
                                item.coordsP1,
                                item.coordsP2,
                                item.coordsP3,
                                item.coordsP4,
                              ]}
                              color={item.color}
                            >
                              <Tooltip>{item.nameP}</Tooltip>
                            </Polygon>
                          )}
                        </>
                      ))}

                      <Polygon
                        positions={[
                          this.state.activeItem.coordsP1,
                          this.state.activeItem.coordsP2,
                          this.state.activeItem.coordsP3,
                          this.state.activeItem.coordsP4,
                        ]}
                        color="red"
                      >
                        <Tooltip>{this.state.activeItem.nameP}</Tooltip>
                      </Polygon>

                      <Marker position={this.state.activeItem.coordsP1}>
                        <Tooltip>{this.state.activeItem.nameP}</Tooltip>
                      </Marker>

                      {/* <MyComponent /> */}
                      <LocationMarker />
                    </MapContainer>
                  </CardBody>
                </Card>
              </GridItem>
              {/* <GridItem xs={1}></GridItem> */}

              <GridItem xs={6} sm={6} md={6} lg={3}>
                {/* <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-name-label">Name</InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="laikiAgoraId"
                    multiple
                    // value={personName}
                    // onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {tmpItemsLaikes.map((item) => (
                      <MenuItem value={item.id}>{item.nameLA}</MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {/* <Input
                  type="select"
                  name="laikiAgoraId"
                  id="laikiAgoraId"
                  onChange={(e) => this.handleChange(e)}
                >
                  <option style={{ color: "black" }}>{"-"}</option>
                  {tmpItemsLaikes.map((item) => (
                    <option value={item.id}>{item.nameLA}</option>
                  ))}
                </Input> */}
                <Card>
                  <CardHeader color="success">Στοιχεία επιχείρησης</CardHeader>
                  <CardBody>
                    {" "}
                    <label>
                      {" "}
                      <b> Επιλογή Λαϊκής </b>
                    </label>{" "}
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="laikiAgoraId"
                      id="laikiAgoraId"
                      value={String(this.state.activeItem.laikiAgoraId)}
                      onChange={(e) => this.handleMultiSelectChange(e)}
                      style={{ fontSize: "14px" }}
                    >
                      {tmpItemsLaikes.map((item) => (
                        <MenuItem value={item.nameLA}>{item.nameLA}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      Επιλογή Κατηγορίας πωλητών*
                    </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="kathgoria"
                      id="kathgoria"
                      value={String(this.state.activeItem.kathgoria)}
                      onChange={(e) => this.handleMultiSelectChangeΚ(e)}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem value="παραγωγοί">Παραγωγοί </MenuItem>
                      <MenuItem value="επαγγελματίες">Επαγγελματίες </MenuItem>
                    </Select>
                    <br />
                    {/* <MultiSelect
                  options={tmpItemsLaikesSelect}
                  // value={selected}
                  onChange={(e) => this.handleMultiSelectChange(e)}
                  labelledBy="Select"
                /> */}
                    <label style={{ color: "#47A34B" }}>Αριθμός Θέσης*</label>{" "}
                    <br />
                    <CustomInput
                      id="nameP"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.nameP,
                        placeholder: "Αριθμός Θέσης*",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label style={{ color: "#47A34B" }}> ΑΦΜ * </label> <br />
                    <CustomInput
                      id="afmP"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.afmP,
                        placeholder: "ΑΦΜ",
                        onChange: (e) => this.handleChange(e),
                        type: "number",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Αρ. Φακέλου </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="arfak"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.arfak,
                        placeholder: "Αρ. Φακέλου",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Site </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="siteP"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.siteP,
                        placeholder: "Site",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Διεύθυνση </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="dieuthinsi"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.dieuthinsi,
                        placeholder: "Διεύθυνση",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label>
                      {" "}
                      <b> Barcode </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="barcode"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.barcode,
                        placeholder: "Barcode",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      Επιλογή Είδους Πάγκου*
                    </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="categoryP"
                      required
                      id="categoryP"
                      value={String(this.state.activeItem.categoryP)}
                      onChange={(e) => this.handleChangeSelect(e)}
                      style={{ fontSize: "12px" }}
                    >
                      {categories.map((item) => (
                        <MenuItem value={item.namePC}>{item.namePC}</MenuItem>
                      ))}
                    </Select>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success">Στοιχεία Κατόχου</CardHeader>
                  <CardBody>
                    {" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      {" "}
                      Ονοματεπώνυμο *{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="userIdp"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.userIdp,
                        placeholder: "Ονοματεπώνυμο",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      {" "}
                      Πατρώνυμο *{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="patronymo"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.patronymo,
                        placeholder: "Πατρώνυμο",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Τηλέφωνο </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="tilefwno"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.tilefwno,
                        placeholder: "Τηλέφωνο",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label>
                      {" "}
                      <b> Κινητό </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="kinito"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.kinito,
                        placeholder: "Κινητό",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader color="success">Αρχεία</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        <b> Αρχείο </b>
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangeArxDoc(e)}
                        name="file_field"
                        type="file"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                    </FormGroup>{" "}
                    <br />
                    {this.state.activeItem.id && (
                      <>
                        <label>
                          {" "}
                          <b> Διαθέσιμα αρχεία </b>
                        </label>
                        {this.state.selectedDocs.map(
                          ({ id, file_field, description }) => (
                            <Badge>
                              <Button
                                size="sm"
                                round
                                onClick={() =>
                                  window.open(file_field, "_blank")
                                }
                              >
                                {" "}
                                {description}{" "}
                              </Button>

                              <Button
                                round
                                color="danger"
                                size="sm"
                                onClick={() => this.handleDeleteDocP(id)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Badge>
                          )
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>

                {/* <Input
                  type="select"
                  name="categoryP"
                  id="categoryP"
                  onChange={(e) => this.handleChange(e)}
                >
                  <option style={{ color: "black" }}>{"-"}</option>
                  {categories.map((item) => (
                    <option value={item.namePC}>
                      {item.namePC} {" - "}
                      {item.colorPC}
                    </option>
                  ))}
                </Input> */}
              </GridItem>
              {/* <GridItem xs={1}></GridItem> */}
              <GridItem xs={6} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="success">Οικονομικά Στοιχεία</CardHeader>
                  <CardBody>
                    {" "}
                    {/* <label>
                      {" "}
                      Οικονομικά Στοιχεία{" "} */}
                    {/* </label> */}
                    <br />
                    <label>
                      {" "}
                      <b> Μήκος Πάγκου </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="tetragwnika"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.tetragwnika,
                        placeholder: "Μήκος πάγκου",
                        onChange: (e) => this.handleChangeP1(e),
                        type: "number",
                      }}
                    />{" "}
                    <br />
                    {this.state.activeItem.kathgoria === "επαγγελματίες" && (
                      <>
                        <label>
                          {" "}
                          <b> Τιμή μονάδας </b>{" "}
                        </label>{" "}
                        <br />
                        <CustomInput
                          id="timimonadas"
                          inputProps={{
                            required: true,

                            value: this.state.xrewsiepagk,
                            placeholder: "Τιμή μονάδας",
                            onChange: (e) => this.handleChangeP2(e),
                            type: "number",
                          }}
                        />{" "}
                      </>
                    )}{" "}
                    {this.state.activeItem.kathgoria === "παραγωγοί" && (
                      <>
                        <label>
                          {" "}
                          <b> Τιμή μονάδας </b>{" "}
                        </label>{" "}
                        <br />
                        <CustomInput
                          id="timimonadas"
                          inputProps={{
                            required: true,

                            value: this.state.xrewsiparag,
                            placeholder: "Τιμή μονάδας",
                            onChange: (e) => this.handleChangeP2(e),
                            type: "number",
                          }}
                        />{" "}
                      </>
                    )}{" "}
                    <br />
                    {this.state.activeItem.kathgoria === "επαγγελματίες" && (
                      <>
                        <label>
                          {" "}
                          <b> Κόστος </b>{" "}
                        </label>{" "}
                        <br />
                        <CustomInput
                          id="poso"
                          inputProps={{
                            disabled: true,
                            value: this.state.activeItem.poso,
                            placeholder: "Κόστος",
                            // onChange: (e) => this.handleChange(e),
                            type: "text",
                          }}
                        />
                      </>
                    )}{" "}
                    <br />
                    {this.state.activeItem.kathgoria === "παραγωγοί" && (
                      <>
                        {this.state.activeItem.id && (
                          <>
                            <Button
                              round
                              color="info"
                              size="sm"
                              onClick={() => this.handleNewXrewsi()}
                            >
                              <i className="fa fa-plus"></i>
                            </Button>
                          </>
                        )}
                        {/* <ExcelFile
                          element={
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#4CAF50",
                                borderColor: "#4CAF50",
                                fontSize: "1em",
                                borderRadius: "100px",
                              }}
                            >
                              Κατέβασμα χρεώσεων
                            </button>
                          }
                        >
                          <ExcelSheet data={UdataTablePX} name="All">
                            <ExcelColumn label="Επωνυμία" value="epwn" />
                            <ExcelColumn label="ΑΦΜ" value="afm" />
                            <ExcelColumn
                              label="Διάστημα χρέωσης"
                              value="time"
                            />
                            <ExcelColumn label="Ποσό" value="poso" />
                            <ExcelColumn label="Πλήρωσε" value="plirwse" />
                            <ExcelColumn label="Παρουσίες" value="par" />
                          </ExcelSheet>
                        </ExcelFile> */}
                        <ReactTable
                          data={UdataTablePX}
                          filterable
                          resizable={false}
                          columns={[
                            // {
                            //   Header: "Έναρξη",
                            //   accessor: "enarxiP",
                            // },
                            {
                              Header: "Ημερομηνία",
                              accessor: "date",
                            },
                            {
                              Header: "Μήκος",
                              accessor: "tetragwnika",
                            },

                            // {
                            //   Header: "Λήξη",
                            //   accessor: "lixiP",
                            // },
                            {
                              Header: "Ποσό",
                              accessor: "poso",
                            },
                            // {
                            //   Header: "Παρουσίες",
                            //   accessor: "par",
                            // },
                            // {
                            //   Header: "Πλήρωσε",
                            //   accessor: "plirwse",
                            //   Filter: SelectColumnFilter,
                            //   filter: "includes",
                            // },

                            {
                              Header: "",
                              accessor: "e",
                              disableFilters: true,
                              disableSortBy: true,
                            },
                          ]}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                        />
                      </>
                    )}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="enarxiP"
                    name="enarxiP"
                    showTodayButton
                    required
                    label="Έναρξη"
                    value={this.state.activeItem.enarxiP}
                    onChange={(e) => this.handleChangeEnarxi(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
                    {/* <InputLabel id="lixiP" className={classes.label}>
                  Λήξη
                </InputLabel> */}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="lixeE"
                    name="lixeE"
                    required
                    label="Λήξη"
                    value={this.state.activeItem.lixiP}
                    onChange={(e) => this.handleChangeLixi(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success">Επιπλέον Στοιχεία</CardHeader>
                  <CardBody>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarxiAP"
                        name="enarxiAP"
                        showTodayButton
                        required
                        label="Έναρξη άδειας πάγκου"
                        value={this.state.activeItem.enarxiAP}
                        onChange={(e) => this.handleChangeEnarxiAdeias(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <InputLabel id="lixiP" className={classes.label}>
                  Λήξη
                </InputLabel> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="lixeAP"
                        name="lixeAP"
                        required
                        label="Λήξη άδειας πάγκου"
                        value={this.state.activeItem.lixiAP}
                        onChange={(e) => this.handleChangeLixiAdeias(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <CustomInput
                  id="posoP"
                  inputProps={{
                    value: this.state.activeItem.posoP,
                    placeholder: "Ποσό",
                    onChange: (e) => this.handleChange(e),
                    type: "text",
                  }}
                /> */}
                    {/* {this.state.activeItem.plirwse && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={(e) => this.handleChangePagkosBools(e)}
                        name="plirwse"
                        id="plirwse"
                        color="primary"
                      />
                    }
                    label="Πλήρωσε;"
                  />
                )}
                {!this.state.activeItem.plirwse && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangePagkosBools(e)}
                        name="plirwse"
                        id="plirwse"
                        color="primary"
                      />
                    }
                    label="Πλήρωσε;"
                  />
                )} */}
                    {this.state.activeItem.anastoli && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="anastoli"
                            id="anastoli"
                            color="primary"
                          />
                        }
                        label="Αναστολή άδειας λειτουργίας;"
                      />
                    )}
                    {!this.state.activeItem.anastoli && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="anastoli"
                            id="anastoli"
                            color="primary"
                          />
                        }
                        label="Αναστολή άδειας λειτουργίας;"
                      />
                    )}
                    {this.state.activeItem.adeiaAllouDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaAllouDimou"
                            id="adeiaAllouDimou"
                            color="primary"
                          />
                        }
                        label="Άδεια άλλου Δήμου;"
                      />
                    )}
                    {!this.state.activeItem.adeiaAllouDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaAllouDimou"
                            id="adeiaAllouDimou"
                            color="primary"
                          />
                        }
                        label="Άδεια άλλου Δήμου;"
                      />
                    )}
                    {this.state.activeItem.isinteam && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="isinteam"
                            id="isinteam"
                            color="primary"
                          />
                        }
                        label="Ανήκει στο σωματείο Λαϊκών Αγορών;"
                      />
                    )}
                    {!this.state.activeItem.isinteam && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="isinteam"
                            id="isinteam"
                            color="primary"
                          />
                        }
                        label="Ανήκει στο σωματείο Λαϊκών Αγορών;"
                      />
                    )}
                    {this.state.activeItem.adeiaDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaDimou"
                            id="adeiaDimou"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια του δήμου;"
                      />
                    )}
                    {!this.state.activeItem.adeiaDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaDimou"
                            id="adeiaDimou"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια του δήμου;"
                      />
                    )}
                    {this.state.activeItem.adeiaApokentrwmenis && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaApokentrwmenis"
                            id="adeiaApokentrwmenis"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια της περιφέρειας;"
                      />
                    )}
                    {!this.state.activeItem.adeiaApokentrwmenis && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaApokentrwmenis"
                            id="adeiaApokentrwmenis"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια της περιφέρειας;"
                      />
                    )}
                    {this.state.activeItem.diakopi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="diakopi"
                            id="diakopi"
                            color="primary"
                          />
                        }
                        label="Διακοπή αδειοδότησης;"
                      />
                    )}
                    {!this.state.activeItem.diakopi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="diakopi"
                            id="diakopi"
                            color="primary"
                          />
                        }
                        label="Διακοπή αδειοδότησης;"
                      />
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success">Παρατηρήσεις</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItem.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChange(e),

                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClose()}>
              Άκυρο
            </Button>
            {/* {savedisabled && ( */}
            <Button
              size="sm"
              round
              onClick={() => this.handlePost()}
              color="success"
            >
              Αποθήκευση
            </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalL}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="md"
          onClose={() => this.handleCloseLaiki()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Στοιχεία Λαϊκής
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseLaiki()}
            >
              <Close className={classes.modalLlose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardHeader color="success">Χάρτης</CardHeader>
                  <CardBody>
                    <label>
                      Παρακαλούμε επιλέξτε στον χάρτη το κέντρο της Λαϊκής
                    </label>
                    <MapContainer
                      center={this.state.laikiselectedcenter}
                      zoom={zoom}
                      scrollWheelZoom={false}
                      style={{ height: "50vh", width: "180wh" }}
                    >
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {this.state.activeItemLaiki.coordsLA != undefined && (
                        <Marker position={this.state.activeItemLaiki.coordsLA}>
                          <Tooltip>{this.state.activeItemLaiki.nameLA}</Tooltip>
                        </Marker>
                      )}
                      {/* <MyComponent /> */}
                      <LocationMarkerLaiki />{" "}
                    </MapContainer>{" "}
                  </CardBody>
                </Card>{" "}
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardHeader color="success">Στοιχεία Λαϊκής</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="nameLA"
                      name="nameLA"
                      inputProps={{
                        value: this.state.activeItemLaiki.nameLA,
                        placeholder: "Όνομα Λαϊκής",
                        onChange: (e) => this.handleChangeLaiki(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <CustomInput
                      id="perioxiA"
                      inputProps={{
                        value: this.state.activeItemLaiki.perioxiA,
                        placeholder: "Περιοχή",
                        onChange: (e) => this.handleChangeLaiki(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <FormGroup>
                      {this.state.activeItemLaiki.deytera && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="deytera"
                              id="deytera"
                              color="primary"
                            />
                          }
                          label="Δευτέρα"
                        />
                      )}
                      {!this.state.activeItemLaiki.deytera && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="deytera"
                              id="deytera"
                              color="primary"
                            />
                          }
                          label="Δευτέρα"
                        />
                      )}
                      {this.state.activeItemLaiki.triti && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="triti"
                              id="triti"
                              color="primary"
                            />
                          }
                          label="Τρίτη"
                        />
                      )}
                      {!this.state.activeItemLaiki.triti && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="triti"
                              id="triti"
                              color="primary"
                            />
                          }
                          label="Τρίτη"
                        />
                      )}{" "}
                      {this.state.activeItemLaiki.tetarti && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="tetarti"
                              id="tetarti"
                              color="primary"
                            />
                          }
                          label="Τετάρτη"
                        />
                      )}
                      {!this.state.activeItemLaiki.tetarti && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="tetarti"
                              id="tetarti"
                              color="primary"
                            />
                          }
                          label="Τετάρτη"
                        />
                      )}
                      {this.state.activeItemLaiki.pempti && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="pempti"
                              id="pempti"
                              color="primary"
                            />
                          }
                          label="Πέμπτη"
                        />
                      )}
                      {!this.state.activeItemLaiki.pempti && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="pempti"
                              id="pempti"
                              color="primary"
                            />
                          }
                          label="Πέμπτη"
                        />
                      )}{" "}
                      {this.state.activeItemLaiki.paraskeui && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="paraskeui"
                              id="paraskeui"
                              color="primary"
                            />
                          }
                          label="Παρασκευή"
                        />
                      )}
                      {!this.state.activeItemLaiki.paraskeui && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="paraskeui"
                              id="paraskeui"
                              color="primary"
                            />
                          }
                          label="Παρασκευή"
                        />
                      )}
                      {this.state.activeItemLaiki.savvato && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="savvato"
                              id="savvato"
                              color="primary"
                            />
                          }
                          label="Σάββατο"
                        />
                      )}
                      {!this.state.activeItemLaiki.savvato && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="savvato"
                              id="savvato"
                              color="primary"
                            />
                          }
                          label="Σάββατο"
                        />
                      )}
                      {this.state.activeItemLaiki.kyriaki && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="kyriaki"
                              id="kyriaki"
                              color="primary"
                            />
                          }
                          label="Κυριακή"
                        />
                      )}
                      {!this.state.activeItemLaiki.kyriaki && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => this.handleChangeLaikiMeres(e)}
                              name="kyriaki"
                              id="kyriaki"
                              color="primary"
                            />
                          }
                          label="Κυριακή"
                        />
                      )}
                    </FormGroup>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseLaiki()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostLaiki()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalPX}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseX()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}> Στοιχεία Πάγκου Λαϊκής</h4>
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseX()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs="6">
                <Card>
                  <CardHeader>Στοιχεία Πάγκου</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="pagkos"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.pagkos,
                        placeholder: "Πάγκος",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />

                    <CustomInput
                      id="kathgoria"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.kathgoria,
                        placeholder: "Κατηγορία",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="tetragwnika"
                      inputProps={{
                        value: this.state.activeItemXrewsi.tetragwnika,
                        placeholder: "Μήκος Πάγκου",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="timimonadas"
                      inputProps={{
                        value: this.state.activeItemXrewsi.timimonadas,
                        placeholder: "Τιμή μονάδας",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs="6">
                <Card>
                  <CardHeader>Στοιχεία Χρέωσης</CardHeader>
                  <CardBody>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarxiP"
                        name="enarxiP"
                        showTodayButton
                        required
                        label="Έναρξη"
                        value={this.state.activeItemXrewsi.enarxiP}
                        onChange={(e) => this.handleChangeEnarxiXrewsi(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="lixeE"
                        name="lixeE"
                        required
                        label="Λήξη"
                        value={this.state.activeItemXrewsi.lixiP}
                        onChange={(e) => this.handleChangeLixiXrewsi(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <CustomInput
                      id="posoP"
                      inputProps={{
                        value: this.state.activeItemXrewsi.poso,
                        placeholder: "Ποσό",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                    {this.state.activeItemXrewsi.plirwse && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) =>
                              this.handleChangePagkosBoolsXrewsi(e)
                            }
                            name="plirwse"
                            id="plirwse"
                            color="primary"
                          />
                        }
                        label="Πλήρωσε;"
                      />
                    )}
                    {!this.state.activeItemXrewsi.plirwse && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              this.handleChangePagkosBoolsXrewsi(e)
                            }
                            name="plirwse"
                            id="plirwse"
                            color="primary"
                          />
                        }
                        label="Πλήρωσε;"
                      />
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseX()}>
              Άκυρο
            </Button>
            {/* {savedisabled && ( */}
            <Button
              size="sm"
              round
              onClick={() => this.handlePostXrewsi()}
              color="success"
            >
              Αποθήκευση
            </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalXR}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseXr()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              Στοιχεία Τιμών Χρεώσεων Πάγκων
            </h4>
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseXr()}
            >
              <Close className={classes.modalCloseXr} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs="6">
                <Card>
                  <CardHeader>Στοιχεία Χρεώσης</CardHeader>
                  <CardBody>
                    <label> Ποσό για επαγγελματίες</label> <br />
                    <CustomInput
                      id="timeEp"
                      inputProps={{
                        value: this.state.activeItemPagkoiXrewsi.timeEp,
                        placeholder: "Ποσό",
                        onChange: (e) => this.handleChangePXrewseis(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label> Ποσό για παραγωγούς</label>
                    <br />
                    <CustomInput
                      id="timePar"
                      inputProps={{
                        value: this.state.activeItemPagkoiXrewsi.timePar,
                        placeholder: "Ποσό",
                        onChange: (e) => this.handleChangePXrewseis(e),
                        type: "text",
                      }}
                    />
                    <br />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseXr()}>
              Άκυρο
            </Button>
            {/* {savedisabled && ( */}
            <Button
              size="sm"
              round
              onClick={() => this.handlePostXrewseis()}
              color="success"
            >
              Αποθήκευση
            </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

class CategoryPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      LaikesList: [],
      LaikesSelectList: [],
      markers: [[0, 0]],
      EpixeiriseisCategoryList: [],
      PagkosCategoryList: [],
      activeItemEC: {},
      activeItemE: {},
      modalE: false,
      modalEC: false,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    if (!userParser("Admin") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }

    this.refreshListCategory();
  }

  refreshListCategory() {
    // axios
    //   .get("http://127.0.0.1:8000/api/EpixeirisiCategory/")
    session
      .get("api/EpixeirisiCategory/")
      .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/PagkosCategory/")
    session
      .get("api/PagkosCategory/")
      .then((res) => this.setState({ PagkosCategoryList: res.data }))
      .catch((err) => console.log(err));
  }

  handleNewEC = () => {
    // // console.log(item);
    this.state.activeItemEC.nameEC = "";
    this.state.activeItemEC.logo = "";
    // this.state.activeItemE = item;
    this.setState({ modalEC: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemEC);
    // this.render();
  };

  handleCloseEC = () => {
    this.state.activeItemEC = [];
    this.setState({ activeItemEC: [] });
    this.setState({ modalEC: false });
  };

  handleDeletePC = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/PagkosCategory/${item.id}`)
    session
      .delete(`api/PagkosCategory/${item.id}`)
      .then(this.refreshListCategory());
    this.refreshListCategory();
    this.setState({ state: this.state });
  };

  handleDeleteEC = (item) => {
    // axios
    //   .delete(
    //     `http://127.0.0.1:8000/api/EpixeirisiCategory/${item.id}`
    //   )
    session
      .delete(`api/EpixeirisiCategory/${item.id}`)
      .then(this.refreshListCategory());
    this.refreshListCategory();
    this.setState({ state: this.state });
  };

  render() {
    const classes = this.state.useStyles;
    const Epcategories = this.state.EpixeiriseisCategoryList;
    const Pacategories = this.state.PagkosCategoryList;

    UdataTablePoC = [];
    for (var i = 0; i < Pacategories.length; i++) {
      const dataRow = {};
      const item = Pacategories[i];

      dataRow.onoma = Pacategories[i].namePC;

      dataRow.eidos = <Badge color={item.description}>{item.colorPC}</Badge>;
      dataRow.e = (
        <>
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeletePC(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      UdataTablePoC.push(dataRow);
    }

    UdataTableEC = [];

    for (var i = 0; i < Epcategories.length; i++) {
      const dataRow = {};
      const item = Epcategories[i];

      dataRow.onoma = Epcategories[i].nameEC;

      dataRow.eidos = <img style={{ width: "25px" }} src={item.logo} />;

      dataRow.e = (
        <>
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeleteEC(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      UdataTableEC.push(dataRow);
    }

    return (
      <>
        <GridContainer>
          <GridItem xs={6}>
            <Card>
              <CardHeader color="success">Κατηγορίας Πάγκου</CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTablePoC}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Όνομα",
                      accessor: "onoma",
                    },
                    {
                      Header: "Είδος",
                      accessor: "eidos",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
                {/* 
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Όνομα</TableCell>
                        <TableCell>Είδος</TableCell>
                        <TableCell>Ενέργειες</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Pacategories.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            {item.namePC}
                          </TableCell>

                          <TableCell>
                            {" "}
                            <Badge color={item.description}>
                              {item.colorPC}
                            </Badge>
                          </TableCell>

                          <TableCell>
                            {/* <Button
                          round
                          color="info"
                          size="sm"
                          // onClick={() => this.handleEditE(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>{" "} 
                              round
                              color="danger"
                              size="sm"
                              onClick={() => this.handleDeletePC(item)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </CardBody>
            </Card>
            {/* <GridContainer direction="row" justify="center" alignItems="center">
              <h3>
                {" "}
                {"Κατηγορίας Πάγκου"} */}
            {/* <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewEC()}
                >
                  <i className="fa fa-plus"></i>
                </Button>{" "} */}
            {/* </h3>
            </GridContainer> */}
          </GridItem>

          <GridItem xs={6}>
            <Card>
              <CardHeader color="success">
                Κατηγορίας Επιχείρησης{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewEC()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTableEC}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Όνομα",
                      accessor: "onoma",
                    },
                    {
                      Header: "Είδος",
                      accessor: "eidos",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
                {/* <GridContainer direction="row" justify="center" alignItems="center">
              <h3>
                {" "}
                {" Κατηγορίας Επιχείρησης"}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewEC()}
                >
                  <i className="fa fa-plus"></i>
                </Button>{" "}
              </h3>
            </GridContainer> */}
                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Όνομα</TableCell>
                        <TableCell>Είδος</TableCell>
                        <TableCell>Ενέργειες</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Epcategories.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            {item.nameEC}
                          </TableCell>

                          <TableCell>
                            <img style={{ width: "25px" }} src={item.logo} />{" "}
                          </TableCell>

                          {/* <TableCell>{item.colorPC}</TableCell>                      */}
                {/* <TableCell> */}
                {/* <Button
                          round
                          color="info"
                          size="sm"
                          // onClick={() => this.handleEditE(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>{" "} 
                            <Button
                              round
                              color="danger"
                              size="sm"
                              onClick={() => this.handleDeleteEC(item)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalEC}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseEC()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseEC()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="nameEC"
                  inputProps={{
                    value: this.state.activeItemEC.nameEC,
                    placeholder: "Όνομα",
                    onChange: (e) => this.handleChangeEC(e),
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <Input
                  accept="image/*"
                  // className={classes.input}
                  id="post-image"
                  onChange={(e) => this.handleChangeLogo(e)}
                  name="image"
                  type="file"
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseEC()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => setlogo(this.state.activeItemEC)}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

class UsersPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      UsersList: [],
      GroupList: [],
      isnew: 0,
      activeItemUser: {
        groupnames: [],
      },
      modalU: false,
      groups: [],
      passwordFieldActivity: true,
    };
  }

  componentDidMount() {
    this.refreshListUsers();
  }

  refreshListUsers() {
    // axios
    //   .get("http://127.0.0.1:8000/api/users/")
    session
      .get("api/users/")
      .then((res) => this.setState({ UsersList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/groups/")
    session
      .get("api/groups/")
      .then((res) => this.setState({ GroupList: res.data }))
      .catch((err) => console.log(err));
  }

  handleMultiSelectChange2 = (e) => {
    const options = e.target.value;
    const value = [];
    const valueF = [];

    // console.log(e.target.value);

    for (let i = 0, l = options.length; i < l; i += 1) {
      // console.log(options[i]);

      if (options[i] != undefined) {
        // console.log(options[i].length);
        // console.log(options[i].constructor);

        if (!String(options[i].constructor).includes("Array")) {
          value.push(options[i]);
        } else {
          const tmp = options[i];
          // console.log(tmp);

          for (var j = 0; j < tmp.length; j++) {
            value.push(tmp[j]);
          }
        }
      }
    }

    // console.log(value);

    const uniqueNames = Array.from(new Set(value));

    // console.log(uniqueNames);

    // for (var i = 0; i < uniqueNames.length; i++) {
    //   const tmpgroups = this.state.GroupList.filter(
    //     (itemG) => String(itemG.name) == uniqueNames[i]
    //   );

    //   valueF.push(tmpgroups[0].id);
    // }

    // console.log(valueF);

    const name = "groupnames";
    const activeItemUser = {
      ...this.state.activeItemUser,
      groupnames: uniqueNames,
    };

    // console.log(activeItemUser);
    this.setState({ activeItemUser });

    this.setState({ state: this.state });
  };

  handleMultiSelectChange = (e) => {
    const name = "groups";
    const value = [e.target.value];
    const activeItemUser = { ...this.state.activeItemUser, [name]: value };

    // console.log(activeItemUser);
    this.setState({ activeItemUser });

    this.setState({ state: this.state });

    this.render();

    // // console.log(e);
    // const selectedvalue = e;

    // for (var i = 0; i < selectedvalue.length; i++) {
    //   // console.log(selectedvalue[i].value);
    //   const ids = [];
    //   ids.push(selectedvalue[i].value);
    //   this.state.activeItem.laikiAgoraId = ids;
    // }
  };

  handle_signup = (e, data) => {
    // console.log(data);

    if (this.state.activeItemUser.id) {
      const newItem = this.state.activeItemUser;

      // console.log(newItem);
      let valueF = [];

      for (var i = 0; i < newItem.groupnames.length; i++) {
        const tmpgroups = this.state.GroupList.filter(
          (itemG) => String(itemG.name) == newItem.groupnames[i]
        );

        valueF.push(tmpgroups[0].id);
      }

      // console.log(valueF);

      newItem.groups = valueF;

      // const sign = require("jwt-encode");
      // const secret = "626$&9s))i*v_6jnb(nxneo5p)n^#=0jet5(y1!#w0fp$5dj-b";
      // const data = {
      //   password: newItem.password,
      // };
      // const jwt = sign(data, secret);

      // newItem.password = jwt;

      // axios
      //   .put(`http://127.0.0.1:8000/api/users/${newItem.id}/`, newItem)
      session
        .put(`api/users/${newItem.id}/`, newItem)
        .then(this.refreshListUsers());
    } else {
      // console.log(this.state.activeItemUser, "Active ItemUser");
      // e.preventDefault();
      session
        .post("tests/users/", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        // fetch("http://127.0.0.1:8000/tests/users/", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(this.state.activeItemUser),
        // })
        .then(this.refreshListUsers())
        .catch((error) => {
          alert(error.response.data);
        });

      window.location.reload();
    }

    this.refreshListUsers();
    this.setState({ state: this.state });
    this.handleCloseU();
    this.render();
    this.render();

    // window.location.reload();
  };

  handleNewU = () => {
    // // console.log(item);
    this.state.activeItemUser.username = "";
    this.state.activeItemUser.password = "";
    this.state.activeItemUser.first_name = "";
    this.state.activeItemUser.last_name = "";
    this.state.activeItemUser.email = "";

    this.state.isnew = 1;
    // this.state.activeItemE = item;
    this.setState({ modalU: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemUser);
    // this.render();
  };

  handleChangeU = (e) => {
    const { name, value } = e.target;

    const activeItemUser = { ...this.state.activeItemUser, [name]: value };
    // console.log(this.state.activeItemUser);
    this.setState({ activeItemUser });
  };

  handleCloseU = () => {
    this.state.activeItemUser = [];
    this.setState({ activeItemUser: [] });
    this.setState({ modalU: false });
  };

  handleDeleteU = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/users/${item.id}`)
    session.delete(`api/users/${item.id}`).then(this.refreshListUsers());
    this.refreshListUsers();
    this.setState({ state: this.state });
    this.refreshListUsers();
    this.setState({ state: this.state });
    this.handleCloseU();
    this.render();
  };

  handleEditUser = (item) => {
    this.state.isnew = 0;

    this.state.activeItemUser = item;

    let group = [];

    const tmpgroups = this.state.GroupList.filter((itemG) =>
      String(item.groups).includes(String(itemG.id))
    );

    for (var j = 0; j < tmpgroups.length; j++) {
      group.push(tmpgroups[j].name);
    }

    this.state.activeItemUser.groupnames = group;

    // console.log(this.state.activeItemUser);
    // this.state.activeItem = item;
    this.setState({ modalU: true });
    // // console.log(this.state.modalP);
    // // console.log(this.state.activeItemLaiki);
    // this.render();
  };

  passwordFieldHandle = () => {
    this.setState({ passwordFieldActivity: false });
    console.log("clicked");
  };

  render() {
    const classes = this.state.useStyles;
    const Users = this.state.UsersList;
    const Groups = this.state.GroupList.filter((item) => item.id != 1);

    UdataTableU = [];

    for (var i = 0; i < Users.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      const name = Users[i].username;
      const item = Users[i];
      let group = "";

      const tmpgroups = this.state.GroupList.filter((itemG) =>
        String(item.groups).includes(String(itemG.id))
      );

      // console.log(tmpgroups);

      for (var j = 0; j < tmpgroups.length; j++) {
        group += tmpgroups[j].name + " - ";
      }

      // if (item.groups == 1) {
      //   group = "Superuser";
      // }
      // if (item.groups == 4) {
      //   group = "Διαχειριστής ανακοινώσεων";
      // }
      // if (item.groups == 5) {
      //   group = "Ελεγκτής";
      // }
      // if (item.groups == 6) {
      //   group = "Admin";
      // }

      dataRow.username = name;
      dataRow.fullname = Users[i].first_name + " " + Users[i].last_name;
      dataRow.email = Users[i].email;
      dataRow.group = group;

      dataRow.e = (
        <>
          {" "}
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditUser(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          {(localStorage.getItem("isSuper") != true ||
            !localStorage.getItem("userselectedname") == item.username) && (
            <Button
              round
              color="danger"
              size="sm"
              onClick={() => this.handleDeleteU(item)}
            >
              <i className="fa fa-times"></i>
            </Button>
          )}
        </>
      );

      UdataTableU.push(dataRow);
    }

    grouptable = [];

    const names = [];

    for (var i = 0; i < Groups.length; i++) {
      const dataRow = {};
      const item = Groups[i];

      // console.log(item);

      dataRow.value = String(item.id);
      dataRow.label = item.name;

      grouptable.push(dataRow);
      names.push(item.name);
    }

    // console.log(this.state.activeItemUser.groupnames);

    return (
      <>
        <GridContainer>
          <GridItem>
            <Card>
              <CardHeader color="success">
                Χρήστες{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewU()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTableU}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Username",
                      accessor: "username",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Ονοματεπώνυμο",
                      accessor: "fullname",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Group",
                      accessor: "group",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />

                {/* 
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Ονοματεπώνυμο</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Ενέργειες</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Users.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.username}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.first_name} {item.last_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.groups == 1 && "Superuser"}
                        {item.groups == 4 && "Admin"}
                        {item.groups == 5 && "Προβολή"}
                        {item.groups == 6 && "Ανάρτηση Νέων"}
                      </TableCell>

                      <TableCell>
                        <Button
                          round
                          color="info"
                          size="sm"
                          onClick={() => this.handleEditUser(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>{" "}
                        {(item.groups != 1 ||
                          !localStorage.getItem("userselectedname") ==
                            item.username) && (
                          <Button
                            round
                            color="danger"
                            size="sm"
                            onClick={() => this.handleDeleteU(item)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalU}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseU()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseU()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="username"
                  inputProps={{
                    value: this.state.activeItemUser.username,
                    placeholder: "username",
                    id: "username",
                    name: "username",
                    onChange: (e) => this.handleChangeU(e),
                    type: "text",
                  }}
                />

                {this.state.isnew == 0 && (
                  <>
                    <CustomInput
                      id="first_name"
                      inputProps={{
                        value: this.state.activeItemUser.first_name,
                        placeholder: "first_name",
                        id: "first_name",
                        name: "first_name",
                        onChange: (e) => this.handleChangeU(e),
                        type: "text",
                      }}
                    />

                    <CustomInput
                      id="email"
                      inputProps={{
                        value: this.state.activeItemUser.email,
                        placeholder: "email",
                        id: "email",
                        name: "email",
                        onChange: (e) => this.handleChangeU(e),
                        type: "text",
                      }}
                    />

                    {this.state.activeItemUser.groups != 1 && (
                      <>
                        {/* <label>Επιλογή Group</label>
                    {Groups.length != 0 && (
                      <Select
                        labelId="demo-simple-select-label"
                        name="groups"
                        id="groups"
                        value={String(this.state.activeItemUser.groups)}
                        onChange={(e) => this.handleMultiSelectChange(e)}
                        style={{ fontSize: "14px" }}
                      >
                        {Groups.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    )}
                    <br />
                    <br /> */}
                        <br />
                        <br />
                        {this.state.activeItemUser.groupnames != undefined && (
                          <>
                            {names.length != 0 && (
                              <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={this.state.activeItemUser.groupnames}
                                onChange={(e) =>
                                  this.handleMultiSelectChange2(e)
                                }
                                input={<Input />}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  id="password"
                  inputProps={{
                    value: this.state.activeItemUser.password,
                    placeholder: "password",
                    id: "password",
                    name: "password",
                    onChange: (e) => this.handleChangeU(e),
                    type: "password",
                    // disabled:true
                    // disabled:this.state.passwordFieldActivity,
                  }}
                />
                {this.state.isnew == 0 && (
                  <CustomInput
                    id="last_name"
                    inputProps={{
                      value: this.state.activeItemUser.last_name,
                      placeholder: "last_name",
                      id: "last_name",
                      name: "last_name",
                      onChange: (e) => this.handleChangeU(e),
                      type: "text",
                    }}
                  />
                )}
                <br />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseU()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handle_signup(this.state.activeItemUser)}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

class PostPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      PostList: [],
      PostCatList: [],
      activeItemPost: {},
      modalPoc: false,
      activeItemPoc: {},
      modalPost: false,
      lastname: "",
      postdocitem: [],
      postdocsList: [],
      selectedPostDocs: [],
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListPostDocs();
  }

  refreshList() {
    // axios
    //   .get("http://127.0.0.1:8000/api/Post/")
    session
      .get("api/Post/")
      .then((res) => this.setState({ PostList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/PostCategory/")
    session
      .get("api/PostCategory/")
      .then((res) => this.setState({ PostCatList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListPostDocs() {
    // axios
    //   .get("http://127.0.0.1:8000/api/FileFieldFormPost/")
    session
      .get("api/FileFieldFormPost/")
      .then((res) => this.setState({ postdocsList: res.data }))
      .catch((err) => console.log(err));
  }

  handleNewPost = () => {
    // // console.log(item);
    this.state.activeItemPost.title = "";
    this.state.activeItemPost.slug = "";
    this.state.activeItemPost.content = "";
    this.state.activeItemPost.status = "";
    this.state.activeItemPost.category = "";
    this.state.activeItemPost.author = "";

    // this.state.activeItemE = item;
    this.setState({ modalPost: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemPost);
    // this.render();
  };

  handleClosePost = () => {
    this.state.activeItemPost = [];
    this.setState({ activeItemPost: [] });
    this.setState({ modalPost: false });
  };

  handleNewPoC = () => {
    // // console.log(item);
    this.state.activeItemPoc.namePoC = "";
    // this.state.activeItemE = item;
    this.setState({ modalPoc: true });
    // // console.log(this.state.modalE);
    // this.render();
  };

  handleEditPost = (item) => {
    // // console.log(item);
    this.state.activeItemPost = item;
    // this.state.activeItemE = item;

    this.state.selectedPostDocs = this.state.postdocsList.filter(
      (itemS) => itemS.postId === item.title
    );

    // // console.log(this.state.postdocsList);
    // // console.log(this.state.selectedPostDocs);

    this.setState({ modalPost: true });
    // // console.log(this.state.modalE);
    // this.render();
  };

  handleEditPoC = (item) => {
    // // console.log(item);
    this.state.activeItemPoc = item;
    // this.state.activeItemE = item;
    this.setState({ modalPoc: true });
    // // console.log(this.state.modalE);
    // this.render();
  };

  handleChangePoc = (e) => {
    this.state.lastname = this.state.activeItemPoc.namePoC;
    const name = e.target.id;
    const value = e.target.value;
    const activeItemPoc = { ...this.state.activeItemPoc, [name]: value };
    // formData.append([name], value);
    // console.log("ok");
    this.setState({ activeItemPoc });
  };

  handleChangePost = (e) => {
    // this.state.lastname = this.state.activeItemPoc.namePoC;
    let name = "";
    if (e.target.name) {
      name = e.target.name;
    } else {
      name = e.target.id;
    }
    const value = e.target.value;
    const activeItemPost = { ...this.state.activeItemPost, [name]: value };
    // formData.append([name], value);
    // // console.log("ok");
    this.setState({ activeItemPost });

    // console.log(activeItemPost);
  };

  handlePostPoC = () => {
    const newItem = this.state.activeItemPoc;

    if (newItem.id) {
      // const tmpPosts = this.state.PostList.filter( (item) =>
      // String(item.category) === this.state.last)

      // axios
      //   .put(
      //     `http://127.0.0.1:8000/api/PostCategory/${newItem.id}/`,
      //     newItem
      //   )
      session
        .put(`api/PostCategory/${newItem.id}/`, newItem)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("http://127.0.0.1:8000/api/PostCategory/", newItem)
      session.post("api/PostCategory/", newItem).then(this.refreshList());
    }

    this.state.activeItemPoc = [];
    this.refreshList();
    this.handleClosePoC();
    // newE = true;
  };

  handleChangePostDoc = (e) => {
    var files = e.target.files[0];
    // console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.postdocitem.file_field = e.target.files[0];
    this.state.postdocitem.pagkos = this.state.activeItemPost.title;
    this.state.postdocitem.description = fname[0];
  };

  handlePostPost = () => {
    const newItem = this.state.activeItemPost;

    newItem.slug = newItem.title;
    newItem.author = localStorage.getItem("userselectedname");

    if (newItem.id) {
      // const tmpPosts = this.state.PostList.filter( (item) =>
      // String(item.category) === this.state.last)

      // console.log(newItem);

      this.state.postdocitem.postId = newItem.title;

      setPostDoc(this.state.postdocitem);

      // axios
      //   .put(`http://127.0.0.1:8000/api/Post/${newItem.id}/`, newItem)
      session
        .put(`api/Post/${newItem.id}/`, newItem)
        .then(this.refreshList())
        .catch((err) => console.log(err));
    } else {
      // axios
      //   .post("http://127.0.0.1:8000/api/Post/", newItem)
      session
        .post("api/Post/", newItem)
        .then(this.refreshList())
        .catch((err) => console.log(err));

      this.state.postdocitem.postId = newItem.title;

      setPostDoc(this.state.postdocitem);
    }

    this.state.activeItemPost = [];
    this.refreshList();
    this.refreshListPostDocs();
    this.handleClosePost();
    this.setState({ state: this.state });
    // newE = true;
  };

  handleClosePoC = () => {
    this.state.activeItemPoc = [];
    this.setState({ activeItemPoc: [] });
    this.setState({ modalPoc: false });
  };

  handleDeletePoC = (item) => {
    // console.log(item);
    // axios
    //   .delete(`http://127.0.0.1:8000/api/PostCategory/${item.id}/`)
    session.delete(`api/PostCategory/${item.id}/`).then(this.refreshList());
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleDeletePost = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/Post/${item.id}`)
    session.delete(`api/Post/${item.id}`).then(this.refreshList());
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleDeleteDocPo = (id) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/FileFieldFormPost/${id}`)
    session
      .delete(`api/FileFieldFormPost/${id}`)
      .then(this.refreshListPostDocs());
    this.refreshList();
    this.refreshListPostDocs();
    this.setState({ state: this.state });
    this.setState({ modalPost: false });
    this.render();
    document.getElementById("file_field").value = null;
    this.postselected = "";
    // window.location.reload(false);
    // this.setState({ modalP: true });

    this.refreshListPostDocs();
    this.setState({ modalP: false });

    this.state.postselected = "-";

    this.yourFunctionPo();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunctionPo() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    // console.log("Waited 5s");
    this.handleEditPost(this.state.activeItemPost);
    this.setState({ modalPost: false });
    await delay(1000);
    // console.log("Waited 5s");
    this.handleEditPost(this.state.activeItemPost);
  }

  render() {
    const classes = this.state.useStyles;
    const posts = this.state.PostList;
    const postsCat = this.state.PostCatList;

    for (var i = 0; i < posts.length; i++) {
      const tmpdocs = this.state.postdocsList.filter(
        (itemS) => itemS.postId === posts[i].title
      );

      if (tmpdocs[0] != undefined) {
        // console.log(tmpdocs);
        posts[i].image = String(tmpdocs[0].file_field);
      }
    }

    UdataTablePocat = [];

    for (var i = 0; i < postsCat.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      const name = postsCat[i].namePoC;
      const item = postsCat[i];
      dataRow.name = name;
      dataRow.e = (
        <Button
          round
          color="danger"
          size="sm"
          onClick={() => this.handleDeletePoC(item)}
        >
          <i className="fa fa-times"></i>
        </Button>
      );
      // tmpItemsLaikes[i].nameLA;
      // dataRow.perioxi = tmpItemsLaikes[i].perioxiA;

      UdataTablePocat.push(dataRow);
    }

    UdataTablePo = [];

    for (var i = 0; i < posts.length; i++) {
      const dataRow = {};
      const id = posts[i].id;
      const nameP = posts[i].title;
      const item = posts[i];
      let category = "";
      let category2 = "";

      if (posts[i].status == 0) {
        category2 = <Badge color="warning">{"Πρόχειρο"}</Badge>;
      } else {
        category2 = <Badge color="success">{"Δημοσιευμένο"}</Badge>;
      }

      if (posts[i].status == 0) {
        category = "Πρόχειρο";
      } else {
        category = "Δημοσιευμένο";
      }

      dataRow.name = nameP;
      dataRow.e = (
        <>
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditPost(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeletePost(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.category = posts[i].category;

      // dataRow.date = posts[i].created_on.split("T")[0];

      let tmp = posts[i].created_on.split("T")[0];
      let tmp2 = tmp.split("-");
      dataRow.date = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      dataRow.content = posts[i].content;
      dataRow.user = posts[i].author;
      dataRow.katastasi = category;

      //   <TableCell>
      //   {item.status == 0 && (
      //     <Badge color="warning">{"Πρόχειρο"}</Badge>
      //   )}
      //   {item.status == 1 && (
      //     <Badge color="success">{"Δημοσιευμένο"}</Badge>
      //   )}
      // </TableCell>

      UdataTablePo.push(dataRow);
    }

    // console.log(posts);

    return (
      <>
        <GridContainer>
          <GridItem xs={9}>
            <Card>
              <CardBody>
                <CardHeader color="success">
                  Post{" "}
                  <Button
                    round
                    color="info"
                    size="sm"
                    onClick={() => this.handleNewPost()}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </CardHeader>

                <ReactTable
                  data={UdataTablePo}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Τίτλος",
                      accessor: "name",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Περιεχόμενο",
                      accessor: "content",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Κατηγορία",
                      accessor: "category",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Συγγραφέας",
                      accessor: "user",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Κατάσταση",
                      accessor: "katastasi",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ημερομηνία",
                      accessor: "date",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: " ",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>

            {/* <GridContainer direction="row" justify="center" alignItems="center">
              <h3>
                {" "}
                {"Posts"}{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewPost()}
                >
                  <i className="fa fa-plus"></i>
                </Button>{" "}
              </h3>
            </GridContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Τίτλος</TableCell>
                    <TableCell>Περιεχόμενο</TableCell>
                    <TableCell>Κατηγορία</TableCell>
                    <TableCell>Συγγραφέας</TableCell>
                    <TableCell>Κατάσταση</TableCell>
                    <TableCell>Ημερομηνία Δημιουργίας</TableCell>
                    <TableCell>Ενέργειες</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {posts.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.title}
                      </TableCell>

                      <TableCell>
                        {item.content} {" -- "}
                        {!String(item.image).includes("pdf") && (
                          <img style={{ width: "100px" }} src={item.image} />
                        )}{" "}
                        {String(item.image).includes("pdf") && (
                          <Button
                            size="sm"
                            round
                            onClick={() => window.open(item.image, "_blank")}
                          >
                            {" "}
                            {item.title}{" "}
                          </Button>
                        )}{" "}
                      </TableCell>

                      <TableCell>{item.category}</TableCell>

                      <TableCell>{item.author}</TableCell>

                      <TableCell>
                        {item.status == 0 && (
                          <Badge color="warning">{"Πρόχειρο"}</Badge>
                        )}
                        {item.status == 1 && (
                          <Badge color="success">{"Δημοσιευμένο"}</Badge>
                        )}
                      </TableCell>

                      <TableCell>{item.created_on}</TableCell>

                      <TableCell>
                        <Button
                          round
                          color="info"
                          size="sm"
                          onClick={() => this.handleEditPost(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>{" "}
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.handleDeletePost(item)}
                        >
                          <i className="fa fa-times"></i>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </GridItem>

          <GridItem xs={3}>
            <Card>
              <CardBody>
                <CardHeader color="success">
                  Κατηγορίες{" "}
                  <Button
                    round
                    color="info"
                    size="sm"
                    onClick={() => this.handleNewPoC()}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </CardHeader>
                <ReactTable
                  data={UdataTablePocat}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Όνομα",
                      accessor: "name",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Ενέργεια",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>

            {/* <GridContainer direction="row" justify="center" alignItems="center">
              <h3>
                {" "}
                {"Posts Categories"}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewPoC()}
                >
                  <i className="fa fa-plus"></i>
                </Button>{" "}
              </h3>
            </GridContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Id c</TableCell> 
                    <TableCell>Όνομα</TableCell>
                    <TableCell>Ενέργεια</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postsCat.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.namePoC}
                      </TableCell>
                      {/* 
                      <TableCell>{item.namePoC}</TableCell> 

                      <TableCell>
                        {/* <Button
                          round
                          color="info"
                          size="sm"
                          onClick={() => this.handleEditPoC(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>{" "} *
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.handleDeletePoC(item)}
                        >
                          <i className="fa fa-times"></i>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modalPoc,
          }}
          open={this.state.modalPoc}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleClosePoC()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePoC()}
            >
              <Close className={classes.modalClosePoc} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="namePoC"
                  inputProps={{
                    value: this.state.activeItemPoc.namePoC,
                    placeholder: "Όνομα",
                    onChange: (e) => this.handleChangePoc(e),
                    type: "text",
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePoC()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostPoC()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modalPost,
          }}
          open={this.state.modalPost}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="sm"
          onClose={() => this.handleClosePost()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePost()}
            >
              <Close className={classes.modalClosePoc} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color="success">Στοιχεία</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="title"
                      name="title"
                      inputProps={{
                        value: this.state.activeItemPost.title,
                        placeholder: "Τίτλος",
                        onChange: (e) => this.handleChangePost(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <CustomInput
                      id="content"
                      name="content"
                      inputProps={{
                        value: this.state.activeItemPost.content,
                        placeholder: "Κείμενο",
                        maxlength: "5",
                        onChange: (e) => this.handleChangePost(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label> Κατηγορία </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="category"
                      id="category"
                      value={String(this.state.activeItemPost.category)}
                      onChange={(e) => this.handleChangePost(e)}
                    >
                      {postsCat.map((item) => (
                        <MenuItem value={item.namePoC}>{item.namePoC}</MenuItem>
                      ))}
                    </Select>
                    <br></br>
                    <br></br>
                    <label> Κατάσταση </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="status"
                      id="status"
                      value={parseInt(this.state.activeItemPost.status)}
                      onChange={(e) => this.handleChangePost(e)}
                    >
                      <MenuItem value={0}>Πρόχειρο</MenuItem>

                      <MenuItem value={1}>Δημοσιευμένο</MenuItem>
                    </Select>
                    <br />
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        Αρχείο
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangePostDoc(e)}
                        name="file_field"
                        type="file"
                      />
                    </FormGroup>
                    <label> Διαθέσιμα αρχεία</label>
                    {this.state.selectedPostDocs.map(
                      ({ id, file_field, description }) => (
                        <Badge>
                          <Button
                            size="sm"
                            round
                            onClick={() => window.open(file_field, "_blank")}
                          >
                            {" "}
                            {description}{" "}
                          </Button>
                          <Button
                            round
                            color="danger"
                            size="sm"
                            onClick={() => this.handleDeleteDocPo(id)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </Badge>
                      )
                    )}{" "}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePost()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostPost()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
