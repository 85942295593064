import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { el } from "date-fns/locale";
import Input from '@mui/material/Input';
import grLocale from 'date-fns/locale/el';
import session from '../api/session'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';




import { TimePicker } from '@mui/x-date-pickers/TimePicker';





const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function CreateBusinessModal(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const [buisnessName, setBuisnessName] = React.useState('')
    const [owner, setOwner] = React.useState('')
    const [afm, setAfm] = React.useState('')
    const [site, setSite] = React.useState('')
    const [address, setAddress] = React.useState('')
    const [folderNumber, setFolderNumber] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [notes, setNotes] = React.useState('')
    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);
    const [alertShow, setAlertShow] = React.useState(false)
    const [selectedFile, setSelectedFile] = React.useState(null);



    const isFormValid = () => {
        if ([buisnessName, owner, address, category].map(e => e == "").filter(o => o == true).length > 0) {
            setAlertShow(true)
            return false
        } else {
            setAlertShow(false)
            return true
        }
    }

    const saveDialog = () => {

        if (isFormValid()) {
            const today = new Date()
            session.post('api/Epixeiriseis/', {
                nameE: buisnessName,
                afmE: afm,
                iconeE: category,
                userIdE: owner,
                siteE: site,
                dieuthinsi: address,
                paratiriseis: notes,
                arfak: folderNumber,
                wrarioE: startTime != null ? startTime.toLocaleTimeString('el', { hour12: false }) : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0).toLocaleTimeString('el', { hour12: false }),
                wrarioL: endTime != null ? endTime.toLocaleTimeString('el', { hour12: false }) : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 16, 0, 0).toLocaleTimeString('el', { hour12: false })
            }).then(() => {
                props.createBusinessEnd()
                setTimeout(() => {
                    let data = new FormData()

                    data.append('file_field', selectedFile)
                    data.append('epixeirisi', buisnessName)
                    session.post(`api/FileFieldFormEpixeirisi/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                }, 2000);

            })
            setOpen(false)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setAlertShow(false)
    }

    return (
        <div>
            <Button
                onClick={handleOpen}
                startIcon={<AddBusinessIcon />}
                variant="contained"
                sx={{
                    width: "fit-content",
                    height: "78%",
                    textTransform: "none",
                    bgcolor: "#169ab0",
                }}
            >
                Δημιουργία Επιχείρησης
            </Button>
            <Dialog

                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'l'}
            >

                <DialogTitle style={{ textAlign: "center" }}>Δημιουργία Επιχείρησης</DialogTitle>
                {alertShow &&
                    <Alert severity="error">Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία!</Alert>
                }

                <DialogContent style={{ textAlign: "center" }}>


                    <hr />

                    <Grid container spacing={8} alignItems="center"
                        justify="center">
                        <Grid item xs={6} >
                            <Card sx={{ minWidth: 275 }} style={{ boxShadow: "0 0 20px aquamarine", borderStyle: "groove", borderRadius: "10px" }}>
                                <CardContent>
                                    <TextField id="standard-basic" label="Επωνυμία" variant="standard" required onChange={(e) => setBuisnessName(e.target.value)} />
                                    <br />
                                    <br />

                                    <TextField id="standard-basic" label="Ιδιοκτήτης" variant="standard" required onChange={(e) => setOwner(e.target.value)} />
                                    <br />
                                    <br />
                                    <TextField id="standard-basic" label="Διεύθυνση" variant="standard" required onChange={(e) => setAddress(e.target.value)} />
                                    <br />
                                    <br />


                                    <TextField id="standard-basic" label="ΑΦΜ" variant="standard" onChange={(e) => setAfm(e.target.value)} />
                                    <br />
                                    <br />
                                    <TextField id="standard-basic" label="Αριθμός Φακέλου" variant="standard" onChange={(e) => setFolderNumber(e.target.value)} />
                                    <br />
                                    <br />
                                    <TextField id="standard-basic" label="Site" variant="standard" onChange={(e) => setSite(e.target.value)} />
                                    <br />
                                    <br />
                                    <InputLabel id="demo-controlled-open-select-label">
                                        Κατηγορία Επιχείρησης*
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>-------------------</em>
                                        </MenuItem>
                                        {props.categories.map((e) => (
                                            <MenuItem key={e} value={e}>
                                                {e}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <br />
                                    <br />
                                    <TextField id="standard-basic" label="Παρατηρήσεις" variant="standard" onChange={(e) => setNotes(e.target.value)} />


                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }} style={{ boxShadow: "0 0 20px aquamarine", borderStyle: "groove", borderRadius: "10px" }}>
                                <CardContent>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Εναρξη Ωραρίου"
                                            value={startTime}
                                            ampm={false}
                                            format="HH:mm:ss"
                                            onChange={(newValue) => {
                                                setStartTime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <br />
                                    <br />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Λήξη Ωραρίου"
                                            value={endTime}
                                            ampm={false}
                                            format="HH:mm:ss"
                                            onChange={(newValue) => {
                                                setEndTime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>


                                </CardContent>
                            </Card>

                            <br />
                            <br />

                            <Card sx={{ minWidth: 275 }} style={{ boxShadow: "0 0 20px aquamarine", borderStyle: "groove", borderRadius: "10px" }}>

                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Συνημμένο αρχείο
                                    </Typography>


                                    <Input
                                        //  multiple
                                        id="file_field"
                                        name="file_field"
                                        type="file"

                                        onChange={(e) => setSelectedFile(e.target.files[0])}

                                    />

                                </CardContent>
                            </Card>
                        </Grid>



                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="success" onClick={saveDialog}>Αποθηκευση</Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>Κλεισιμο</Button>
                </DialogActions>


            </Dialog>
        </div >

    )
}


function DeleteBussiness(props) {

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const deleteBusiness = () => {

        session.delete(`api/Epixeiriseis/${props.bussinessID}/`).then(() =>

            props.handleBusinessDeleteEnd()
        )
            .catch((e) => console.log(e.response.data))
            .finally(() => setOpen(false))


    }

    return (
        <div style={{ display: "inline" }}>
            <Button onClick={handleOpen} startIcon={<DeleteIcon />} color="error">

            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Είστε σίγουροι οτι θέλετε να διαγράψετε την Επιχείρηση - {props.bussinessName} ;
                    </Typography>

                    <hr />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button color="error" variant="outlined" onClick={deleteBusiness}>Διαγραφη</Button>
                    <Button color="success" variant="outlined" onClick={handleClose}>Επιστροφη</Button>

                </Box>
            </Modal>
        </div>
    )

}



export { CreateBusinessModal, DeleteBussiness }
