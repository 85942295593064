import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import session from "../api/session";

import userParser from "components/userGroups.js";
import {
  EidosParabasisModal,
  EidosParabasisDeleteSwal,
  StadiaParabaseisModal,
  StadioParabasisDeleteSwal,
  EidosAdeiasModal,
  EidosAdeiasDeleteSwal,
} from "./modals.jsx";

const parseDate = (dt) => {
  return new Date(dt).toLocaleDateString('el', { hour12: false })
}

class Parameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "1",
      eidiParabasis: [],
      stadiaParabasis: [],
      openUpdateEidosModal: false,
      openUpdateStadioModal: false,
      openCreateEidosModal: false,
      openCreateStadioModal: false,
      title: "",
      createValue: "",
      createValue2: "",
      eidiAdeias: [],
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    if (!userParser("Admin") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }

    this.fetchEidiParabasis();
    this.fetchStadiaParabasis();
    this.fetchEidiAdeias();
  }

  fetchEidiParabasis() {
    session
      .get("api/eidos-parabasis/all/")
      .then((resp) => {
        this.setState({ eidiParabasis: resp.data });
      })
      .catch((e) => {
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
  }

  fetchStadiaParabasis() {
    session.get("api/stadio-parabasis/all/").then((resp) => {
      this.setState({ stadiaParabasis: resp.data });
    });
  }

  fetchEidiAdeias() {
    session.get("api/eidos-adeias/all/").then((resp) => {
      this.setState({ eidiAdeias: resp.data });
    });
  }
  handleChange = (_, newValue) => {
    this.setState({
      newValue,
    });
  };

  eidosAdeiasEnd(e) {
    setTimeout(() => {
      this.fetchEidiAdeias();
    }, 2000);
  }
  handleEndEidosParabasis(e) {
    setTimeout(() => {
      this.fetchEidiParabasis();
    }, 2000);
  }

  handleStadioEnd(e) {
    setTimeout(() => {
      this.fetchStadiaParabasis();
    }, 2000);
  }

  parseDate(dt) {
    return new Date(dt).toLocaleDateString('el', { hour12: false })
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>ΠΑΡΑΜΕΤΡΙΚΑ</h3>
        </Box>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            backgroundColor: "white",
          }}
        >
          <TabContext value={this.state.newValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TabList
                onChange={this.handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ΕΙΔH ΠΑΡΑΒΑΣΗΣ" value="1" />
                <Tab label="ΣΤΑΔΙA ΠΑΡΑΒΑΣΗΣ" value="2" />
                <Tab label="ΠΑΡΑΜΕΤΡΙΚΑ ΑΔΕΙΑΣ" value="3" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.eidiParabasis &&
                      this.state.eidiParabasis.length > 0 &&
                      this.state.eidiParabasis.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">{parseDate(e.created_at)}</TableCell>
                          <TableCell align="right">
                            <EidosParabasisModal
                              action="Update"
                              handleEnd={(e) => this.handleEndEidosParabasis(e)}
                              eidosId={e.id}
                              title={e.title}
                            ></EidosParabasisModal>
                            <EidosParabasisDeleteSwal
                              EidosID={e.id}
                              handleEnd={(e) => this.handleEndEidosParabasis(e)}
                            ></EidosParabasisDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <EidosParabasisModal
                action="Create"
                handleEnd={(e) => this.handleEndEidosParabasis(e)}
              ></EidosParabasisModal>
            </TabPanel>
            {/* TAB STADIA PARABASEIS */}
            <TabPanel value="2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.stadiaParabasis &&
                      this.state.stadiaParabasis.length > 0 &&
                      this.state.stadiaParabasis.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">{parseDate(e.created_at)}</TableCell>
                          <TableCell align="right">
                            <StadiaParabaseisModal
                              action="Update"
                              title={e.title}
                              handleStadioEnd={(e) => this.handleStadioEnd(e)}
                              stadioID={e.id}
                            ></StadiaParabaseisModal>

                            <StadioParabasisDeleteSwal
                              handleStadioEnd={(e) => this.handleStadioEnd(e)}
                              stadioID={e.id}
                            ></StadioParabasisDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <StadiaParabaseisModal
                action="Create"
                handleStadioEnd={(e) => this.handleStadioEnd(e)}
              ></StadiaParabaseisModal>
            </TabPanel>
            <TabPanel value="3">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.eidiAdeias &&
                      this.state.eidiAdeias.length > 0 &&
                      this.state.eidiAdeias.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">{parseDate(e.created_at)}</TableCell>
                          <TableCell align="right">
                            <EidosAdeiasModal
                              action="Update"
                              title={e.title}
                              eidosAdeiasID={e.id}
                              eidosAdeiasEnd={(e) => this.eidosAdeiasEnd(e)}
                            ></EidosAdeiasModal>
                            <EidosAdeiasDeleteSwal
                              eidosAdeiasEnd={(e) => this.eidosAdeiasEnd(e)}
                              eidosAdeiasID={e.id}
                            ></EidosAdeiasDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <EidosAdeiasModal
                action="Create"
                eidosAdeiasEnd={(e) => this.eidosAdeiasEnd(e)}
              ></EidosAdeiasModal>
            </TabPanel>
          </TabContext>
        </Box>

        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Parameters;
