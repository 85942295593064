import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import axios from "axios";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import session from "../api/session";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h2>Σύνδεση</h2>
                  </CardHeader>
                  <Login></Login>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      username: "",
      password: "",
      GroupList: [],
    };
  }

  componentDidMount() {
    localStorage.removeItem("token");
    localStorage.removeItem("usergroups");
    this.refreshList();
  }

  refreshList() {
    session
      .get("api/groups/")
      .then((res) => this.setState({ GroupList: res.data }))
      .catch((err) => console.log(err));
    // axios
    //   .get(`${api_url}groups/`)
    //   .then((res) => this.setState({ GroupList: res.data }))
    //   .catch((err) => console.log(err));
  }

  handle_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevstate) => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  handle_login = (data) => {
    // e.preventDefault();
    // console.log(data)

    console.log(data);
    session
      .post("api/token-auth/", {
        username: data.username,
        password: data.password,
      })
      // fetch(`${api_url}token-auth/`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // })
      .then((res) => res.data)
      .then((json) => {
        if (
          json === undefined ||
          json.token === undefined ||
          json.user === undefined
        ) {
          alert("Wrong credentials, please try again or sign up");
        } else {
          let groupid = json.user.groups;

          console.log(this.state.GroupList);
          console.log(groupid);

          let groups = this.state.GroupList.filter((itemS) =>
            String(groupid).includes(String(itemS.id))
          );

          let groupname = [];

          for (var i = 0; i < groups.length; i++) {
            groupname.push(groups[i].name);
          }

          // groupname = groups;

          console.log(groupname);

          localStorage.setItem("token", json.token);
          localStorage.setItem("userselectedname", json.user.username);
          localStorage.setItem("isSuper", json.user.is_superuser);
          // localStorage.setItem("userselectedd", json.user.id);
          localStorage.setItem("usergroups", groupname);
          // localStorage.setItem("isLoggedIn", false);
          this.setState({
            logged_in: true,
            displayed_form: "",
            username: json.user.username,
            first_name: json.user.first_name,
          });

          // console.log("ok")
          // this.findinstitute();
          // this.pfalerts();
          window.location.href = "../";
        }
      });
  };
  render() {
    const classes = this.state.useStyles;

    return (
      <>
        <CardBody>
          <label>Username </label>
          <CustomInput
            // labelText="Username..."
            id="username"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "username",
              id: "username",
              onChange: (e) => this.handle_change(e),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputIconsColor} />
                </InputAdornment>
              ),
            }}
          />
          <label>Password </label>
          <CustomInput
            id="pass"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              name: "password",
              id: "password",
              onChange: (e) => this.handle_change(e),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                </InputAdornment>
              ),
              autoComplete: "off",
            }}
          />
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Button
            onClick={() => this.handle_login(this.state)}
            simple
            color="primary"
            size="lg"
          >
            ΣΥΝΔΕΣΗ
          </Button>
        </CardFooter>
      </>
    );
  }
}
