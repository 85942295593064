import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import MapE from "views/MapE/MapE.js";
import MapEAdmin from "views/MapE/MapE-admin.js";
import Map from "views/Map/Map.js";
import MapLA from "views/MapLA/MapLA.js";
import MapLAAdmin from "views/MapLA/MapLA-admin.js";
import MapLAParousies from "views/MapLA/MapLa-Parousies.js";
import SuperuserAdmin from "views/SuperUserPanel/SuperuserAdmin.js";
import Admin from "views/Admin/Admin.js";
import Post from "views/Post/Post.js";
import PostCovid from "views/Post/PostCovid.js";
import Xrewseis from "views/Admin/Xrewseis.js";

import PostAdmin from "views/Post/Post-admin.js";
import Gnwstopoihseis from "views/Gnwstopoihseis/Gnwstopoihseis.js";
import EpikoinwniaStegasmeno from "views/Epikoinwnia/EpikoinwniaStegasmeno.js";
import EpikoinwniaYpethrio from "views/Epikoinwnia/EpikoinwniaYpethrio.js";
import SupervisorIndex from "./views/Supervisor/index";
import BusinessCards from "./views/BusinessCards/index";
import BusinessDetails from "./views/BusinessCards/BusinessDetails/index";
import Stats from "views/Stats/Stats.js";
import Parameters from "./views/Parametrika/index.js";
import LaikesAdmin from "./views/LaikesPagkoiEpixeiriseis/laikes.js";
import EpixeiriseisAdmin from "./views/LaikesPagkoiEpixeiriseis/epixeiriseis.js";
import GenikaAdmin from "./views/LaikesPagkoiEpixeiriseis/genika.js";
import Syndromes from "./views/Syndromes/index.js";
import Contact from "./views/Epikoinwnia/contact.js";
import ContactManagement from "./views/ContactManagement/index.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <script>
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"
      />
    </script>
    <Switch>
      <Route path="/contact-management" component={ContactManagement} />
      <Route path="/contact" component={Contact} />
      <Route path="/syndromes" component={Syndromes} />
      <Route path="/genika-admin" component={GenikaAdmin} />
      <Route path="/laikes-admin" component={LaikesAdmin} />
      {/* <Route path="/pagkoi-admin" component={PagkoiAdmin} /> */}
      <Route path="/epixeiriseis-admin" component={EpixeiriseisAdmin} />
      <Route path="/parametric-infringements" component={Parameters} />
      <Route path="/business-details/:id" component={BusinessDetails} />
      <Route path="/business-cards" component={BusinessCards} />
      <Route path="/supervisors" component={SupervisorIndex} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/yp" component={LoginPage} />
      <Route path="/MapE" component={MapE} />
      <Route path="/MapE-Admin" component={MapEAdmin} />
      <Route path="/Map" component={Map} />
      <Route path="/MapLA" component={MapLA} />
      <Route path="/MapLA-Admin" component={MapLAAdmin} />
      <Route path="/MapLa-Parousia" component={MapLAParousies} />
      <Route path="/SuperuserAdmin" component={SuperuserAdmin} />
      <Route path="/Admin" component={Admin} />
      <Route path="/Post" component={Post} />
      <Route path="/Post-Covid" component={PostCovid} />
      <Route path="/Post-admin" component={PostAdmin} />
      <Route path="/Gnwstopoihseis" component={Gnwstopoihseis} />
      {/* <Route path="/EpikoinwniaStegasmeno" component={EpikoinwniaStegasmeno} /> */}
      {/* <Route path="/EpikoinwniaYpethrio" component={EpikoinwniaYpethrio} /> */}
      <Route path="/Stats" component={Stats} />
      <Route path="/Xrewseis" component={Xrewseis} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
