import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMapEvents,
  FeatureGroup,
  Tooltip,
  // Circle,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
// import { isConstructorDeclaration } from "typescript";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Date from "react-datetime";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelect from "react-multi-select-component";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import NavPills from "components/NavPills/NavPills.js";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import Input from "@material-ui/core/Input";

import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// core components

import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import {
  CardFooter,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  // Table,
  Container,
  Row,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  Modal,
  ModalHeader,
  // Button,
  ModalBody,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import { number } from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import Geocode from "react-geocode";
import lastDayOfISOWeek from "date-fns/fp/lastDayOfISOWeek/index";
import session from "../api/session";

import ReactExport from "react-data-export";
import userParser from "components/userGroups.js";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

Geocode.setApiKey("AIzaSyBSF6RaFqZGzFJgPZv1drw7PEjYZ8qZd_0");
Geocode.setLanguage("el");
Geocode.setRegion("gr");
let UdataTablePocat = [];
let UdataTablePids = [];
let UdataTablePo = [];

let UdataTableU = [];
let grouptable = [];

let UdataTablePoC = [];
let UdataTableEC = [];

let UdataTableL = [];
let UdataTableP = [];
let UdataTablePX = [];
let UdataTableE = [];
let UdataTableXr = [];

async function setDoc(item) {
  var url = "http://127.0.0.1:8000/api/docuploadPagkos";
  // console.log(item);

  let formData = new FormData();
  formData.append("pagkos", item.pagkos);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPagkos", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
}

async function setPostDoc(item) {
  var url = "http://127.0.0.1:8000/api/docuploadPost";
  // console.log(item);

  let formData = new FormData();
  formData.append("postId", item.postId);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPost", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
}

async function setlogo(item) {
  var url = "http://127.0.0.1:8000/api/logoupload";

  let formData = new FormData();

  formData.append("logo", item.logo);
  formData.append("nameEC", item.nameEC);
  formData.append("iconEC", "-");
  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/logoupload", formData);
}

async function setDocE(item) {
  var url = "http://127.0.0.1:8000/api/docuploadEpixirisi";
  // console.log(item);

  let formData = new FormData();
  formData.append("epixeirisi", item.epixeirisi);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  // console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadEpixirisi", formData);

  // let response = await axios.post(url, formData);
  // console.log(response);
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let posseleted = [];
let adressel = "";
let newE = false;
let editE = false;
let center = [39.365, 22.951];
let zoom = 14;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function LocationMarker() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // console.log(address);
          adressel = address;
        },
        (error) => {
          console.error(error);
        }
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : <></>;
  return position === null ? null : (
    <Marker position={position}>
      {" "}
      <Tooltip>{adressel}</Tooltip>{" "}
    </Marker>
  );
}

function LocationMarkerLaiki() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

export default function SuperuserAdmin() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  let newItem = React.useState();
  let newItemC = React.useState();

  React.useEffect(() => {
    // console.log("e");
    // console.log(editE);
  }, [editE]);

  React.useEffect(() => {
    // // console.log("e");
    // // console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <NavPills
              color="success"
              size="sm"
              tabs={[
                {
                  tabButton: "Κατηγορίες",
                  tabContent: <CategoryPanel></CategoryPanel>,
                },
                {
                  tabButton: "Χρήστες",
                  tabContent: <UsersPanel></UsersPanel>,
                },
                {
                  tabButton: "Posts",
                  tabContent: <PostPanel></PostPanel>,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class CategoryPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      LaikesList: [],
      LaikesSelectList: [],
      markers: [[0, 0]],
      EpixeiriseisCategoryList: [],
      PagkosCategoryList: [],
      activeItemEC: {},
      activeItemE: {},
      modalE: false,
      modalEC: false,
    };
  }

  componentDidMount() {
    this.refreshListCategory();
  }

  refreshListCategory() {
    // axios
    //   .get("http://127.0.0.1:8000/api/EpixeirisiCategory/")
    session
      .get("api/EpixeirisiCategory/")
      .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/PagkosCategory/")
    session
      .get("api/PagkosCategory/")
      .then((res) => this.setState({ PagkosCategoryList: res.data }))
      .catch((err) => console.log(err));
  }

  handleNewEC = () => {
    // // console.log(item);
    this.state.activeItemEC.nameEC = "";
    this.state.activeItemEC.logo = "";
    // this.state.activeItemE = item;
    this.setState({ modalEC: true });
    // // console.log(this.state.modalE);
    // console.log(this.state.activeItemEC);
    // this.render();
  };

  handleCloseEC = () => {
    this.state.activeItemEC = [];
    this.setState({ activeItemEC: [] });
    this.setState({ modalEC: false });
  };

  handleDeletePC = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/PagkosCategory/${item.id}`)
    session
      .delete(`api/PagkosCategory/${item.id}`)
      .then(this.refreshListCategory());
    this.refreshListCategory();
    this.setState({ state: this.state });
  };

  handleDeleteEC = (item) => {
    // axios
    //   .delete(
    //     `http://127.0.0.1:8000/api/EpixeirisiCategory/${item.id}`
    //   )
    session
      .delete(`api/EpixeirisiCategory/${item.id}`)
      .then(this.refreshListCategory());
    this.refreshListCategory();
    this.setState({ state: this.state });
  };

  render() {
    const classes = this.state.useStyles;
    const Epcategories = this.state.EpixeiriseisCategoryList;
    const Pacategories = this.state.PagkosCategoryList;

    UdataTablePoC = [];
    for (var i = 0; i < Pacategories.length; i++) {
      const dataRow = {};
      const item = Pacategories[i];

      dataRow.onoma = Pacategories[i].namePC;

      dataRow.eidos = <Badge color={item.description}>{item.colorPC}</Badge>;
      dataRow.e = (
        <>
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeletePC(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      UdataTablePoC.push(dataRow);
    }

    UdataTableEC = [];

    for (var i = 0; i < Epcategories.length; i++) {
      const dataRow = {};
      const item = Epcategories[i];

      dataRow.onoma = Epcategories[i].nameEC;

      dataRow.eidos = <img style={{ width: "25px" }} src={item.logo} />;

      dataRow.e = (
        <>
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeleteEC(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      UdataTableEC.push(dataRow);
    }

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardHeader color="success">Κατηγορίας Πάγκου</CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTablePoC}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Όνομα",
                      accessor: "onoma",
                    },
                    {
                      Header: "Είδος",
                      accessor: "eidos",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardHeader color="success">
                Κατηγορίας Επιχείρησης{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewEC()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTableEC}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Όνομα",
                      accessor: "onoma",
                    },
                    {
                      Header: "Είδος",
                      accessor: "eidos",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalEC}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseEC()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseEC()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="nameEC"
                  inputProps={{
                    value: this.state.activeItemEC.nameEC,
                    placeholder: "Όνομα",
                    onChange: (e) => this.handleChangeEC(e),
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <Input
                  accept="image/*"
                  // className={classes.input}
                  id="post-image"
                  onChange={(e) => this.handleChangeLogo(e)}
                  name="image"
                  type="file"
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseEC()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => setlogo(this.state.activeItemEC)}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

class UsersPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      UsersList: [],
      GroupList: [],
      isnew: 0,
      activeItemUser: {
        groupnames: [],
      },
      modalU: false,
      groups: [],
      passwordFieldActivity: true,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "/";
    }
    if (!userParser("Admin") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }
    this.refreshListUsers();
  }

  refreshListUsers() {
    // axios
    //   .get("http://127.0.0.1:8000/api/users/")
    session
      .get("api/users/")
      .then((res) => this.setState({ UsersList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/groups/")
    session
      .get("api/groups/")
      .then((res) => this.setState({ GroupList: res.data }))
      .catch((err) => console.log(err));
  }

  handleMultiSelectChange2 = (e) => {
    const options = e.target.value;
    const value = [];
    const valueF = [];

    // console.log(e.target.value);

    for (let i = 0, l = options.length; i < l; i += 1) {
      // console.log(options[i]);

      if (options[i] != undefined) {
        // console.log(options[i].length);
        // console.log(options[i].constructor);

        if (!String(options[i].constructor).includes("Array")) {
          value.push(options[i]);
        } else {
          const tmp = options[i];
          // console.log(tmp);

          for (var j = 0; j < tmp.length; j++) {
            value.push(tmp[j]);
          }
        }
      }
    }

    // console.log(value);

    const uniqueNames = Array.from(new Set(value));

    const name = "groupnames";
    const activeItemUser = {
      ...this.state.activeItemUser,
      groupnames: uniqueNames,
    };

    // console.log(activeItemUser);
    this.setState({ activeItemUser });

    this.setState({ state: this.state });
  };

  handleMultiSelectChange = (e) => {
    const name = "groups";
    const value = [e.target.value];
    const activeItemUser = { ...this.state.activeItemUser, [name]: value };

    // console.log(activeItemUser);
    this.setState({ activeItemUser });

    this.setState({ state: this.state });

    this.render();
  };

  handle_signup = (e, data) => {
    // console.log(data);

    if (this.state.activeItemUser.id) {
      const newItem = this.state.activeItemUser;

      let valueF = [];

      for (var i = 0; i < newItem.groupnames.length; i++) {
        const tmpgroups = this.state.GroupList.filter(
          (itemG) => String(itemG.name) == newItem.groupnames[i]
        );

        valueF.push(tmpgroups[0].id);
      }

      // console.log(valueF);

      newItem.groups = valueF;
      session.put(`api/users/${newItem.id}/`, newItem).then(() => {
        this.refreshListUsers();
      });
    } else {
      // console.log(this.state.activeItemUser, "Active ItemUser");
      // e.preventDefault();
      console.log(this.state.activeItemUser);
      session
        .post("tests/users/", {
          username: this.state.activeItemUser.username,
          password: this.state.activeItemUser.password,
        })
        .then(() => {
          this.refreshListUsers();
        })
        .catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
      // session
      //   .post("tests/users/", {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(this.state.activeItemUser),
      //   })

      //   .then((resp) => {
      //     console.log(resp);
      //     this.refreshListUsers();
      //   });
      // .catch((error) => {
      //   alert(error.response.data);
      // });

      // window.location.reload();
    }

    this.refreshListUsers();
    this.setState({ state: this.state });
    this.handleCloseU();
    this.render();
    this.render();

    // window.location.reload();
  };

  handleNewU = () => {
    // // console.log(item);
    this.state.activeItemUser.username = "";
    this.state.activeItemUser.password = "";
    this.state.activeItemUser.first_name = "";
    this.state.activeItemUser.last_name = "";
    this.state.activeItemUser.email = "";

    this.state.isnew = 1;
    // this.state.activeItemE = item;
    this.setState({ modalU: true });
  };

  handleChangeU = (e) => {
    const { name, value } = e.target;

    const activeItemUser = { ...this.state.activeItemUser, [name]: value };
    // console.log(this.state.activeItemUser);
    this.setState({ activeItemUser });
  };

  handleCloseU = () => {
    this.state.activeItemUser = [];
    this.setState({ activeItemUser: [] });
    this.setState({ modalU: false });
  };

  handleDeleteU = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/users/${item.id}`)
    session.delete(`api/users/${item.id}`).then(this.refreshListUsers());
    this.refreshListUsers();
    this.setState({ state: this.state });
    this.refreshListUsers();
    this.setState({ state: this.state });
    this.handleCloseU();
    this.render();
  };

  handleEditUser = (item) => {
    this.state.isnew = 0;

    this.state.activeItemUser = item;

    let group = [];

    const tmpgroups = this.state.GroupList.filter((itemG) =>
      String(item.groups).includes(String(itemG.id))
    );

    for (var j = 0; j < tmpgroups.length; j++) {
      group.push(tmpgroups[j].name);
    }

    this.state.activeItemUser.groupnames = group;

    // console.log(this.state.activeItemUser);
    // this.state.activeItem = item;
    this.setState({ modalU: true });
    // // console.log(this.state.modalP);
    // // console.log(this.state.activeItemLaiki);
    // this.render();
  };

  passwordFieldHandle = () => {
    this.setState({ passwordFieldActivity: false });
    console.log("clicked");
  };

  render() {
    const classes = this.state.useStyles;
    const Users = this.state.UsersList;
    const Groups = this.state.GroupList.filter((item) => item.id != 1);

    UdataTableU = [];

    for (var i = 0; i < Users.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      const name = Users[i].username;
      const item = Users[i];
      let group = "";

      const tmpgroups = this.state.GroupList.filter((itemG) =>
        String(item.groups).includes(String(itemG.id))
      );

      // console.log(tmpgroups);

      for (var j = 0; j < tmpgroups.length; j++) {
        group += tmpgroups[j].name + " - ";
      }

      dataRow.username = name;
      dataRow.fullname = Users[i].first_name + " " + Users[i].last_name;
      dataRow.email = Users[i].email;
      dataRow.group = group;

      dataRow.e = (
        <>
          {" "}
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditUser(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          {(localStorage.getItem("isSuper") != true ||
            !localStorage.getItem("userselectedname") == item.username) && (
            <Button
              round
              color="danger"
              size="sm"
              onClick={() => this.handleDeleteU(item)}
            >
              <i className="fa fa-times"></i>
            </Button>
          )}
        </>
      );

      UdataTableU.push(dataRow);
    }

    grouptable = [];

    const names = [];

    for (var i = 0; i < Groups.length; i++) {
      const dataRow = {};
      const item = Groups[i];

      // console.log(item);

      dataRow.value = String(item.id);
      dataRow.label = item.name;

      grouptable.push(dataRow);
      names.push(item.name);
    }

    // console.log(this.state.activeItemUser.groupnames);

    return (
      <>
        <GridContainer>
          <GridItem>
            <Card>
              <CardHeader color="success">
                Χρήστες{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewU()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTableU}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Username",
                      accessor: "username",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Ονοματεπώνυμο",
                      accessor: "fullname",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Group",
                      accessor: "group",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ενέργειες",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalU}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseU()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseU()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="username"
                  inputProps={{
                    value: this.state.activeItemUser.username,
                    placeholder: "username",
                    id: "username",
                    name: "username",
                    onChange: (e) => this.handleChangeU(e),
                    type: "text",
                  }}
                />

                {this.state.isnew == 0 && (
                  <>
                    <CustomInput
                      id="first_name"
                      inputProps={{
                        value: this.state.activeItemUser.first_name,
                        placeholder: "first_name",
                        id: "first_name",
                        name: "first_name",
                        onChange: (e) => this.handleChangeU(e),
                        type: "text",
                      }}
                    />

                    <CustomInput
                      id="email"
                      inputProps={{
                        value: this.state.activeItemUser.email,
                        placeholder: "email",
                        id: "email",
                        name: "email",
                        onChange: (e) => this.handleChangeU(e),
                        type: "text",
                      }}
                    />

                    {this.state.activeItemUser.groups != 1 && (
                      <>
                        <br />
                        <br />
                        {this.state.activeItemUser.groupnames != undefined && (
                          <>
                            {names.length != 0 && (
                              <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={this.state.activeItemUser.groupnames}
                                onChange={(e) =>
                                  this.handleMultiSelectChange2(e)
                                }
                                input={<Input />}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  id="password"
                  inputProps={{
                    value: this.state.activeItemUser.password,
                    placeholder: "password",
                    id: "password",
                    name: "password",
                    onChange: (e) => this.handleChangeU(e),
                    type: "password",
                    // disabled:true
                    // disabled:this.state.passwordFieldActivity,
                  }}
                />
                {this.state.isnew == 0 && (
                  <CustomInput
                    id="last_name"
                    inputProps={{
                      value: this.state.activeItemUser.last_name,
                      placeholder: "last_name",
                      id: "last_name",
                      name: "last_name",
                      onChange: (e) => this.handleChangeU(e),
                      type: "text",
                    }}
                  />
                )}
                <br />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseU()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handle_signup(this.state.activeItemUser)}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

class PostPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      PostList: [],
      PostCatList: [],
      activeItemPost: {},
      modalPoc: false,
      activeItemPoc: {},
      modalPost: false,
      lastname: "",
      postdocitem: [],
      postdocsList: [],
      selectedPostDocs: [],
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListPostDocs();
  }

  refreshList() {
    // axios
    //   .get("http://127.0.0.1:8000/api/Post/")
    session
      .get("api/Post/")
      .then((res) => this.setState({ PostList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("http://127.0.0.1:8000/api/PostCategory/")
    session
      .get("api/PostCategory/")
      .then((res) => this.setState({ PostCatList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListPostDocs() {
    // axios
    //   .get("http://127.0.0.1:8000/api/FileFieldFormPost/")
    session
      .get("api/FileFieldFormPost/")
      .then((res) => this.setState({ postdocsList: res.data }))
      .catch((err) => console.log(err));
  }

  handleNewPost = () => {
    // // console.log(item);
    this.state.activeItemPost.title = "";
    this.state.activeItemPost.slug = "";
    this.state.activeItemPost.content = "";
    this.state.activeItemPost.status = "";
    this.state.activeItemPost.category = "";
    this.state.activeItemPost.author = "";

    // this.state.activeItemE = item;
    this.setState({ modalPost: true });
  };

  handleClosePost = () => {
    this.state.activeItemPost = [];
    this.setState({ activeItemPost: [] });
    this.setState({ modalPost: false });
  };

  handleNewPoC = () => {
    // // console.log(item);
    this.state.activeItemPoc.namePoC = "";
    // this.state.activeItemE = item;
    this.setState({ modalPoc: true });
    // // console.log(this.state.modalE);
    // this.render();
  };

  handleEditPost = (item) => {
    // // console.log(item);
    this.state.activeItemPost = item;
    // this.state.activeItemE = item;

    this.state.selectedPostDocs = this.state.postdocsList.filter(
      (itemS) => itemS.postId === item.title
    );

    // // console.log(this.state.postdocsList);
    // // console.log(this.state.selectedPostDocs);

    this.setState({ modalPost: true });
    // // console.log(this.state.modalE);
    // this.render();
  };

  handleEditPoC = (item) => {
    // // console.log(item);
    this.state.activeItemPoc = item;
    // this.state.activeItemE = item;
    this.setState({ modalPoc: true });
    // // console.log(this.state.modalE);
    // this.render();
  };

  handleChangePoc = (e) => {
    this.state.lastname = this.state.activeItemPoc.namePoC;
    const name = e.target.id;
    const value = e.target.value;
    const activeItemPoc = { ...this.state.activeItemPoc, [name]: value };
    // formData.append([name], value);
    // console.log("ok");
    this.setState({ activeItemPoc });
  };

  handleChangePost = (e) => {
    // this.state.lastname = this.state.activeItemPoc.namePoC;
    let name = "";
    if (e.target.name) {
      name = e.target.name;
    } else {
      name = e.target.id;
    }
    const value = e.target.value;
    const activeItemPost = { ...this.state.activeItemPost, [name]: value };
    // formData.append([name], value);
    // // console.log("ok");
    this.setState({ activeItemPost });

    // console.log(activeItemPost);
  };

  handlePostPoC = () => {
    const newItem = this.state.activeItemPoc;

    if (newItem.id) {
      // const tmpPosts = this.state.PostList.filter( (item) =>
      // String(item.category) === this.state.last)

      // axios
      //   .put(
      //     `http://127.0.0.1:8000/api/PostCategory/${newItem.id}/`,
      //     newItem
      //   )
      session
        .put(`api/PostCategory/${newItem.id}/`, newItem)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("http://127.0.0.1:8000/api/PostCategory/", newItem)
      session.post("api/PostCategory/", newItem).then(this.refreshList());
    }

    this.state.activeItemPoc = [];
    this.refreshList();
    this.handleClosePoC();
    // newE = true;
  };

  handleChangePostDoc = (e) => {
    var files = e.target.files[0];
    // console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.postdocitem.file_field = e.target.files[0];
    this.state.postdocitem.pagkos = this.state.activeItemPost.title;
    this.state.postdocitem.description = fname[0];
  };

  handlePostPost = () => {
    const newItem = this.state.activeItemPost;

    newItem.slug = newItem.title;
    newItem.author = localStorage.getItem("userselectedname");

    if (newItem.id) {
      // const tmpPosts = this.state.PostList.filter( (item) =>
      // String(item.category) === this.state.last)

      // console.log(newItem);

      this.state.postdocitem.postId = newItem.title;

      setPostDoc(this.state.postdocitem);

      // axios
      //   .put(`http://127.0.0.1:8000/api/Post/${newItem.id}/`, newItem)
      session
        .put(`api/Post/${newItem.id}/`, newItem)
        .then(this.refreshList())
        .catch((err) => console.log(err));
    } else {
      // axios
      //   .post("http://127.0.0.1:8000/api/Post/", newItem)
      session
        .post("api/Post/", newItem)
        .then(this.refreshList())
        .catch((err) => console.log(err));

      this.state.postdocitem.postId = newItem.title;

      setPostDoc(this.state.postdocitem);
    }

    this.state.activeItemPost = [];
    this.refreshList();
    this.refreshListPostDocs();
    this.handleClosePost();
    this.setState({ state: this.state });
    // newE = true;
  };

  handleClosePoC = () => {
    this.state.activeItemPoc = [];
    this.setState({ activeItemPoc: [] });
    this.setState({ modalPoc: false });
  };

  handleDeletePoC = (item) => {
    // console.log(item);
    // axios
    //   .delete(`http://127.0.0.1:8000/api/PostCategory/${item.id}/`)
    session.delete(`api/PostCategory/${item.id}/`).then(this.refreshList());
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleDeletePost = (item) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/Post/${item.id}`)
    session.delete(`api/Post/${item.id}`).then(this.refreshList());
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleDeleteDocPo = (id) => {
    // axios
    //   .delete(`http://127.0.0.1:8000/api/FileFieldFormPost/${id}`)
    session
      .delete(`api/FileFieldFormPost/${id}`)
      .then(this.refreshListPostDocs());
    this.refreshList();
    this.refreshListPostDocs();
    this.setState({ state: this.state });
    this.setState({ modalPost: false });
    this.render();
    document.getElementById("file_field").value = null;
    this.postselected = "";
    // window.location.reload(false);
    // this.setState({ modalP: true });

    this.refreshListPostDocs();
    this.setState({ modalP: false });

    this.state.postselected = "-";

    this.yourFunctionPo();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunctionPo() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    // console.log("Waited 5s");
    this.handleEditPost(this.state.activeItemPost);
    this.setState({ modalPost: false });
    await delay(1000);
    // console.log("Waited 5s");
    this.handleEditPost(this.state.activeItemPost);
  }

  render() {
    const classes = this.state.useStyles;
    const posts = this.state.PostList;
    const postsCat = this.state.PostCatList;

    for (var i = 0; i < posts.length; i++) {
      const tmpdocs = this.state.postdocsList.filter(
        (itemS) => itemS.postId === posts[i].title
      );

      if (tmpdocs[0] != undefined) {
        // console.log(tmpdocs);
        posts[i].image = String(tmpdocs[0].file_field);
      }
    }

    UdataTablePocat = [];

    for (var i = 0; i < postsCat.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      const name = postsCat[i].namePoC;
      const item = postsCat[i];
      dataRow.name = name;
      dataRow.e = (
        <Button
          round
          color="danger"
          size="sm"
          onClick={() => this.handleDeletePoC(item)}
        >
          <i className="fa fa-times"></i>
        </Button>
      );
      // tmpItemsLaikes[i].nameLA;
      // dataRow.perioxi = tmpItemsLaikes[i].perioxiA;

      UdataTablePocat.push(dataRow);
    }

    UdataTablePo = [];

    for (var i = 0; i < posts.length; i++) {
      const dataRow = {};
      const id = posts[i].id;
      const nameP = posts[i].title;
      const item = posts[i];
      let category = "";
      let category2 = "";

      if (posts[i].status == 0) {
        category2 = <Badge color="warning">{"Πρόχειρο"}</Badge>;
      } else {
        category2 = <Badge color="success">{"Δημοσιευμένο"}</Badge>;
      }

      if (posts[i].status == 0) {
        category = "Πρόχειρο";
      } else {
        category = "Δημοσιευμένο";
      }

      dataRow.name = nameP;
      dataRow.e = (
        <>
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditPost(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeletePost(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.category = posts[i].category;

      // dataRow.date = posts[i].created_on.split("T")[0];

      let tmp = posts[i].created_on.split("T")[0];
      let tmp2 = tmp.split("-");
      dataRow.date = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      dataRow.content = posts[i].content;
      dataRow.user = posts[i].author;
      dataRow.katastasi = category;
      UdataTablePo.push(dataRow);
    }

    // console.log(posts);

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={9}>
            <Card>
              <CardBody>
                <CardHeader color="success">
                  Post{" "}
                  <Button
                    round
                    color="info"
                    size="sm"
                    onClick={() => this.handleNewPost()}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </CardHeader>

                <ReactTable
                  data={UdataTablePo}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Τίτλος",
                      accessor: "name",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Περιεχόμενο",
                      accessor: "content",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Κατηγορία",
                      accessor: "category",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Συγγραφέας",
                      accessor: "user",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Κατάσταση",
                      accessor: "katastasi",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ημερομηνία",
                      accessor: "date",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: " ",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={3}>
            <Card>
              <CardBody>
                <CardHeader color="success">
                  Κατηγορίες{" "}
                  <Button
                    round
                    color="info"
                    size="sm"
                    onClick={() => this.handleNewPoC()}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </CardHeader>
                <ReactTable
                  data={UdataTablePocat}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Όνομα",
                      accessor: "name",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Ενέργεια",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modalPoc,
          }}
          open={this.state.modalPoc}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleClosePoC()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePoC()}
            >
              <Close className={classes.modalClosePoc} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  id="namePoC"
                  inputProps={{
                    value: this.state.activeItemPoc.namePoC,
                    placeholder: "Όνομα",
                    onChange: (e) => this.handleChangePoc(e),
                    type: "text",
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePoC()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostPoC()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modalPost,
          }}
          open={this.state.modalPost}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="sm"
          onClose={() => this.handleClosePost()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePost()}
            >
              <Close className={classes.modalClosePoc} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color="success">Στοιχεία</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="title"
                      name="title"
                      inputProps={{
                        value: this.state.activeItemPost.title,
                        placeholder: "Τίτλος",
                        onChange: (e) => this.handleChangePost(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <CustomInput
                      id="content"
                      name="content"
                      inputProps={{
                        value: this.state.activeItemPost.content,
                        placeholder: "Κείμενο",
                        maxlength: "5",
                        onChange: (e) => this.handleChangePost(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label> Κατηγορία </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="category"
                      id="category"
                      value={String(this.state.activeItemPost.category)}
                      onChange={(e) => this.handleChangePost(e)}
                    >
                      {postsCat.map((item) => (
                        <MenuItem value={item.namePoC}>{item.namePoC}</MenuItem>
                      ))}
                    </Select>
                    <br></br>
                    <br></br>
                    <label> Κατάσταση </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="status"
                      id="status"
                      value={parseInt(this.state.activeItemPost.status)}
                      onChange={(e) => this.handleChangePost(e)}
                    >
                      <MenuItem value={0}>Πρόχειρο</MenuItem>

                      <MenuItem value={1}>Δημοσιευμένο</MenuItem>
                    </Select>
                    <br />
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        Αρχείο
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangePostDoc(e)}
                        name="file_field"
                        type="file"
                      />
                    </FormGroup>
                    <label> Διαθέσιμα αρχεία</label>
                    {this.state.selectedPostDocs.map(
                      ({ id, file_field, description }) => (
                        <Badge>
                          <Button
                            size="sm"
                            round
                            onClick={() => window.open(file_field, "_blank")}
                          >
                            {" "}
                            {description}{" "}
                          </Button>
                          <Button
                            round
                            color="danger"
                            size="sm"
                            onClick={() => this.handleDeleteDocPo(id)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </Badge>
                      )
                    )}{" "}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePost()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostPost()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
