import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Input from '@mui/material/Input';
import session from '../../api/session'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function ParabaseisModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [eidos, setEidosParabasi] = React.useState('');
    const [stadio, setStadioParabasi] = React.useState('');
    const [value, setValue] = React.useState(props.date ? new Date(props.date) : null);

    const [stadioUpdate, setStadioUpdate] = React.useState(props.updateStadia)
    const [textValue, setTextValue] = React.useState(props.comments ? props.comments : "")
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [checked, setChecked] = React.useState(props.checkAnaklisi ? true : false)


    const handleSelectChange = (event) => {
        setStadioParabasi(event.target.value)
    }
    const handleEidosSelectChange = (event) => {

        setEidosParabasi(event.target.value)
    }

    const dateChange = (newValue) => {

        setValue(newValue)
    }

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked)
    };

    const handleChangeText = (event) => {
        setTextValue(event.target.value);
    };
    const SaveCard = () => {

        console.log(selectedFile)
        let data = new FormData()
        if (selectedFile) {
            data.append('file', selectedFile)
        }

        // data.append('date', value.toLocaleDateString().split('/').reverse().join('-'))
        data.append('date', value.toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-'))
        data.append('stadio', stadio)
        data.append('eidos', eidos)
        data.append('anaklisi_adeias', checked)
        data.append('comments', textValue)
        data.append('business', props.personID)
        data.append('created_by', props.created_by)

        if (props.action === 'Create') {


            session.post(`api/business-parabaseis/create/`,
                data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => setOpen(false), props.handleChange())
        }
        if (props.action === "Update") {


            session.put(`api/business-parabaseis/${props.id}/update/`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => setOpen(false), props.handleChange())
        }

    }

    return (
        <div>
            <Button onClick={handleOpen} variant="outlined"> {props.buttonTitle ? props.buttonTitle : "Δημιουργια"}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.title ? props.title : "Δημιουργία"} Παράβασης
                    </Typography>
                    <hr />
                    <Stack spacing={5}>

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>

                                    <CardContent >

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={4}>
                                                <DesktopDatePicker
                                                    label="Εναρξη Εγγραφής"
                                                    // inputFormat="yyy/MM/dd"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={value}
                                                    // onChange={(newValue) => setValue(newValue)}
                                                    onChange={(newValue) => dateChange(newValue)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>

                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Σταδιο Παράβασης</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={stadio}
                                                onChange={handleSelectChange}
                                                label="Σταδιο Παράβασης"
                                            >

                                                <MenuItem value="">
                                                    <em>None</em>

                                                </MenuItem>


                                                {props.stadia && props.stadia.length > 0 && props.stadia.map((e) => (
                                                    <MenuItem key={e.id} value={e.id}>{e.title}</MenuItem>

                                                ))}

                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Είδος Παράβασης</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={eidos}
                                                onChange={handleEidosSelectChange}
                                                label="Είδος Παράβασης"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.eidos && props.eidos.length > 0 && props.eidos.map((e) => (
                                                    <MenuItem key={e.id} value={e.id}>{e.title}</MenuItem>

                                                ))}

                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Ποινή"
                                            multiline
                                            onChange={(e) => handleChangeText(e)}
                                            value={textValue}
                                        />
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={checked} onChange={handleChangeCheck} name="jason" />
                                            }
                                            label="Ανάκληση Άδειας"
                                        />
                                    </CardContent>
                                </Grid>
                                <br />
                                <Grid item xs={12}>
                                    <Input
                                        id="file_field"
                                        name="file_field"
                                        type="file"
                                        onChange={(e) => setSelectedFile(e.target.files[0])}
                                    />
                                    <br />

                                    {props.file && props.file.length > 0 && (
                                        <Link href={props.file}>{decodeURI(props.file.split('/').pop())}</Link>
                                    )}


                                </Grid>
                            </Grid>

                        </Box>
                    </Stack>
                    <Typography>
                        <Button variant="contained" color="success" onClick={SaveCard}>
                            Αποθηκευση
                        </Button>

                    </Typography>
                </Box>
            </Modal>
        </div >
    )
}


function MetabibaseisModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState(props.date ? new Date(props.date) : null);
    const [textValueFrom, setTextValueFrom] = React.useState(props.fromPerson ? props.fromPerson : "")
    const [textValueTo, setTextValueTo] = React.useState(props.toPerson ? props.toPerson : "")
    const [textValue, setTextValue] = React.useState(props.comments ? props.comments : "")
    const [selectedFile, setSelectedFile] = React.useState(null);




    const handleFromChange = (event) => {
        setTextValueFrom(event.target.value)
    }

    const handleToChange = (event) => {
        setTextValueTo(event.target.value)
    }
    const handleChangeText = (event) => {
        setTextValue(event.target.value);
    };
    const SaveCard = () => {
        let data = new FormData()
        if (selectedFile) {
            data.append('file', selectedFile)
        }

        data.append('date', value.toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-'))
        data.append('from_person', textValueFrom)
        data.append('to_person', textValueTo)

        data.append('comments', textValue)
        data.append('business', props.personID)
        data.append('created_by', props.created_by)

        if (props.action === 'Create') {


            session.post(`api/business-transfer/create/`,
                data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => setOpen(false), props.handleChange())
        }
        if (props.action === "Update") {

            session.put(`api/business-transfer/${props.id}/update/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(() => setOpen(false), props.handleChange())

        }

    }

    return (
        <div>
            <Button onClick={handleOpen} variant="outlined"> {props.buttonTitle ? props.buttonTitle : "Δημιουργια"}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.title ? props.title : "Δημιουργία"} Μεταβίβασης
                    </Typography>
                    <hr />
                    <Stack spacing={5}>

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>

                                    <CardContent >

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={4}>
                                                <DesktopDatePicker
                                                    label="Εναρξη Εγγραφής"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={value}
                                                    onChange={(newValue) => setValue(newValue)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>

                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >

                                        <TextField

                                            label="Σχολια"
                                            id="outlined-multiline-flexible"

                                            multiline
                                            maxRows={4}
                                            value={textValue}
                                            onChange={handleChangeText}

                                        />
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >

                                        <TextField

                                            label="Απο"
                                            id="outlined-multiline-flexible"

                                            multiline
                                            maxRows={4}
                                            value={textValueFrom}
                                            onChange={handleFromChange}

                                        />
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent >

                                        <TextField

                                            label="Προς"
                                            id="outlined-multiline-flexible"

                                            multiline
                                            maxRows={4}
                                            value={textValueTo}
                                            onChange={handleToChange}

                                        />
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        //  multiple
                                        id="file_field"
                                        name="file_field"
                                        type="file"

                                        onChange={(e) => setSelectedFile(e.target.files[0])}

                                    />
                                </Grid>
                            </Grid>

                        </Box>
                    </Stack>
                    <Typography>
                        <Button variant="contained" color="success" onClick={SaveCard}>
                            Αποθηκευση
                        </Button>

                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}


export {
    ParabaseisModal, MetabibaseisModal
}