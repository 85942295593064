import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import axios from "axios";
// import PDFViewer from "pdf-viewer-reactjs";
import DownloadLink from "react-download-link";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Date from "react-datetime";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelect from "react-multi-select-component";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import session from '../api/session'

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  Polygon,
  useMapEvents,
} from "react-leaflet";
import {
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import L from "leaflet";
import Parallax from "components/Parallax/Parallax.js";
import { PDFDownloadLink, Document, Page } from "react-pdf";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ReactTable from "components/ReactTable/ReactTable.js";

let UdataTablePcat = [];
let UdataTableP = [];

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
async function setPostDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPost";
  console.log(item);

  let formData = new FormData();
  formData.append("postId", item.postId);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPost",formData)

  // let response = await axios.post(url, formData);
  console.log(response);
  window.location.reload(false);
}
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default function Post(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <br></br>
      <br></br>

      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
       <br />
      <br />
       <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <PostPanel />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
class PostPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      PostList: [],
      markers: [[0, 0]],
      map: null,
      postselected: "",
      numPages: 0,
      pageNumber: 1,
      postdocsList: [],
      selectedPostDocs: [],
      PostCatList: [],
      postcategoryselected: "-",
      activeItemPost: {},
      modalPost: false,
      postdocitem: [],
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListPostDocs();
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
  }

  refreshListPostDocs() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormPost/")
      session.get("api/FileFieldFormPost/")
      .then((res) => this.setState({ postdocsList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/PostCategory/")
      session.get("api/PostCategory/")
      .then((res) => this.setState({ PostCatList: res.data }))
      .catch((err) => console.log(err));
  }

  handleEpixirisiSelect(e) {
    const value = e.target.value;

    this.state.postselected = value;

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handlePostCategorySelect(e) {
    const value = e.target.value;

    this.state.postcategoryselected = value;
    this.setState({ state: this.state });
    this.refreshList();
  }

  ClearPostCategorySelect() {
    this.state.postcategoryselected = "-";
    this.setState({ state: this.state });
    this.refreshList();
  }

  refreshList() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Post/")
      session.get("api/Post/")
      .then((res) => this.setState({ PostList: res.data }))
      .catch((err) => console.log(err));
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    // setNumPages(numPages);
    this.state.numPages = numPages;
  };

  handleNewPost = () => {
    // console.log(item);
    this.state.activeItemPost.title = "";
    this.state.activeItemPost.slug = "";
    this.state.activeItemPost.content = "";
    this.state.activeItemPost.status = "";
    this.state.activeItemPost.category = "";
    this.state.activeItemPost.author = "";
    document.getElementById("file_field").value = null;

    // this.state.activeItemE = item;
    this.setState({ modalPost: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemPost);
    // this.render();
  };

  handleChangePostDoc = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.postdocitem.file_field = e.target.files[0];
    this.state.postdocitem.pagkos = this.state.activeItemPost.title;
    this.state.postdocitem.description = fname[0];
  };

  handleChangePost = (e) => {
    // this.state.lastname = this.state.activeItemPoc.namePoC;
    let name = "";
    if (e.target.name) {
      name = e.target.name;
    } else {
      name = e.target.id;
    }
    const value = e.target.value;
    const activeItemPost = { ...this.state.activeItemPost, [name]: value };
    // formData.append([name], value);
    // console.log("ok");
    this.setState({ activeItemPost });

    console.log(activeItemPost);
  };

  handlePostPost = () => {
    const newItem = this.state.activeItemPost;

    newItem.slug = newItem.title;
    newItem.author = localStorage.getItem("userselectedname");

    console.log(newItem);

    if (newItem.title == "" || newItem.category == "") {
      alert("Παρακαλούμε προσθέστε τα υποχρεωτικά πεδία");
    } else {
      if (newItem.id) {
        // const tmpPosts = this.state.PostList.filter( (item) =>
        // String(item.category) === this.state.last)

        console.log(newItem);

        this.state.postdocitem.postId = newItem.title;

        setPostDoc(this.state.postdocitem);

        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/Post/${newItem.id}/`,
        //     newItem
        //   )
          session.put(`api/Post/${newItem.id}/`,newItem)
          .then(this.refreshList())
          .catch((err) => console.log(err));
      } else {
        // axios
          // .post("https://epixirin-volos.govcloud.gr/be/api/Post/", newItem)

          session.post("api/Post/",newItem)
          .then(this.refreshList())
          .catch((err) => console.log(err));

        this.state.postdocitem.postId = newItem.title;

        setPostDoc(this.state.postdocitem);
      }

      this.state.activeItemPost = [];
      this.refreshList();
      this.refreshListPostDocs();
      this.handleClosePost();
      this.setState({ state: this.state });
      document.getElementById("file_field").value = null;
    }
    // newE = true;
  };

  handleDeletePost = (item) => {
    // axios
      // .delete(`https://epixirin-volos.govcloud.gr/be/api/Post/${item.id}`)
      session.delete(`api/Post/${item.id}`)
      .then(this.refreshList());
    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleDeleteDocPo = (id) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/FileFieldFormPost/${id}`)
      session.delete(`api/FileFieldFormPost/${id}`)
      .then(this.refreshListPostDocs());
    this.refreshList();
    this.refreshListPostDocs();
    this.setState({ state: this.state });
    this.setState({ modalPost: false });
    this.render();
    document.getElementById("file_field").value = null;
    this.postselected = "";
    // window.location.reload(false);
    // this.setState({ modalP: true });

    this.refreshListPostDocs();
    this.setState({ modalP: false });

    this.state.postselected = "-";

    this.yourFunctionPo();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunctionPo() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    console.log("Waited 5s");
    this.handleEditPost(this.state.activeItemPost);
    this.setState({ modalPost: false });
    await delay(1000);
    console.log("Waited 5s");
    this.handleEditPost(this.state.activeItemPost);
  }
  handleEditPost = (item) => {
    // console.log(item);
    this.state.activeItemPost = item;
    // this.state.activeItemE = item;

    this.state.selectedPostDocs = this.state.postdocsList.filter(
      (itemS) => itemS.postId === item.title
    );

    // console.log(this.state.postdocsList);
    // console.log(this.state.selectedPostDocs);

    this.setState({ modalPost: true });
    // console.log(this.state.modalE);
    // this.render();
  };

  handleClosePost = () => {
    this.state.activeItemPost = [];
    this.setState({ activeItemPost: [] });
    this.setState({ modalPost: false });
  };

  // getDataFromURL = (url) =>
  //   new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       fetch(url)
  //         .then((response) => response.text())
  //         .then((data) => {
  //           resolve(data);
  //         });
  //     });
  //   }, 2000);

  render() {
    let tmpItems = this.state.PostList;

    console.log(this.state.postcategoryselected);

    if (
      this.state.postcategoryselected != undefined &&
      this.state.postcategoryselected != "-"
    ) {
      tmpItems = tmpItems.filter(
        (item) => item.category == this.state.postcategoryselected
      );
    }
    const postcat = this.state.PostCatList;

    let tmpItemstoshow = [];

    tmpItemstoshow = this.state.PostList.filter(
      (item) => item.id === parseInt(this.state.postselected)
    );

    for (var i = 0; i < tmpItemstoshow.length; i++) {
      const tmpdocs = this.state.postdocsList.filter(
        (itemS) => itemS.postId === tmpItemstoshow[i].title
      );

      if (tmpdocs[0] != undefined) {
        console.log(tmpdocs);
        tmpItemstoshow[i].image = String(tmpdocs[0].file_field);
      }
    }

    const styles = StyleSheet.create({
      page: { backgroundColor: "tomato" },
      section: { textAlign: "center", margin: 30 },
    });

    console.log(tmpItemstoshow);
    const classes = this.state.useStyles;

    UdataTablePcat = [];

    for (var i = 0; i < postcat.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      const name = postcat[i].namePoC;

      dataRow.name = name;
      dataRow.selectLa = (
        <Radio
          checked={String(this.state.postcategoryselected) === name}
          onChange={(e) => this.handlePostCategorySelect(e)}
          value={name}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      // dataRow.perioxi = tmpItemsLaikes[i].perioxiA;

      UdataTablePcat.push(dataRow);
    }

    UdataTableP = [];

    for (var i = 0; i < tmpItems.length; i++) {
      const dataRow = {};
      const id = tmpItems[i].id;
      const nameP = tmpItems[i].title;
      const item = tmpItems[i];
      let katastasi = "";
      if (tmpItems[i].status == 0) {
        katastasi = <Badge color="warning">{"Πρόχειρο"}</Badge>;
      } else {
        katastasi = <Badge color="success">{"Δημοσιευμένο"}</Badge>;
      }

      dataRow.name = nameP;
      dataRow.katastasi = katastasi;
      dataRow.selectLa = (
        <Radio
          checked={parseInt(this.state.postselected) === parseInt(id)}
          onChange={(e) => this.handleEpixirisiSelect(e)}
          value={id}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.category = tmpItems[i].category;

      let tmp = tmpItems[i].created_on.split("T")[0];

      let tmp2 = tmp.split("-");
      dataRow.date = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      dataRow.e = (
        <>
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditPost(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeletePost(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );
      UdataTableP.push(dataRow);
    }

    return (
      <>
        <GridContainer>
          <GridItem xs={7}>
            {/* <GridContainer direction="row" justify="center" alignItems="center">
              <h3> {"Posts"}</h3>
            </GridContainer> */}
            {tmpItemstoshow[0] != undefined && (
              <div>
                <Card>
                  {" "}
                  <CardHeader color="success">
                    {tmpItemstoshow[0].title}
                  </CardHeader>
                  <CardBody>
                    {/* <Row>{tmpItemstoshow[0].slug}</Row> */}

                    <Row>{tmpItemstoshow[0].content}</Row>

                    {tmpItemstoshow[0].image != null && (
                      <div>
                        {String(tmpItemstoshow[0].image).includes("pdf") && (
                          // <PDFViewer
                          //   document={{
                          //     url: tmpItemstoshow[0].image,
                          //   }}
                          // />

                          <div>
                            {/* <Link to={tmpItemstoshow[0].image} target="_blank" download>Download</Link> */}

                            {/* <DownloadLink
                        label="Save"
                        filename={tmpItemstoshow[0].title + ".pdf"}
                        exportFile={() =>
                          Promise.resolve(
                            this.getDataFromURL(tmpItemstoshow[0].image)
                          )
                        }
                      /> */}

                            <Button
                              size="sm"
                              round
                              onClick={() =>
                                window.open(tmpItemstoshow[0].image, "_blank")
                              }
                            >
                              {"Λήψη"}
                            </Button>
                            <Card>
                              <CardBody>
                                <Document
                                  file={tmpItemstoshow[0].image}
                                  onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                  {Array.apply(null, Array(this.state.numPages))
                                    .map((x, i) => i + 1)
                                    .map((page) => (
                                      <Page pageNumber={page} />
                                    ))}
                                </Document>
                              </CardBody>
                            </Card>
                            {/* <div>
                        <p>
                          Page{" "}
                          {this.state.pageNumber ||
                            (this.state.numPages ? 1 : "--")}{" "}
                          of {this.state.numPages || "--"}
                        </p>
                        <button
                          type="button"
                          disabled={this.state.pageNumber <= 1}
                          onClick={this.state.previousPage}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          disabled={
                            this.state.pageNumber >= this.state.numPages
                          }
                          onClick={this.state.nextPage}
                        >
                          Next
                        </button>
                      </div> */}

                            {/* <PDFDownloadLink
                        document={tmpItemstoshow[0].image}
                        fileName={tmpItemstoshow[0].title}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download now!"
                        }
                      </PDFDownloadLink> */}
                          </div>
                        )}

                        {!String(tmpItemstoshow[0].image).includes("pdf") && (
                          <img
                            style={{ width: "50%" }}
                            src={tmpItemstoshow[0].image}
                          />
                        )}
                      </div>

                      // <Row>{tmpItemstoshow[0].image}</Row>
                    )}

                    {/* <Row>{tmpItemstoshow[0].slug}</Row> */}

                    <Row>
                      {/* <Col> Συντάκτης: {tmpItemstoshow[0].author} </Col> */}
                      <Col>
                        {" "}
                        Ημερομηνία:{" "}
                        {
                          String(tmpItemstoshow[0].created_on).split("T")[0]
                        }{" "}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {/* <Card>
              <CardHeader color="success">Κατηγορίες</CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTablePcat}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: (
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.ClearPostCategorySelect()}
                        >
                          {" "}
                          <i className="fa fa-times"></i>{" "}
                        </Button>
                      ),
                      accessor: "selectLa",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Όνομα",
                      accessor: "name",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                /> */}

            {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Button
                            round
                            color="danger"
                            size="sm"
                            onClick={() => this.ClearPostCategorySelect()}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                          Όνομα
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {postcat.map((item) => (
                        <TableRow key={item.id}> */}
            {/* <FormControlLabel
                      control={
                        <Checkbox
                          // onChange={(e) => this.handleChangePostCategory(e)}
                          name="namePoC"
                          id="namePoC"
                          color="primary"
                        />
                      }
                      label={item.namePoC}
                    /> */}

            {/* <Radio
                            checked={
                              String(this.state.postcategoryselected) ===
                              item.namePoC
                            }
                            onChange={(e) => this.handlePostCategorySelect(e)}
                            value={item.namePoC}
                            name="radio-button-demo"
                          />
                          {item.namePoC}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>{" "}
                </TableContainer> */}
            {/* </CardBody>
            </Card> */}
            <Card>
              <CardHeader color="success">
                Post{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewPost()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={UdataTableP}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: " ",
                      accessor: "selectLa",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Τίτλος",
                      accessor: "name",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Κατάσταση",
                      accessor: "katastasi",
                      disableFilters: true,
                      disableSortBy: false,
                      // Filter: SelectColumnFilter,
                      // filter: "includes",
                    },
                    {
                      Header: "Κατηγορία",
                      accessor: "category",
                      // disableFilters: true,
                      disableSortBy: false,
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ημερομηνία",
                      accessor: "date",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                    {
                      Header: "Ενέργιες",
                      accessor: "e",
                      disableFilters: false,
                      disableSortBy: false,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
                {/* <GridContainer
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <h3>
                    {" "}
                    {"Posts"}{" "}
                    <Button
                      round
                      color="info"
                      size="sm"
                      onClick={() => this.handleNewPost()}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>{" "}
                  </h3>
                </GridContainer> */}
                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Τίτλος</TableCell>
                        <TableCell>Κατηγορία</TableCell>
                        <TableCell>Κατάσταση</TableCell>
                        {/* <TableCell>Συγγραφέας</TableCell> 
                        <TableCell>Ημερομηνία</TableCell>
                        <TableCell>Ενέργειες</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tmpItems.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            <Radio
                              checked={
                                parseInt(this.state.postselected) ===
                                parseInt(item.id)
                              }
                              onChange={(e) => this.handleEpixirisiSelect(e)}
                              value={item.id}
                              name="radio-button-demo"
                            />
                            {item.title}
                          </TableCell>
                          <TableCell>{item.category}</TableCell>
                          {/* <TableCell>{item.author}</TableCell> 
                          <TableCell>
                            {item.status == 0 && (
                              <Badge color="warning">{"Πρόχειρο"}</Badge>
                            )}
                            {item.status == 1 && (
                              <Badge color="success">{"Δημοσιευμένο"}</Badge>
                            )}
                          </TableCell>
                          <TableCell>{item.created_on.split("T")[0]}</TableCell>
                          <TableCell>
                            <Button
                              round
                              color="info"
                              size="sm"
                              onClick={() => this.handleEditPost(item)}
                            >
                              <i className="fa fa-edit"></i>
                            </Button>{" "}
                            <Button
                              round
                              color="danger"
                              size="sm"
                              onClick={() => this.handleDeletePost(item)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>{" "}
                </TableContainer> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modalPost,
          }}
          open={this.state.modalPost}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="sm"
          onClose={() => this.handleClosePost()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            {" "}
            Στοιχεία Ανακοίνωσης
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePost()}
            >
              <Close className={classes.modalClosePoc} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color="success">Στοιχεία</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="title"
                      name="title"
                      inputProps={{
                        value: this.state.activeItemPost.title,
                        placeholder: "Τίτλος*",
                        onChange: (e) => this.handleChangePost(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <CustomInput
                      id="content"
                      name="content"
                      inputProps={{
                        value: this.state.activeItemPost.content,
                        placeholder: "Κείμενο",
                        maxlength: "5",
                        onChange: (e) => this.handleChangePost(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label> Κατηγορία* </label> <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="category"
                      id="category"
                      value={String(this.state.activeItemPost.category)}
                      onChange={(e) => this.handleChangePost(e)}
                    >
                      {postcat.map((item) => (
                        <MenuItem value={item.namePoC}>{item.namePoC}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <label> Κατάσταση* </label> <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="status"
                      id="status"
                      value={parseInt(this.state.activeItemPost.status)}
                      onChange={(e) => this.handleChangePost(e)}
                    >
                      <MenuItem value={0}>Πρόχειρο</MenuItem>

                      <MenuItem value={1}>Δημοσιευμένο</MenuItem>
                    </Select>{" "}
                    <br />
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        Αρχείο
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangePostDoc(e)}
                        name="file_field"
                        type="file"
                      />
                    </FormGroup>
                    <label> Διαθέσιμα αρχεία</label>
                    {this.state.selectedPostDocs.map(
                      ({ id, file_field, description }) => (
                        <Badge>
                          <Button
                            size="sm"
                            round
                            onClick={() => window.open(file_field, "_blank")}
                          >
                            {" "}
                            {description}{" "}
                          </Button>
                          <Button
                            round
                            color="danger"
                            size="sm"
                            onClick={() => this.handleDeleteDocPo(id)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </Badge>
                      )
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePost()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostPost()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
